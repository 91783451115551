import React from 'react';
import {LinearProgress, Stack, Typography} from "@mui/material";
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie-files/loading-screen.json';
import {grey} from "@mui/material/colors";
import {SubmittingWorkProgress} from "../../utils/enums/submitting-work-progress";
import {IconCheckupList, IconSquareCheck, IconSquareCheckFilled} from "@tabler/icons-react";

const MintingLoadingScreen = ({message, progress = []}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const value = progress?.length ? (progress.length / 4) * 100 : 0;

  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={3}
      sx={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "#FFF",
        zIndex: 100000
      }}
    >
      <Lottie
        options={defaultOptions}
        height={260}
        width={260}
      />
      <Typography fontSize={24} fontWeight={600}>Saving  you work...</Typography>
      <Typography color={grey[500]}
                  fontWeight={500}>{message || "Please wait while we register your NFT on the blockchain"}</Typography>
      {progress?.length ? <>
        <LinearProgress
          color={progress.includes(SubmittingWorkProgress.FAILED) ? "error" : "warning"}
          variant={"buffer"} value={value} valueBuffer={value + 25}
          sx={{maxWidth: '200px', width: "100%", py: 0.5}}/>
        <Stack direction={"column"} gap={1}>
          {progress?.filter(item => item !== SubmittingWorkProgress.FAILED)
            ?.map(item => {
              return <Stack direction={'row'} gap={1} alignItems={"center"}>
                <IconSquareCheckFilled style={{color: "#11AA11"}}/> <Typography>{item}</Typography>
              </Stack>
            })
          }
        </Stack>
      </> : null}

    </Stack>
  );
};

export default MintingLoadingScreen;
