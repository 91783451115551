import {Stack, Box, Typography, Button, TextField} from "@mui/material";
import React from "react";
import {MonetizeWorkForm} from "./MonetizeWorkForm";
import {MintBanner} from "../../../components/banner/MintBanner";
import {styled} from '@mui/material/styles';
import {LicenseType} from "../../../utils/enums/LicenseType";
import {BeforeStartedCard} from "./BeforeStartedCard";

const GradientText = styled(Typography)(({theme}) => ({
  cursor: 'pointer',
  background: 'linear-gradient(to right, #a356ae, #f98150)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  userSelect: 'none'
}));

export const LicenseForm = ({setIsConnected, license, nft, onChange, onUpdate, onSelectTemplate}) => {

  const standLimitationsTemp = `The licensee agrees to the following terms:
This license may not be used in a way that harms the copyright holder’s reputation.
This license may not be transferred to a third party without the copyright holder’s permission.
This license may not be used to violate the law.
This license may not be used in culturally inappropriate contexts.
The licensee will not circumvent any technological measures that control access to the work.
The licensee must credit the original creator when using the work.`;

  return (
    <>
      <BeforeStartedCard nft={nft}/>
      {/*<Box sx={{textAlign: "left", px: 2}} fullWidth>*/}
      {/*  <FormControlLabel*/}
      {/*    label={license?.type ? "I’d like to monetize my work" : "I will not monetize my work."}*/}
      {/*    control={<IOSSwitch*/}
      {/*      checked={license?.type === LicenseType.MONETIZED}*/}
      {/*      sx={{mr: 2}}*/}
      {/*      onChange={(e) => onUpdate("type", e.target.checked ? LicenseType.MONETIZED : LicenseType.NON_MONETIZED)}*/}
      {/*    />}*/}
      {/*  />*/}
      {/*</Box>*/}

      <MonetizeWorkForm license={license} onChange={onChange} onUpdate={onUpdate} onSelectTemplate={onSelectTemplate} />

      <Box maxWidth={"md"}>
        <Stack direction={'row'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              color: "#2D2D30",
              pb: 2,
            }}
          >
            Other limitations
          </Typography>
          <GradientText variant="button" sx={{p: 0, m: 0, borderRadius: 5, ml: 'auto', mb: 'auto'}} onClick={() => {
            onUpdate("limitations", standLimitationsTemp)
          }}>
            Fill with standard limitations template
          </GradientText>
        </Stack>
        <TextField
          multiline rows={5} fullWidth
          value={license?.limitations}
          onChange={e => onUpdate("limitations", e.target.value)}
          InputProps={{sx: {borderRadius: 1, bgcolor: "#FFF"}}}
          placeholder={"Cannot be used for..."}
        />
      </Box>
      {
        license?.type === LicenseType.MONETIZED ?
          <>
            <MintBanner setAccount={(addr) => setIsConnected(!!addr)}/>
            <Box maxWidth={"md"}>
              <Typography variant={"h4"} fontWeight={600}>No cost to mint</Typography>
              <Typography variant={"subtitle2"} fontWeight={400}>There’s no cost to mint licenses. We will create this
                license and when someone wants to engage one it, we will mint the license and include the minting cost
                in the overall fee to the buyer. </Typography>
            </Box>
          </> :
          <>
            <Box maxWidth={"md"}>
              <Typography variant={"h4"} fontWeight={600}>No need to mint</Typography>
              <Typography variant={"subtitle2"} fontWeight={400}>There’s no cost to mint licenses. We will create this
                license.</Typography>
            </Box>
          </>
      }
      <Box>
        {/*<Button className={"sign-button"} size={"large"} sx={{px: 3, borderRadius: 3}}>*/}
        {/*  Save as Template*/}
        {/*</Button>*/}
      </Box>
    </>
  )
}