import React from "react";
import {MoreInformation} from "./MoreInformation";
import {ExplicitContent} from "./ExplicitContent";
import {UploadFile} from "./UploadFile";
import {AcknowledgeOriginalWork} from "./AcknowledgeOriginalWork";
import {ListAllAttributions} from "./ListAllAttributions";
import {PublicInformation} from "./PublicInformation";
import {StepButtons} from "./StepButtons";
import {MintBanner} from "../../../components/banner/MintBanner";
import {Button, Typography} from "@mui/material";
import Box from "@mui/material/Box";


export const NftInformation = ({
                                 onNextStep,
                                 onPrevStep,
                                 onSubmit,
                                 onUpdate,
                                 publicWalletAddress,
                                 setPublicWalletAddress,
                                 registerWorkForm,
                                 workSaved,
                                 loading
                               }) => {
  const isDisabled = (!registerWorkForm?.title || !registerWorkForm?.description ||
    !registerWorkForm?.file || !registerWorkForm?.publicImage ||
    !registerWorkForm?.publicTitle || !registerWorkForm?.publicDescription ||
    registerWorkForm?.originalWork === false || registerWorkForm?.ownWork === false)

  return (
    <>
      <MoreInformation registerWorkForm={registerWorkForm} onUpdate={onUpdate}/>
      <UploadFile file={registerWorkForm?.file} onUpdate={(file) => onUpdate("file", file)}/>
      <AcknowledgeOriginalWork originalWork={registerWorkForm?.originalWork}
                               ownWork={registerWorkForm?.ownWork}
                               onUpdate={onUpdate}/>
      <ListAllAttributions attributions={registerWorkForm?.attributions} onUpdate={onUpdate}/>
      <ExplicitContent registerWorkForm={registerWorkForm} onUpdate={onUpdate}/>
      <PublicInformation registerWorkForm={registerWorkForm} onUpdate={onUpdate}/>
      <Box sx={{display: {xs: "none", md: "block"} }}>
        {
          !registerWorkForm?.publicWalletAddress &&
          <>
            <MintBanner
              // cost={'$9.99'}
              // title={"US Dollars"}
              // description={"To mint your creative work, we charge a flat fee of $9.99, and mint your work on the Polygon chain."}
              setAccount={setPublicWalletAddress}
            />
            <Button
              variant="text"
              sx={{
                p: 0, m: 0, borderRadius: 0, fontSize: "0.85rem",
                color: '#949494', textDecoration: 'underline',
                "&:hover": {bgcolor: "transparent", textDecoration: 'underline'}
              }}
              isDisabled={loading || isDisabled}
              onClick={onSubmit}
            >
              {"I don't want a wallet now. Save my work to mint later."}           </Button>
          </>
        }
      </Box>
      <Box sx={{display: {xs: "block", md: "none"} }}>
        <Typography color={"#949494"} fontSize={"0.85rem"}>
          * We are not supporting connecting wallet on mobile please mint your work from desktop
        </Typography>
      </Box>

      <StepButtons
        onBack={onPrevStep} onSubmit={onSubmit}
        isDisabled={loading || isDisabled}
        submitTitle={loading ? "Saving your work..." : workSaved ? "Work saved" : publicWalletAddress ? "Save work and proceed to payment" : "I don't want a wallet now. Save my work to mint later."}
      />
    </>
  )
}