import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {grey} from "@mui/material/colors";

export const PaymentSettingsPage = () => {
  return (
    <>
      <Typography variant={"h2"} fontWeight={700}>Payment settings</Typography>
      <Typography variant={"subtitle2"} fontWeight={400} className={"text-grey"}>C3 manages all payments through Stripe. To add/remove payment options, please click the button below.</Typography>
      <Typography variant={"subtitle2"} fontWeight={400} className={"text-grey"}>If you have questions about past transactions, please <Link to={"contact"} className={'text-grey'} style={{fontWeight: 700}}>contact our team.</Link></Typography>

      <Box sx={{my: 7}}>
        <Button variant={"outlined"} color={"secondary"} sx={{px: 5, borderRadius: 5}}>
          Disconnect/Connect Stripe
        </Button>
      </Box>
      <Stack direction={"row"} gap={2}>
        <Button variant={"outlined"} sx={{px: 5, borderRadius: 5, color: grey[700], borderColor: grey[700], "&:hover": {bgcolor: grey[50], borderColor: grey[700]} }}>
          Cancel
        </Button>
        <Button className={"sign-button"} sx={{px: 5, borderRadius: 5}}>
          Save Changes
        </Button>
      </Stack>

    </>
  )
}