
export const LicenseRequestType = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  PAID: "PAID",
  MINTED: "MINTED",
  FAILED: "FAILED"
}

export const LicenseType = {
  SEND_LICENCE: "SEND_LICENCE",
  REQUEST_LICENCE: "REQUEST_LICENCE",
}