
export const terms = {
  lastModified: "Last updated July 16, 2024",
  text: `
            These terms of use are entered into by and between You and Technology Innovation Law, LLC. ("Company," "we," or "us"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and use of [PLATFORM NAME], including any content, functionality and services offered on or through [PLATFORM NAME] (the "Platform"), whether as a guest or a registered user.

          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY:

          BY USING THE PLATFORM OR BY CLICKING TO ACCEPT OR AGREE TO THE TERMS OF USE WHEN THIS OPTION IS MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF USE AND ALL OTHER POLICIES INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT WANT TO AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.  IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THE LEGAL ENTITY’S BEHALF.

          I. Definitions

          A. Platform. The T-I-L “Platform” provides a platform for trading blockchain-based digital collectibles, protecting those collectibles with copyright, and transacting licenses for those copyrights.

          B. User Contributions

          The Platform may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, blockchains, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Platform.

          All User Contributions must comply with the Content Standards set out in these Terms of Use.

          Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Platform, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material according to your account settings.

          You represent and warrant that:

          ·\tYou own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.

          ·\tAll of your User Contributions do and will comply with these Terms of Use.

          You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.

          We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Platform.

          II. The T-I-L Service

          A. We are Not Your Legal Representative

          T-I-L makes no representations or warranties, express or implied, that T-I-L will represent anyone without prior express communications to that effect.

          B. Children Under the Age of 18 are Prohibited from Using This Service

          This Platform is offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using this Platform, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Platform.

          C. Monitoring & Enforcement of These Terms and Those Incorporated by Reference

          We have the right to:

          ·\tRemove or refuse to post any User Contributions for any or no reason in our sole discretion.

          ·\tTake any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Platform or the public or could create liability for the Company.

          ·\tDisclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.

          ·\tTake appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform.

          ·\tTerminate or suspend your access to all or part of the Platform for any or no reason, including without limitation, any violation of these Terms of Use.

          Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Platform. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.

          HOWEVER, WE DO NOT UNDERTAKE TO REVIEW ALL MATERIAL BEFORE IT IS POSTED ON THE WEBSITE. WE CANNOT ENSURE PROMPT REMOVAL OF OBJECTIONABLE MATERIAL AFTER IT HAS BEEN POSTED. ACCORDINGLY, WE ASSUME NO LIABILITY FOR ANY ACTION OR INACTION REGARDING TRANSMISSIONS, COMMUNICATIONS OR CONTENT PROVIDED BY ANY USER OR THIRD PARTY. WE HAVE NO LIABILITY OR RESPONSIBILITY TO ANYONE FOR PERFORMANCE OR NONPERFORMANCE OF THE ACTIVITIES DESCRIBED IN THIS SECTION.

          D. Online Purchases

          All purchases through our site or other transactions for the sale of goods or information formed through the Platform, or as a result of visits made by you are governed by our [SALE AND LICENSURE OF INTELLECTUAL PROPERTY POLICY] as well as individually applicable agreements between you and other users, which are hereby incorporated into these Terms of Use.

          Additional terms and conditions may apply to specific portions, services or features of the Platform. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use.

          III. Accessing the Service

          A. Geographic Restrictions

          The owner of the Platform is based in the District of Columbia in the United States. We provide this Platform for use only by persons located in the United States. We make no claims that the Platform or any of its content is accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain persons or in certain countries. If you access the Platform from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.

          B. Accessing the Platform and Account Security

          We reserve the right to withdraw or amend this Platform, and any service or material we provide on the Platform, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or the entire Platform, to users, including registered users.

          You are responsible for both:

          ·\tMaking all arrangements necessary for you to have access to the Platform.

          ·\tEnsuring that all persons who access the Platform through your internet connection are aware of these Terms of Use and comply with them.

          To access the Platform or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Platform that all the information you provide on the Platform is correct, current and complete. You agree that all information you provide to register with this Platform or otherwise, including, but not limited to, through the use of any interactive features on the Platform, is governed by our [LINK TO PRIVACY POLICY] and you consent to all actions we take with respect to your information consistent with our Privacy Policy.

          If you choose, or are provided with, a username, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Platform or portions of it using your username, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.

          We have the right to disable any username, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.





          C. Prohibited Uses





          You may use the Platform only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Platform:

          ·\tIn any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).

          ·\tFor the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.

          ·\tTo send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use.

          ·\tTo transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.

          ·\tTo impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).

          ·\tTo engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Platform, or which, as determined by us, may harm the Company or users of the Platform, or expose them to liability.

          Additionally, you agree not to:

          ·\tUse the Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Platform, including their ability to engage in real time activities through the Platform.

          ·\tUse any robot, spider or other automatic device, process or means to access the Platform for any purpose, including monitoring or copying any of the material on the Platform.

          ·\tUse any manual process to monitor or copy any of the material on the Platform, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.

          ·\tUse any device, software or routine that interferes with the proper working of the Platform.

          ·\tIntroduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.

          ·\tAttempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Platform, the server on which the Platform is stored, or any server, computer or database connected to the Platform.

          ·\tAttack the Platform via a denial-of-service attack or a distributed denial-of-service attack.

          ·\tOtherwise attempt to interfere with the proper working of the Platform.

          D. Content Standards

          These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:

          ·\tContain any material which is defamatory, abusive, offensive, harassing, hateful, inflammatory or otherwise objectionable.

          ·\tPromote sexually explicit, pornographic, or violent material, unless T-I-L determines that such material is appropriate.

          ·\tPromote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.

          ·\tInfringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.

          ·\tViolate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and Our other policies incorporated by reference.

          ·\tBe likely to deceive any person.

          ·\tPromote any illegal activity, or advocate, promote or assist any unlawful act.

          ·\tCause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.

          ·\tImpersonate any person, or misrepresent your identity or affiliation with any person or organization.

          ·\tInvolve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.

          ·\tGive the impression that they emanate from or are endorsed by us or any other person or entity if that is not the case.

          IV. Intellectual Property

          A. Intellectual Property, Generally

          The Platform and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.

          These Terms of Use permit you to use the Platform for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Platform, except as follows:

          ·\tYour computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.

          ·\tYou may store files that are automatically cached by your Web browser for display enhancement purposes.

          ·\tYou may print one copy of a reasonable number of pages of the Platform for your own personal, non-commercial use and not for further reproduction, publication or distribution.

          ·\tIf we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.

          ·\tIf we provide {Invalid hyperlink URL} with certain content, you may take such actions as are enabled by such features.

          You must not:

          ·\tModify copies of any materials from this site.

          ·\tUse any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.

          ·\tDelete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.

          You must not access or use for any commercial purposes any part of the Platform or any services or materials available through the Platform.

          If you wish to make any use of material on the Platform other than that set out in this section, please address your request to: [EMAIL ADDRESS OF END-USER COPYRIGHT OWNERS].

          If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Platform in breach of the Terms of Use, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Platform or any content on the Platform is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Platform not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.

          B. Trademarks

          The Company name, the terms [LIST OF TIL MARKS], the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs and slogans on this Platform are the trademarks of their respective owners.

          C. Copyright Infringement

          If you believe that any User Contributions violate your copyright, please see our [LINK TO COPYRIGHT INFRINGEMENT (DMCA) POLICY] for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.

          D. Linking to the T-I-L Platform and Social Media Features

          You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part

          This Platform may provide certain social media features that enable you to:

          ·\tLink from your own or certain third-party websites to certain content on this Platform.

          ·\tSend e-mails or other communications with certain content, or links to certain content, on this Platform.

          ·\tCause limited portions of content on this Platform to be displayed or appear to be displayed on your own or certain third-party websites.

          You may use these features solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:

          ·\tEstablish a link from any website that is not owned by you.

          ·\tCause the Platform or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.

          ·\tLink to any part of the Platform other than the homepage.

          ·\tOtherwise take any action with respect to the materials on this Platform that is inconsistent with any other provision of these Terms of Use.

          The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.

          You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.

          We may disable all or any social media features and any links at any time without notice in our discretion.

          D. Links from the Platform

          If the Platform contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources. We accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Platform, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.

          V. Term, Termination, and Modification of These Terms

          VI. Indemnity, Limitation of Liability, and Disclaimers

          A. Indemnification

          You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Platform, including, but not limited to, your User Contributions, any use of the Platform's content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Platform.

          B. Limitation of Liability

          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.

          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

          C. Disclaimer of Warranties

          You understand that we cannot and do not guarantee or warrant that any files available for downloading from the internet or the Platform will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.

          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.

          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.





          VII. Dispute Resolution

          A. Governing Law & Jurisdiction

          All matters relating to the Platform and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the District of Columbia without giving effect to any choice or conflict of law provision or rule (whether of the District of Columbia or any other jurisdiction).

          Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Platform shall be instituted exclusively in the federal courts of the United States or the courts of the District of Columbia although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.

          B. Arbitration

          At Company's sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Platform, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying District of Columbia law.





          VIII. Miscellaneous

          A. Changes to the Terms of Use

          We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Platform thereafter. However, any changes to the dispute resolution provisions set forth in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or prior to the date the change is posted on the Platform.

          Your continued use of the Platform following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.

          B. Changes to the Platform

          We may update the content on this Platform from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Platform may be out of date at any given time, and we are under no obligation to update such material.

          C. Reliance on Information Posted

          The information presented on or through the Platform is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Platform, or by anyone who may be informed of any of its contents.

          This Platform includes content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.

          D. Information About You and Your Visits to the Platform

          All information we collect on this Platform is subject to our [LINK TO PRIVACY POLICY]. By using the Platform, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.

          you.





          IX. Copyright Infringement

          X. Reliance on Information Posted

          XI. Changes to the Platform

          XII. Information About You and Your Visits to the Platform

          XIII. Online Purchases and Other Terms and Conditions

          XIV. Linking to the Platform and Social Media Features

          XV.

          XVI. Geographic Restrictions

          XVII. Disclaimer of Warranties

          XVIII. Limitation on Liability

          XIX. Indemnification

          XX. Governing Law and Jurisdiction

          XXI. Arbitration

          XXII. Limitation on Time to File Claims

          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.

          XXIII. Waiver and Severability

          No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.

          If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.

          XXIV. Entire Agreement

          The Terms of Use, our Privacy Policy, Terms of Sale, and Terms of Sale; Copyright Infringement (DMCA) Policy; Privacy Policy constitute the sole and entire agreement between you and Technology Innovation Law, LLC. with respect to the Platform and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Platform.

          XXV. Your Comments and Concerns

          This website is operated by Technology Innovation Law, LLC., Washington, D.C. 20016, [CITY], District of Columbia 20016.

          All notices of copyright infringement claims should be sent to the copyright agent designated in our {Invalid hyperlink URL} in the manner and by the means set forth therein.

          All other feedback, comments, requests for technical support and other communications relating to the Platform should be directed to: tilpractice@technology-innovation-law.com .
        `,
  decline_text: `
You must accept the Terms of Service to access C3. The Terms provide important information about users’ rights and responsibilities. We encourage you to carefully review them. If you still have concerns, please feel free to contact us.
  `
}