import {useLocation, useNavigate} from "react-router-dom";

export const useBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPreviousState = location.key !== "default";

  const handleClick = (route) => {
    if (hasPreviousState) {
      navigate(-1);
    } else {
      navigate(route || "/");
    }
  };

  return handleClick;
}