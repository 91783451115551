import {Box, Stack} from "@mui/material";
import React from "react";

export const AlertBanner = ({
                              icon,
                              text,
                              color,
                              fontSize = 18,
                              bgcolor = "linear-gradient(94deg, rgba(141, 87, 195, 0.11) -10.69%, rgba(228, 82, 113, 0.11) 59.43%, rgba(252, 136, 75, 0.11) 104.02%)"
                            }) => {
  return (
    <Stack
      direction={"row"}
      gap={2}
      alignItems={"center"}
      sx={{
        mt: 2,
        p: 2,
        color: color,
        borderRadius: "0.75rem",
        boxShadow: 3,
        fontSize: fontSize,
        background: bgcolor
      }}
    >
      {icon}
      <Box sx={{color: color, flex: 1}}>{text}</Box>
    </Stack>
  )
}