export const IconDistributingCopies = ({size = 20, color = "#000"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size + 9} viewBox={`0 0 ${size + 20} ${size + 20}`} fill="none">
    <path d="M11.0381 19.1322H9.56641V29.4341H11.0381V19.1322Z" fill={color}/>
    <path d="M10.3011 20.9086L0.214844 10.8224L1.25533 9.78186L10.3011 18.8277L19.3469 9.78186L20.3874 10.8224L10.3011 20.9086Z" fill={color}/>
    <path d="M11.0381 0.736084H9.56641V19.8682H11.0381V0.736084Z" fill={color}/>
    <path d="M19.8673 11.0379H14.7163C14.3094 11.0379 13.9805 10.7089 13.9805 10.302C13.9805 9.89509 14.3094 9.56616 14.7163 9.56616H19.8673C20.2742 9.56616 20.6031 9.89509 20.6031 10.302C20.6031 10.7089 20.2742 11.0379 19.8673 11.0379Z" fill={color}/>
    <path d="M19.8687 16.1888C19.4617 16.1888 19.1328 15.8599 19.1328 15.453V10.302C19.1328 9.89509 19.4617 9.56616 19.8687 9.56616C20.2756 9.56616 20.6045 9.89509 20.6045 10.302V15.453C20.6045 15.8599 20.2756 16.1888 19.8687 16.1888Z" fill={color}/>
    <path d="M0.735849 16.1888C0.328925 16.1888 0 15.8599 0 15.453V10.302C0 9.89509 0.328925 9.56616 0.735849 9.56616C1.14277 9.56616 1.4717 9.89509 1.4717 10.302V15.453C1.4717 15.8599 1.14277 16.1888 0.735849 16.1888Z" fill={color}/>
    <path d="M5.88679 11.0379H0.735849C0.328925 11.0379 0 10.7089 0 10.302C0 9.89509 0.328925 9.56616 0.735849 9.56616H5.88679C6.29372 9.56616 6.62264 9.89509 6.62264 10.302C6.62264 10.7089 6.29372 11.0379 5.88679 11.0379Z" fill={color}/>
    <path d="M5.1501 6.62283C4.96172 6.62283 4.77334 6.55071 4.62985 6.40722C4.34213 6.1195 4.34213 5.65445 4.62985 5.36673L9.78079 0.215788C10.0685 -0.0719292 10.5336 -0.0719292 10.8213 0.215788C11.109 0.503505 11.109 0.968561 10.8213 1.25628L5.67034 6.40722C5.52685 6.55071 5.33847 6.62283 5.1501 6.62283Z" fill={color}/>
    <path d="M15.4534 6.62283C15.265 6.62283 15.0766 6.55071 14.9331 6.40722L9.78219 1.25628C9.49448 0.968561 9.49448 0.503505 9.78219 0.215788C10.0699 -0.0719292 10.535 -0.0719292 10.8227 0.215788L15.9736 5.36673C16.2613 5.65445 16.2613 6.1195 15.9736 6.40722C15.8301 6.55071 15.6418 6.62283 15.4534 6.62283Z" fill={color}/>
    <path d="M10.3018 38.2643C12.7402 38.2643 14.7169 36.2876 14.7169 33.8492C14.7169 31.4108 12.7402 29.4341 10.3018 29.4341C7.86342 29.4341 5.88672 31.4108 5.88672 33.8492C5.88672 36.2876 7.86342 38.2643 10.3018 38.2643Z" fill={color}/>
    <path d="M10.3013 39.0001C7.46169 39.0001 5.15039 36.6888 5.15039 33.8492C5.15039 31.0095 7.46169 28.6982 10.3013 28.6982C13.141 28.6982 15.4523 31.0095 15.4523 33.8492C15.4523 36.6888 13.141 39.0001 10.3013 39.0001ZM10.3013 30.1699C8.2726 30.1699 6.62209 31.8204 6.62209 33.8492C6.62209 35.8779 8.2726 37.5284 10.3013 37.5284C12.3301 37.5284 13.9806 35.8779 13.9806 33.8492C13.9806 31.8204 12.3301 30.1699 10.3013 30.1699Z" fill={color}/>
  </svg>
)