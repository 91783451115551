import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {registerWorkInitialState} from "./registerWorkInitialState";
import {Button, Stack, Typography} from "@mui/material";
import {ChooseTemplate} from "./components/ChooseTemplate";
import {ProjectInformation} from "./components/ProjectInformation";
import {NftInformation} from "./components/NftInformation";
import {auth, db, storage} from "../../config/firebase";
import {toast} from "react-toastify";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {StripeEmbeddedForm} from "../../components/stripe/StripeEmbeddedForm";
import {grey} from "@mui/material/colors";
import {IconChevronLeft} from "@tabler/icons-react";
import {useNavigate, useParams} from "react-router-dom";
import useWalletConfirm from "../../hooks/useWalletConfirm";
import {NftCreated} from "./components/NftCreated";
import MintingLoadingScreen from "../../components/lottie-animations/MintingLoadingScreen";
import {SubmittingWorkProgress} from "../../utils/enums/submitting-work-progress";
import {PhantomWalletPrompt} from "../../components/modals/PhantomWalletPrompt";
import Box from "@mui/material/Box";
import {StripeProductTypes} from "../../utils/enums/StripeProductTypes";

export const RegisterWorkNew = () => {
  const {state: {royaltyTemplates, userDetails}, dispatch} = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [registerWorkForm, setRegisterWorkForm] = useState({...registerWorkInitialState})
  const [publicWalletAddress, setPublicWalletAddress] = useState(undefined);
  const [allChecksPassed, setAllChecksPassed] = useState(false)
  const [status, setStatus] = useState('');
  const [submittingStatus, setSubmittingStatus] = useState('');
  const [doneStatuses, setDoneStatuses] = useState([]);
  const [workSaved, setWorkSaved] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const {nftId} = useParams();
  const navigate = useNavigate();
  const [WalletDialog, confirmDialog] = useWalletConfirm("Are you sure you want to save your work for later?", "If you don’t connect your wallet, we will still mint your work as an NFT. When you have a wallet, you can click “Transfer” to move your NFT from our wallet to yours.")

  useEffect(() => {
    const fetchNft = async () => {
      const docRef = doc(db, "royalty_templates", nftId);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        const nftData = {...docSnap?.data()}
        setRegisterWorkForm({...nftData})
        setPublicWalletAddress(nftData?.publicWalletAddress || undefined);
        if (nftData?.publicWalletAddress) {
          setAllChecksPassed(true);
          setStep(3);
        } else {
          setStep(2);
        }
      }
    }
    if (nftId) {
      fetchNft();
    }
  }, [nftId])

  useEffect(() => {
    window.scrollTo({behavior: "smooth", top: 0});
  }, [step])

  useEffect(() => {
    const newState = {};
    if (userDetails?.projects?.length) {
      newState.projectTypes = [userDetails?.projects[0]] || [];
    }

    setRegisterWorkForm(prev => ({...prev, ...newState}))
  }, [userDetails])

  const onUpdate = (key, value) => {
    setRegisterWorkForm(prevState => {
      return {...prevState, [key]: value}
    })
  }

  const onSubmit = async () => {
    let step = 1;
    if (!publicWalletAddress && !registerWorkForm?.publicWalletAddress) {
      const response = await confirmDialog();
      if (!response) {
        return;
      }
      step = 2;
    }
    const data = {...registerWorkForm};
    if (nftId) {
      data.uid = nftId;
    }
    data.createdAt = new Date().getTime()
    data.userUid = auth.currentUser.uid
    data.paid = false
    data.nftTxnHash = ''
    data.publicWalletAddress = registerWorkForm?.publicWalletAddress || publicWalletAddress || null;

    const file = registerWorkForm?.file;
    const publicImage = registerWorkForm?.publicImage;
    //TODO: check if all of the mandatory fields have been filled out
    //  change state to reflect that to render payment and save the work
    if (!file) {
      toast.error('No file, please upload a file and try again!')
      return;
    }

    setAllChecksPassed(true)
    toast.info("Saving your work...")
    setSubmitLoading(true)
    try {
      if (typeof file !== "string") {
        const fileFolderRef = ref(storage, `royalty_templates/${file?.name}`)
        console.log("Uploading image")
        setSubmittingStatus(SubmittingWorkProgress.UPLOAD_WORK)
        const imgRef = await uploadBytes(fileFolderRef, file);
        data.file = await getDownloadURL(imgRef.ref);
        setDoneStatuses([SubmittingWorkProgress.UPLOAD_WORK]);
      }
      if (typeof publicImage !== "string") {
        setSubmittingStatus(SubmittingWorkProgress.UPLOAD_PUBLIC_IMAGE)
        const fileFolderRef = ref(storage, `royalty_templates/${publicImage?.name}`)
        const publicImgRef = await uploadBytes(fileFolderRef, publicImage);
        data.publicImage = await getDownloadURL(publicImgRef.ref);
        setDoneStatuses(prev => ([...prev, SubmittingWorkProgress.UPLOAD_PUBLIC_IMAGE]));
      }

      setSubmittingStatus(SubmittingWorkProgress.SAVE_WORK);
      await setDoc(doc(db, "royalty_templates", data.uid), data);
      setDoneStatuses(prev => ([...prev, SubmittingWorkProgress.SAVE_WORK]));
      const templates = [...royaltyTemplates];
      const index = templates?.findIndex(template => template?.uid === data?.uid);
      if (index >= 0) {
        templates[index] = data;
      } else {
        templates.unshift(data);
      }
      dispatch({type: "update_royalty_templates", payload: templates});
      setWorkSaved(true);
      setSubmittingStatus(SubmittingWorkProgress.PAYMENT);
      setDoneStatuses(prev => ([...prev, SubmittingWorkProgress.PAYMENT]));
      if (step === 2) {
        toast.success("Work saved");
        navigate("/portfolio")
      } else {
        setStep(prev => prev + step);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Save failed, please try again!')
      setSubmittingStatus(SubmittingWorkProgress.FAILED);
    } finally {
      setSubmitLoading(false);
      setSubmittingStatus("");
      setDoneStatuses([])
    }
  }


  const onNextStep = () => setStep(prev => prev + 1);
  const onPrevStep = () => setStep(prev => prev - 1);

  return (
    <Stack id={"form-wrapper"} direction={"column"} gap={5} maxWidth={"lg"} sx={{py: 5, px: 2, width: "90%", mx: "auto", textAlign: "left !important", overflowX: "hidden"}}>
      <WalletDialog />
      <PhantomWalletPrompt />
      <Box sx={{display: step === 4 ? "none" : "block"}}>
        <Typography variant={"h2"} fontWeight={700} fullWidth align={"center"} gutterBottom>Document a New Creative Work</Typography>
        <Typography className={"text-grey"} fontWeight={400} fullWidth align={"center"}>On blockchain to protect your work with a common law copyright.</Typography>
      </Box>
      {
        submitLoading ? <MintingLoadingScreen message={submittingStatus} progress={doneStatuses} /> : ({
          0: <ChooseTemplate onNextStep={onNextStep} />,
          1: <ProjectInformation onNextStep={onNextStep} onPrevStep={onPrevStep} onUpdate={onUpdate} registerWorkForm={registerWorkForm} />,
          2: <NftInformation
                onNextStep={onNextStep} onPrevStep={onPrevStep}
                onSubmit={onSubmit} onUpdate={onUpdate}
                workSaved={workSaved} loading={submitLoading} registerWorkForm={registerWorkForm}
                publicWalletAddress={publicWalletAddress} setPublicWalletAddress={setPublicWalletAddress}
          />,
          3: <>
            <StripeEmbeddedForm nftUid={nftId || registerWorkForm.uid} publicAddress={publicWalletAddress}
                                allChecksPassed={allChecksPassed} setStatus={setStatus} productType={StripeProductTypes.NFT} onNextStep={onNextStep} />

            <Stack direction={"row"} gap={2}>
              <Button
                disabled={step === 0}
                sx={{
                  textTransform: "none",
                  border: 0,
                  color: `${grey[600]} !important`
                }}
                onClick={() => setStep(step - 1)}
              >
                <IconChevronLeft size={18}/>
                Back
              </Button>
            </Stack>
          </>,
          4: <NftCreated nft={registerWorkForm} />
        }[step])
      }
    </Stack>
  )
}
