import {List, ListItemButton, ListItemText, Stack} from "@mui/material";
import {NavLink, Outlet} from "react-router-dom";
import {useEffect} from "react";

export const SettingsLayout = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Stack direction={"row"} gap={7} maxWidth={"lg"} sx={{py: 5, px: 2, mx: "auto", width: "100%"}}>
      <Sidebar/>
      <Stack direction={"column"} gap={2} sx={{flex: 1, textAlign: "left !important"}}>
        <Outlet />
      </Stack>
    </Stack>
  )
}

const Sidebar = () => (
  <List sx={{display: {xs: "none", md: "block"}, minWidth: "280px"}}>
    <ListItemButton component={NavLink} to={"/view-profile"} sx={{"&.active": {color: "#E45271"}}}>
      <ListItemText primary={"Profile"}/>
    </ListItemButton>
    <ListItemButton component={NavLink} to={"/connect-wallet"} sx={{"&.active": {color: "#E45271"}}}>
      <ListItemText primary={"Connect Wallet"}/>
    </ListItemButton>
    <ListItemButton component={NavLink} to={"/payment-settings"} sx={{"&.active": {color: "#E45271"}}}>
      <ListItemText primary={"Payment Settings"}/>
    </ListItemButton>
    <ListItemButton component={NavLink} to={"/account-settings"} sx={{"&.active": {color: "#E45271"}}}>
      <ListItemText primary={"Account Settings"}/>
    </ListItemButton>
  </List>
)
