import {CountryInfo} from "./CountryInfo";
import {LocationBanner} from "./LocationBanner";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import bgImg1 from "../../../assets/backgrounds/card-bg-1.svg";
import {IconLock, IconUserShield} from "@tabler/icons-react";
import {ProtectionType} from "../../../utils/enums/ProtectionType";
import {ContactInfo} from "../../contact/ContactInfo";
import {ProtectIp} from "./ProtectIp";
import {StepButtons} from "./StepButtons";
import {countryData} from "../../../utils/countryData";


export const ProjectInformation = ({onNextStep, onPrevStep, onUpdate, registerWorkForm}) => {
  const isDisabled = !registerWorkForm?.location|| !registerWorkForm?.protection || registerWorkForm?.projectTypes?.length === 0 || !registerWorkForm?.projectDetails;

  return (
    <>
      <CountryInfo
        primaryTitle={"Where are you located?"}
        selectedCountry={registerWorkForm?.location}
        updateCountry={(country) => onUpdate("location", country)}
        showState={false}
      />
      <LocationBanner location={registerWorkForm?.location || null}/>
      <Stack direction={"row"} gap={4} alignItems={"stretch"} useFlexGap flexWrap={"wrap"}>
        <Stack
          direction={"row"} gap={2} alignItems={"center"} onClick={() => onUpdate("protection", ProtectionType.PROTECT_IP)}
          className={`protection-cart ${registerWorkForm?.protection === ProtectionType.PROTECT_IP ? "active" : ""}`}
          sx={{p: 4, borderRadius: 5, border: 1, borderColor: "divider", flex: 1, minWidth: "200px", cursor: "pointer"}}
        >
          <IconLock size={40}/>
          <Typography variant={"h4"} sx={{flex: 1}}>
            Protect your IP
          </Typography>
        </Stack>
        <Stack
          direction={"row"} gap={2} alignItems={"center"} onClick={() => onUpdate("protection", ProtectionType.HANDLE_IP)}
          className={`protection-cart ${registerWorkForm?.protection === ProtectionType.HANDLE_IP ? "active" : ""}`}
          sx={{p: 4, borderRadius: 5, border: 1, borderColor: "divider", flex: 1, minWidth: "200px", cursor: "pointer"}}
        >
          <IconUserShield size={40}/>
          <Typography variant={"h4"} sx={{flex: 1}}>
            Let us manage your IP or refer you to a partner
          </Typography>
        </Stack>
      </Stack>
      {
        {
          [ProtectionType.PROTECT_IP]: <>
            <ProtectIp onUpdate={onUpdate} registerWorkForm={registerWorkForm} />
            <StepButtons onBack={onPrevStep} onNext={onNextStep} isDisabled={isDisabled} />
          </>,
          [ProtectionType.HANDLE_IP]: <ContactInfo />
        }[registerWorkForm.protection]
      }

    </>
  )
}