import {
  Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import {useEffect, useState} from 'react';
const useConfirm = (customTitle, customMessage) => {
  const [promise, setPromise] = useState(null);
  const [title, setTitle] = useState("Are you sure you want to delete this?");
  const [message, setMessage] = useState("You will not be able to recover it?");

  useEffect(() => {
    setTitle(prev => customTitle || prev);
    setMessage(prev => customMessage || prev);
  }, [customTitle, customMessage]);

  const confirm = (customTitle = null, customMessage = null) => {
    setTitle(prev => customTitle || prev);
    setMessage(prev => customMessage || prev);

    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject });
    });
  }

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // You could replace the Dialog with your library's version
  const ConfirmationDialog = () => (
    <Dialog
      open={promise !== null}
      PaperProps={{
        sx: {py: 2, pr: 2, borderRadius: 3}
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          className={"sign-button"}
          sx={{borderRadius: 5, color: "#FFF"}}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
        <Button
          variant={"outlined"}
          color={"secondary"}
          sx={{borderRadius: 5}}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
  return [ConfirmationDialog, confirm];
};

export default useConfirm;