import {Box, Button, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import {IconChevronRight, IconConfetti, IconMail, IconPhoneCall} from "@tabler/icons-react";
import {AlertBanner} from "../../components/banner/AlertBanner";
import React, {useContext, useState} from "react";
import {useLocation} from "react-router-dom";
import {ProtectionType} from "../../utils/enums/ProtectionType";
import {grey} from "@mui/material/colors";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {AppContext} from "../../context/AppContext";
import {airtableBase} from "../../config/airtable";
import {useDebounce} from "../../hooks/useDebounce";
import AnimationMailSending from "../../components/lottie-animations/AnimationMailSending";
import AnimationFailed from "../../components/lottie-animations/AnimationFailed";
import AnimationSuccess from "../../components/lottie-animations/AnimationSuccess";

const ProtectionMessages = {
  [ProtectionType.HANDLE_IP]: "For complicated or sensitive requests, we can assist you in managing the registration process for your intellectual property.",
  [ProtectionType.REFERRAL]: "Sign up for our intelligent matchmaking service to be connected with a third party contact.",
}

const validationSchema = yup.object({
  firstName: yup
    .string('Enter your name')
    .required('Name is required'),
  lastName: yup
    .string('Enter your last name')
    .required('Last name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup
    .string('Enter your phone number')
    .required('Phone number is required'),
  message: yup
    .string('Enter your message')
    .min(8, 'Message should be of minimum 8 characters length')
    .required('Message is required'),
});

const StatusType = {
  NONE: "NONE",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
}

export const ContactInfo = ({message = "", title = "Feel free to contact us"}) => {
  const {state: {userDetails}} = useContext(AppContext)
  const [status, setStatus] = useState(StatusType.NONE);
  const statusDebounced = useDebounce(status, 1000);

  const formik = useFormik({
    initialValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      email: userDetails?.email || '',
      phone: userDetails?.phoneNumber || '',
      message: message || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setStatus(StatusType.PENDING);

      const fields = {};
      for (const [key, value] of Object.entries(values)) {
        const fieldKey = key[0].toUpperCase() + key.slice(1);
        fields[fieldKey] = value;
      }

      // send on form submit
      await airtableBase('contact-table').create([
        {
          "fields": {
            'First Name': values.firstName,
            'Last Name': values.lastName,
            'Phone': values.phone,
            'Email': values.email,
            'Message': values.message,
            'user current page': title || "Contact Page",
            'time': new Date(Date.now()).toLocaleString(),
            'IP Address': "ip"
          }
        },
      ], function (err) {
        if (err) {
          console.error(err);
          setStatus(StatusType.FAILED);
          return;
        }
        setStatus(StatusType.SUCCESS);
      });

      formik.resetForm({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      })
    },
  });

  const search = useLocation().search;
  const protection = new URLSearchParams(search).get('protection');

  if (protection) {
    title = ProtectionMessages[protection];
  }

  return (
    <Stack direction={"column"} maxWidth={"md"} gap={3} sx={{mx: "auto", p: 1, position: "relative"}}>
      {
        status != StatusType.NONE && <Stack alignItems={"center"} justifyContent={"center"} sx={{position: "absolute", inset: 0, bgcolor: "background.paper"}}>
          <AnimationMailSending open={status === StatusType.PENDING || statusDebounced === StatusType.PENDING} />
          <AnimationFailed open={statusDebounced === StatusType.FAILED} />
          <AnimationSuccess open={statusDebounced === StatusType.SUCCESS} />
        </Stack>
      }

      <Box sx={{mx: "auto"}}>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: "2rem",
            color: "#2D2D30",
          }}
        >
          Contact
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "0.8rem",
            color: grey[400],
            textTransform: "uppercase",
            pb: 2
          }}
        >
          {title}
        </Typography>
      </Box>

      <Stack component={"form"} onSubmit={formik.handleSubmit} direction={"row"} gap={2} useFlexGap flexWrap={"wrap"} sx={{opacity: status != StatusType.NONE ? 0 : 1}}>
        <TextField
          sx={{flex: 1}}
          InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          label={"First name *"}
          placeholder={"Enter your name"}
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          sx={{flex: 1}}
          InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          label={"Last name *"}
          placeholder={"Enter your last name"}
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          sx={{width: "100%"}}
          InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
          InputProps={{
            sx: {borderRadius: 3, bgcolor: "#FFF"},
            endAdornment: <InputAdornment position={"end"}><IconMail size={18}/></InputAdornment>
          }}
          id="email"
          name="email"
          label={"Email *"}
          placeholder={"Enter your email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          sx={{width: "100%"}}
          InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
          InputProps={{
            sx: {borderRadius: 3, bgcolor: "#FFF"},
            endAdornment: <InputAdornment position={"end"}><IconPhoneCall size={18}/></InputAdornment>
          }}
          label={"Phone number *"}
          placeholder={"Enter your phone number"}
          id="phone"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          multiline
          rows={3}
          sx={{width: "100%"}}
          InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          label={"Message *"}
          placeholder={"Message.."}
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />

        <Button
          fullWidth
          type={"submit"}
          className={"sign-button"}
          disabled={status !== StatusType.NONE}
          sx={{
            fontSize: 16, borderRadius: 3
          }}
          endIcon={<IconChevronRight size={18}/>}
        >
          Submit
        </Button>

      </Stack>
    </Stack>
  )
}