import React, {useContext, useEffect, useState} from "react";
import {
  Stack,
  Toolbar, useMediaQuery, useTheme
} from "@mui/material";
import {Header} from "./header/Header";
import {Outlet} from "react-router-dom";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {MiniHeader} from "../mini/MiniHeader";
import {AcceptTerms} from "../../components/accept-terms/AcceptTerms";
import {Sidebar} from "./sidebar/Sidebar";
import {AppContext} from "../../context/AppContext";
import Modals from "../../components/modals/modals";

export const MainLayout = () => {
  const {state: {userDetails}} = useContext(AppContext)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  if (userDetails === null) {
    return <LoadingScreen/>
  }
  if (!userDetails?.acceptTerms) {
    return (
      <Stack className={"mini-bgcolor"} justifyContent={"center"} alignItems={"center"}
             sx={{height: "100dvh", width: "100vw"}}>
        <MiniHeader/>
        <AcceptTerms/>
      </Stack>
    )
  }

  return (
    <Stack
      direction={"column"}
      sx={{
        width: "100vw",
        minHeight: "100vh",
        bgcolor: 'transparent',
        textAlign: '-webkit-center',
        overflowX: "hidden",
      }}
    >
      <Modals/>
      <Header handleOpenDrawer={() => setOpen(!open)}/>
      <Sidebar open={open} setOpen={setOpen}/>
      <Stack direction={"column"} sx={{
        flex: 1,
        pl: {xs: 2, md: 15},
        pr: {xs: 2, md: 15},
        pt: 2,
        pb: {xs: "260px", md: 2},
        overflowY: "auto",
        overflowX: "hidden"
      }}>
        <Toolbar sx={{py: 1}}/>
        <Outlet/>
      </Stack>
    </Stack>
  )
}
