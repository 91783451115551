import {doc, writeBatch} from "firebase/firestore";
import {db} from "../config/firebase";

export const handleArchiveLicense = async ({license, nft, handleSuccess}) => {
  const nftRef = doc(db, 'royalty_templates', nft?.uid)
  const licenseRef = doc(db, 'licenses', license?.uid)

  const batch = writeBatch(db);
  const isArchived = !license?.archive;
  const licenses = nft?.licenses?.map(l => {
    if (l?.uid === license?.uid) {
      return {...l, archive: isArchived};
    }
    return {...l};
  }) || []

  const newLicense = {archive: isArchived}
  const updateNft = {licenses}

  batch.update(licenseRef, newLicense);
  batch.update(nftRef, updateNft);

  await batch.commit();
  handleSuccess({...license, ...newLicense}, {...nft, ...updateNft})
}