import React from "react";
import {MainLayout} from "../../layouts/main/MainLayout";

import {useParams} from "react-router-dom";
import {
    Stack,
    Box,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Select, MenuItem, ButtonGroup
} from "@mui/material"
import {grey} from "@mui/material/colors"
import {IconAdjustmentsAlt, IconSearch, IconBorderAll, IconMenu2} from "@tabler/icons-react";
import {ImageCard} from "../../components/card/ImageCard";
import {UserInfo} from "./UserInfo";

export const Profile = () => {
    const {id} = useParams();

    return (
        <>
            <UserInfo id={id}/>
            <ProfileItems />
        </>
    )
}

const ProfileItems = () => {
    return (
        <Box
            sx={{
                maxWidth: {xs: "90vw", md: "80vw"},
                width: "100%",
                my: 2,
                mx: "auto",
                minHeight: "250px",
                bgcolor: "#FFF",
                borderRadius: 5,
                boxShadow: 5,
            }}
        >
            <Box sx={{m: 2}}>
                <Box sx={{pt: 1, borderBottom: 1, borderColor: "divider"}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%"}}>
                        <Button
                            sx={{
                                borderRadius: 0,
                                borderBottom: 1,
                                textTransform: "none",
                                color: "#000",
                                borderColor: "#000"
                            }}
                        >
                            Created
                        </Button>
                        <Typography sx={{color: "#E7526F", fontWeight: 600}}>
                            Total: 500
                        </Typography>
                    </Stack>
                </Box>

                <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%", my: 1}}>
                    <Button
                        sx={{
                            px: 2,
                            borderRadius: "0.75rem",
                            textTransform: "none",
                            color: "#D55380",
                            fontWeight: "700",
                            background: "linear-gradient(94deg, rgba(141, 87, 195, 0.11) -10.69%, rgba(228, 82, 113, 0.11) 59.43%, rgba(252, 136, 75, 0.11) 104.02%)"
                        }}
                    >
                        Filters <IconAdjustmentsAlt size={18} />
                    </Button>
                    <TextField
                        size={"small"}
                        placeholder={"Search by NFTs.."}
                        sx={{flex: 2}}
                        InputProps={{
                            sx: {borderRadius:  5},
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch size={16} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Select
                        // value={age}
                        // onChange={handleChange}
                        size={"small"}
                        sx={{borderRadius: "0.75rem", flexShrink: 0, width: "12rem"}}
                        MenuProps={{
                            sx: {
                                borderRadius: "0.75rem",
                            }
                        }}
                        value={2}
                    >
                        <MenuItem value={1}>Recently minted</MenuItem>
                        <MenuItem value={2}>Price: low to high</MenuItem>
                        <MenuItem value={3}>Price: high to low</MenuItem>
                    </Select>
                    <ButtonGroup  >
                        <Button sx={{color: grey[800], borderColor: grey[800], bgcolor: grey[100], "&:hover": {color: grey[800], borderColor: grey[800],} }}>
                            <IconBorderAll size={18} />
                        </Button>
                        <Button sx={{color: grey[500], borderColor: grey[500], bgcolor: "transparent", "&:hover": {color: grey[800], borderColor: grey[800],} }}>
                            <IconMenu2 size={18} />
                        </Button>
                    </ButtonGroup>
                </Stack>

                <Stack direction={"row"} gap={2} justifyContent={"space-around"} alignItems={"center"} useFlexGap flexWrap={"wrap"} sx={{py: 2}}>
                    <ImageCard />
                    <ImageCard />
                    <ImageCard />
                    <ImageCard />
                    <ImageCard />
                    <ImageCard />
                    <ImageCard />
                </Stack>
            </Box>
        </Box>
    )
}

