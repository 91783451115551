import {Box, Button, Stack, Typography} from "@mui/material";
import bgImg1 from "../../../assets/backgrounds/card-bg-1.svg";
import {LicenseType} from "../../../utils/enums/LicenseType";
import {useState} from "react";
import {LicenseIntroductionModal} from "./LicenseIntroductionModal";

export const ChooseLicenseTemplate = ({onNextStep, onUpdate}) => {
  const [openIntroduction, setOpenIntroduction] = useState(false);

  const onMonetize = () => {
    onUpdate("type", LicenseType.MONETIZED);
    onNextStep();
  }

  const onNonMonetize = () => {
    onUpdate("type", LicenseType.NON_MONETIZED);
    onNextStep();
  }

  return (
    <>
      <LicenseIntroductionModal
        open={openIntroduction}
        handleClose={() => setOpenIntroduction(false)}
      />
      <Box fullWidth sx={{width: "100%"}}>
        <Button className={"text-grey"} fontSize={"0.85rem"} onClick={() => setOpenIntroduction(true)}>
          Want a 101 crash course in 4 paragraphs on monetization and licensing?
          <Typography component={'span'} sx={{p: 0, m: 0, mx: 1, textDecoration: "underline"}} className={"text-grey"}>
            Click here
          </Typography>
        </Button>
      </Box>
      <Stack direction={"row"} gap={4} alignItems={"stretch"} useFlexGap flexWrap={"wrap"}
             sx={{color: "#FFFFFF !important"}}>

        <Stack direction={"column"} gap={2} className={"royalty-card1"} onClick={onMonetize} sx={{
          background: `url(${bgImg1})`,
          cursor: "pointer",
          p: 4,
          borderRadius: 8,
          flex: 2,
          minWidth: "250px"
        }}>
          <Typography variant={"h4"} fontWeight={700} align={"left"}>Monetize my work</Typography>
          <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
            This option creates a license that allows you to monetize your creative work. You can offer your work for
            sale, receive royalties, while still controlling its use, distribution, and adaption.
          </Typography>
          <div style={{marginTop: "auto"}}>
            <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
              Consider this option if:
            </Typography>
            <ul style={{paddingTop: 0, margin: 0}}>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
                  You want to earn revenue from your creative work.
                </Typography>
              </li>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
                  Your work has commercial potential.
                </Typography>
              </li>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
                  You are looking to collaborate with others want to use your work in a commercial capacity.
                </Typography>
              </li>
            </ul>
          </div>

          <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"} sx={{pt: {xs: 2, md: 5}}}>
            <Box>
              <Typography variant={"h4"} fontWeight={400} align={"left"}>Free</Typography>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>~3 minutes</Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={2} className={"royalty-card1"} onClick={onNonMonetize} sx={{
          background: `url(${bgImg1})`,
          cursor: "pointer",
          p: 4,
          borderRadius: 8,
          flex: 1,
          minWidth: "250px"
        }}>
          <Typography variant={"h4"} fontWeight={700} align={"left"}>Do not monetize my work</Typography>
          <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>This creates a license allowing you to share
            your work freely without monetization. It lets you maintain control over its use and is ideal for
            contributing to the public domain or promoting open access.</Typography>
          <div style={{marginTop: "auto"}}>
            <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
              Consider this option if:
            </Typography>
            <ul style={{paddingTop: 0, margin: 0}}>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>You want your work to be freely
                  available for others.</Typography>
              </li>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>You are not interested in financial
                  gain.</Typography>
              </li>
              <li>
                <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>You prefer to maintain control over
                  how your work is used.</Typography>
              </li>
            </ul>
          </div>

          <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"} sx={{pt: {xs: 2, md: 5}}}>
            <Box>
              <Typography variant={"h4"} fontWeight={400} align={"left"}>Free</Typography>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>~3 minutes</Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}