import {IconBrandAirtable, IconCopy, IconTable} from "@tabler/icons-react";
import React, {useState} from "react";
import {Box, Stack, Table, TableCell, TableRow, Typography, Grid} from "@mui/material";
import {SelectableCard} from "../../../components/SelectableCard";
import {TemplateType} from "../../../utils/enums/TemplateType";
import {CreativeCommonLicenseTemplates} from "../../../utils/CreativeCommonLicenseTemplates";
import {useAtomValue} from "jotai";
import {LicenseTemplatesAtom} from "../../../atoms";

const templateItems = [
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.NON_LICENSE,
    title: "No License",
    description: "You don't want your work to be available to anyone for any reason."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.PUBLIC_DOMAIN,
    title: "The Public Domain (CC0)",
    description: "You want your work to be freely available to anyone for any reason."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.ANTI_PLAGIARIST,
    title: "The Anti-Plagiarist",
    description: "The Anti-Plagiarist (CC-BY) license is a freebie license."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.COPYLEFT,
    title: "The Copyleft",
    description: "This license helps your work be available, but restricts commercial use."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.ONE_AND_ONLY,
    title: "The One and Only",
    description: "You want credit, your work to be freely available, but not modified or adapted."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.ANTI_MATERIALIST,
    title: "The Anti-Materialist",
    description: "You want credit, your work to be freely available, but not for commercial use."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.ANTI_MATERIALIST_COPYLEFT,
    title: "The Anti-Materialist's Copyleft",
    description: "You want credit, freely available, and don't want anyone to make money off of your work."
  },
  {
    icon: <IconTable size={20}/>,
    type: TemplateType.ANTI_MATERIALIST_ONE_AND_ONLY,
    title: "The Anti-Materialist's One and Only",
    description: "You want credit, freely available, but not modified or adapted, and not for commercial use."
  },
]

export const NotMonetizeTemplates = ({license}) => {
  const rights = useAtomValue(LicenseTemplatesAtom);
  // const selectedTemplate = rights?.find(item => item.type === license?.type && item?.rights === license?.licenses)


  return (
    <Box>
      {/*</Stack>*/}
      {license &&
        <Box sx={{py: 3}} maxWidth={"md"}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1rem",
              color: "#2D2D30",
              pb: 1
            }}
          >
            {license?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.9rem",
              color: "#949494",
              pb: 1
            }}
          >
            {license?.description}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.9rem",
              color: "#949494",
              pb: 1, display: license?.link ? "flex" : "none"
            }}
          >
            For more information here: <a href={license?.link} target={"_blank"}
                                          style={{color: "#E45271"}}>{license?.link}</a>
          </Typography>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "1rem",
              color: "#2D2D30",
              py: 1, display: Object.keys(license?.licenses)?.length > 0 ? "flex" : "none"
            }}
          >
            Here is a breakdown of the rights you are giving:
          </Typography>
          <Table>
            {Object.keys(license?.licenses || {}).map(key => {
              const name = key?.toLowerCase()?.replaceAll("_", " ")?.replaceAll("and", "&");
              const value = license?.licenses[key]?.toString()
              return (
                <TableRow sx={{p: 0}}>
                  <TableCell sx={{textTransform: "capitalize", pb: 0.5, pt: 2}}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#949494",
                        pb: 1,
                        textTransform: "capitalize"
                      }}
                    >
                      {name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{textTransform: "capitalize", p: 0, pt: 1}}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "#949494",
                        pb: 1,
                        textTransform: "capitalize"
                      }}
                    >
                      {value}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </Box>
      }
    </Box>
  )
}
