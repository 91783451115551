import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Checkbox, Divider, Fade, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {grey, lightBlue, orange, teal} from "@mui/material/colors";
import GoogleButton from "react-google-button";
import {ArrowForwardIos} from "@mui/icons-material";
import {MiniHeader} from "../../../layouts/mini/MiniHeader";
import {auth, db, googleProvider} from "../../../config/firebase";
import {signInWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import "../../../miscellaneous-styles.css";
import {doc, setDoc, getDoc} from "firebase/firestore";

export const LoginPage = () => {
  const [loginWithMail, setLoginWithMail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const passwordRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginWithMail) {
      passwordRef.current?.focus();
    }
  }, [loginWithMail])

  async function handleLoginGoogle() {
    console.log('login google')
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/my-counts")
    } catch (err) {
      console.log(err);
    }
  }

  const loginHandle = async (e) => {
    e?.preventDefault();
    if (!loginWithMail) {
      setLoginWithMail(true);
      return;
    }
    try {
      if (email && password) {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (err) {
      toast.error(err.message.replace("Firebase: ", ""));
    }
  }

  return (
    <Stack direction={"column"}
           sx={{width: "100%", height: "100%"}}
           justifyContent={'center'}
           alignItems={"center"}>
      <Box sx={{
        bgcolor: "#FFF",
        border: 1,
        borderRadius: 5,
        borderColor: "divider",
        p: 5,
        minWidth: "300px",
        backgroundColor: "rgba(255,255,255, 0.65)",
        padding: "40px 60px",
      }}>
        <Stack component={"form"} onSubmit={loginHandle} direction={"column"} gap={2}
               sx={{width: "100%", height: "100%"}} justifyContent={'center'}
               alignItems={"center"}>
          {/*HEADER*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
            {/*TITLE*/}
            <Typography variant={"h2"} fontWeight={700} className={"login-title"}>
              Log In
            </Typography>
            {/*SIGN UP*/}
            <div style={{display: "inline-flex", gap: 8, alignItems: "center"}}>
              <Typography className={"login-subtitle"} component={"span"}>Don't have account
                yet?</Typography>
              <Typography variant={"span"} component={Link} to={"/register"}
                          className={"login-subtitle alt-color no-underline"}>Sign up</Typography>
            </div>
          </Stack>
          {/*LOGIN WITH MAIL*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%', marginTop: "20px"}}>
            <TextField
              type={"email"}
              variant={"standard"}
              required
              fullWidth
              label={"Email"}
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={"Enter your email address"}
            />
            {
              loginWithMail &&
              <Fade
                in={loginWithMail}
              >
                <TextField
                  ref={passwordRef}
                  autoFocus
                  type={"password"}
                  variant={"standard"}
                  required
                  fullWidth
                  label={"Password"}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder={"Password"}
                />
              </Fade>
            }

            <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}
                   useFlexGap flexWrap={"wrap"}>
              <FormControlLabel sx={{color: grey[700]}} control={<Checkbox defaultChecked
                                                                           sx={{"&.Mui-checked": {color: "#E7526F"}}}/>}
                                label="Remember me"/>
              <Typography sx={{textDecoration: "none", color: grey[700]}} component={Link}
                          to={"/forgot-password"}>Forgot password?</Typography>
            </Stack>

            <Button
              fullWidth
              variant={"contained"}
              type={"submit"}
              className={"sign-button"}
              sx={{
                textTransform: "capitalize",
                bgcolor: lightBlue[400],
                fontWeight: "bold",
                '&:hover': {bgcolor: lightBlue[600]}
              }}
              disabled={!email || (loginWithMail && !password)}
              endIcon={<ArrowForwardIos sx={{fontSize: 12}}/>}
            >
              {loginWithMail ? "Log in" : "Continue"}
            </Button>


          </Stack>
          <Box sx={{width: "100%", margin: "7px 0px"}}>
            <Divider className={"small-subtitle"}>or sign in with</Divider>
          </Box>
          <Box sx={{width: "100%"}}>

            <GoogleButton
              style={{width: "100%", borderRadius: "8px", boxShadow: "none"}}
              onClick={() => handleLoginGoogle()}
              type={"light"}
            />
          </Box>

        </Stack>
      </Box>
    </Stack>
  )
}
