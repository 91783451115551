import React, {useEffect, useState} from 'react';
import {Box, Button, CardContent, Link, Typography} from "@mui/material";
import Card from '@mui/material/Card';
import { AlertBanner } from './banner/AlertBanner';
import { WarningAmberRounded } from '@mui/icons-material';

export default function CustomCard({connector, hooks, name, setAccount}) {
    const {useSelectedAccount, useSelectedChainId, useSelectedIsActive, useSelectedIsActivating} = hooks;
    const isActivating = useSelectedIsActivating(connector);
    const isActive = useSelectedIsActive(connector);
    const account = useSelectedAccount(connector);
    const chain = useSelectedChainId(connector);
    const [error, setError] = useState(undefined);
    const [hasPhantom, setHasPhantom] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState('Disconnected');

    useEffect(() => {
        account && console.log(account);
        setAccount && setAccount(account);
    }, [account]);

  useEffect(() => {
    checkHasPhantom();
    const interval = setInterval(() => {
      if ('phantom' in window) {
        setHasPhantom(true);
        clearInterval(interval); // Stop checking once Phantom is found
      }
    }, 1000); // Check every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

    const checkHasPhantom = () => {
      setHasPhantom('phantom' in window);
    }

    const handleToggleConnect = async () => {
        setError(undefined); // clear error state

        if (isActive) {
            if (connector && connector.deactivate) {
                void connector.deactivate();
            } else {
                void connector.resetState();
            }
        } else if (!isActivating) {
            setConnectionStatus('Connecting..');
            try{
                connector.activate(1);
            } catch (e) {
                connector.resetState();
                setError(e);
            }
        }
    };

    useEffect(() => {
        if (isActive) {
            setConnectionStatus('Connected');
        } else {
            setConnectionStatus('Disconnected');
        }
    }, [isActive]);

    return (
        <Card sx={{marginTop: 3, boxShadow: "none", padding: 0}}>
            <CardContent sx={{padding: 0}}>
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        color: "#2D2D30",
                        pb: 1
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "0.8rem",
                        color: "#949494",
                        pb: 1
                    }}
                >
                    After clicking Submit, your creative work will appear as an NFT in this wallet.<br/>
                    If you do not have a Phantom wallet yet, you can <a href="https://phantom.app/" target={"_blank"}>create
                    one here</a>
                </Typography>

                {/*<Typography variant={'h3'}><strong>{name.toUpperCase()}</strong></Typography>*/}
                <Box sx={{marginTop: 2, padding: 0}}>
                    <Typography
                        sx={{
                            fontSize: "0.8rem",
                            // Check if the connectionStatus is 'Connected' and change color to green
                            color: connectionStatus === 'Connected' ? '#559377' : "#949494",
                            fontWeight: connectionStatus === 'Connected' ? 'bold' : 'none',
                            fontStyle: connectionStatus === 'Connected' ? 'italic' : 'none',
                            pb: 1
                        }}
                    >
                        Status: {error?.message ? `Error: ${error.message}` : <strong>{connectionStatus}</strong>}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "0.8rem",
                            color: "#949494",
                            pb: 1
                        }}
                    >Wallet Public Address - {account ?
                        <strong>{account}</strong> : 'No Account Detected'}</Typography>
                    {/*<Typography>(after minting NFT will appear in this wallet)</Typography>*/}
                    {/*<Typography variabt={'h5'}>ChainId - {chain ? chain : 'No Chain Connected'}</Typography>*/}
                    {//If there is an error while connecting the wallet, this displays
                    error &&
                    <Box sx={{mt: 2}}>
                        <Typography sx={{
                            fontSize: "0.8rem",
                            color: "#d55380",
                            fontWeight: 'bold',
                        }}>
                            No wallet detected.
                        </Typography>
                        <Typography sx={{
                            fontSize: "0.8rem",
                            color: "#949494",
                        }}>
                            A digital wallet is free and takes ~2 minutes to setup.
                        </Typography>
                        <Link href="https://phantom.app/" target={'_blank'} sx={{color: '#ef806f', textDecorationColor: '#ef806f', pt: 0 }}>Download Phantom Wallet.</Link>
                        <AlertBanner
                        text={<Typography variant='caption' color="#d55380" >
                            If you already have a Phantom Wallet and installed the Phantom extension, <br />
                            <strong>make sure you open and unlock the extension first.</strong> Then click Connect. </Typography>
                        }
                        icon={<WarningAmberRounded sx={{color: '#d55380'}} fontSize='large'/>} />
                    </Box>
                    }
                    {hasPhantom ?
                        <Button sx={{marginTop: 2, fontSize: 16, px: 4, borderRadius: 3}} className={"sign-button"} variant={'contained'}
                                onClick={handleToggleConnect}>
                            {isActive ? 'Disconnect' : 'Connect'}
                        </Button>
                        :
                        <Button sx={{marginTop: 2, fontSize: 16}} className={"sign-button"} variant={'contained'}
                                onClick={()=> {
                                  if ("phantom" in window) {
                                    setHasPhantom(true);
                                  } else {
                                    window.open('https://phantom.app', "_blank")
                                  }
                                }}
                        >
                            Create Phantom Wallet
                        </Button>
                    }

                </Box>
            </CardContent>
        </Card>
    );
}
