import {CardRounded} from "../../../components/card/CardRounded";
import {Avatar, Skeleton, Stack, Typography} from "@mui/material";
import {BadgeBox} from "../../../components/card/BadgeBox";
import {useNavigate} from "react-router-dom";
import {usePrismicDocumentByID} from "@prismicio/react";

export const UnlockPotentialWork = () => {
  const navigate = useNavigate();
  const id = "ZnrTNhEAACAA1NQf";
  const [article] = usePrismicDocumentByID(id);

  return (
    article ? <CardRounded
        sx={{
          backgroundImage: `url(${article?.data?.cover_image?.url}) !important`,
          p: 4, cursor: "pointer", position: "relative"
        }} onClick={() => navigate(`/blog/${id}`)}
        className={"event-section-bg"}>
        <Typography variant={"h4"} fontWeight={600} align={"left"} sx={{mr: "auto"}}>
          {article?.data?.title[0]?.text || ""}
        </Typography>
        <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} sx={{p: 1}}>
          <Avatar sx={{
            bgcolor: "rgba(0,0,0, 0.1)",
            backdropFilter: "blur(2px)",
            border: 0.5,
            borderColor: "#fefefe",
            color: "#fefefe",
            p: 0.5
          }}/>
          <div style={{textAlign: "left"}}>
            <Typography variant={"h6"} fontWeight={500} fontSize={"1.1rem"}
                        align={'left'}>{article?.data?.author_name[0]?.text?.trim()}</Typography>
            <Typography variant={"subtitle2"} fontWeight={400} color={'lightgrey'}
                        align={'left'}>{article?.data?.category[0]?.text || ""}</Typography>
          </div>
        </Stack>


        <BadgeBox sx={{position: "absolute", bottom: 4 * 8, right: 4 * 8}}>
          {article?.data?.read_time[0]?.text || "5 min"}
        </BadgeBox>
      </CardRounded>
      : <Skeleton variant="rectangular" width={"100%"} height={"35dvh"} sx={{borderRadius: 5, p: 4}}/>
  )
}