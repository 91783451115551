import {Button, Stack, Typography} from "@mui/material";
import {terms} from "../../utils/terms";
import {useContext, useEffect, useState} from "react";
import {signOut} from "firebase/auth";
import {auth} from "../../config/firebase";
import {toast} from "react-toastify";
import {AppContext} from "../../context/AppContext";
import termsMd from "../../assets/md/termsofservicemarkdown.md"
import ReactMarkdown from 'react-markdown'

const ViewPage = {
  TERMS: "TERMS",
  DECLINE: "DECLINE"
}

export const AcceptTerms = () => {
  const [viewPage, setViewPage] = useState(ViewPage.TERMS);

  return (
    <Stack direction={"column"} gap={1} maxWidth={"md"}
           sx={{width: "95%", height: "70dvh", bgcolor: "#FFFFFF", borderRadius: 5, p: 4, m: "auto"}}>
      {
        {
          [ViewPage.TERMS]: <AcceptTermsView onDecline={() => setViewPage(ViewPage.DECLINE)}/>,
          [ViewPage.DECLINE]: <DeclineTermsView onBack={() => setViewPage(ViewPage.TERMS)}/>,
        }[viewPage]
      }
    </Stack>
  )
}

const AcceptTermsView = ({onDecline}) => {
  const {updateUserDetails} = useContext(AppContext);

  const [text, setText] = useState("");

  useEffect(() => {
    fetch(termsMd)
      .then((response) => response.text())
      .then((text) => {
        setText(text);
      })
  })


  const onAccept = async () => {
    try {
      updateUserDetails({acceptTerms: true});
    } catch (e) {
      console.log(e);
      toast.error(e?.message?.replaceAll("Firebase:", ""))
    }
  }

  return (
    <>
      <Typography variant={"h2"} fontWeight={600} align={"center"}>Terms of Agreement</Typography>
      <Stack direction={"column"} gap={1} sx={{flex: 1, overflowY: "auto"}}>
        <ReactMarkdown>
          {text}
        </ReactMarkdown>
      </Stack>
      <Stack direction={"row"} gap={2} alignitems={"center"} justifyContent={"center"}>
        <Button variant={"outlined"} color={"secondary"} sx={{borderRadius: 2, px: 3}}
                onClick={onDecline}>Decline</Button>
        <Button variant={"contained"} className={"sign-button"} sx={{borderRadius: 2, px: 3}}
                onClick={onAccept}>Accept</Button>
      </Stack>
    </>
  )
}

const DeclineTermsView = ({onBack}) => {
  const onLogout = async () => {
    await signOut(auth);
  }

  return (
    <>
      <Typography variant={"h2"} fontWeight={600} align={"center"}>{"We’re sorry"}</Typography>
      <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={1}
             sx={{py: 3, overflowY: "auto"}}>
        <Typography whiteSpace={"pre-line"}>
          {terms.decline_text}
        </Typography>
      </Stack>
      <Stack direction={"column"} gap={2} alignitems={"center"} justifyContent={"center"}>
        <Button variant={"contained"} className={"sign-button"} sx={{borderRadius: 2, px: 3}} onClick={onBack}>Return to
          Terms of Service</Button>
        <Button variant={"outlined"} color={"secondary"} sx={{borderRadius: 2, px: 3}} onClick={onLogout}>Return to our
          home page</Button>
      </Stack>
    </>
  )
}
