import {Box, List, ListItem, Stack, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../context/AppContext";
import {toast} from "react-toastify";
import {IOSSwitch} from "../../../components/switch/Switch";

export const ViewProfilePage = () => {
  const {state: {userDetails}, updateUserDetails} = useContext(AppContext);

  const onPublicChange = async (e) => {
    try {
      const value = e.target.checked;
      await updateUserDetails({publicAccount: value});
      toast.success(`Your profile is now ${value ? "public" : "private"}`)

    } catch (e) {
      toast.error(e?.message?.replaceAll("Firebase:", "") || "Failed to update profile")
    }
  }

  return (
    <Stack direction={"row"} gap={2} sx={{textAlign: "left !important"}}>
      <Box>
        <IOSSwitch
          checked={userDetails?.publicAccount || false}
          onChange={onPublicChange}
        />
      </Box>
      <Box sx={{flex: 1, color: "#656565 !important", py: 0.5}}>
        {
          userDetails?.publicAccount ?
            <>
              <Typography>Your account is currently <Typography component={"span"} className={"text-purple"}>public</Typography>. This means that..</Typography>
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>For any creative work that you have clicked the “Make this work publicly available on the marketplace” button <Typography component={"span"} className={"text-purple"}>will be shown</Typography> on the C3 marketplace.</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', ml: 1 }}>
                  <Typography>However, by default, all work is private and <Typography component={"span"} className={"text-purple"}>unless you click</Typography> the “Make public” button, all other work will be <Typography component={"span"} className={"text-purple"}>private.</Typography></Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography><Typography component={"span"} className={"text-purple"}>If you choose to share</Typography> a license QR code or URL publicly, anyone who scans the QR or sees the URL <Typography component={"span"} className={"text-purple"}>will be able to see</Typography> the public License terms that you have set.</Typography>
                </ListItem>
              </List>
            </>
            :
            <>
              <Typography>Your account is currently <Typography component={"span"} className={"text-purple"}>hidden</Typography>. This means that..</Typography>
              <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>Your creative work <Typography component={"span"} className={"text-purple"}>will not</Typography> be shown on the C3 marketplace.</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography>However, <Typography component={"span"} className={"text-purple"}>if you choose to share</Typography> a license QR code or URL publicly, anyone who scans the QR or sees the URL will be able to see the public License terms that you have set.</Typography>
                </ListItem>
              </List>
            </>
        }
      </Box>
    </Stack>
  )
}