import {Button, Stack, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import React, {useEffect, useState} from "react";
import {auth, db} from "../../config/firebase";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {CardView} from "../portfolio/components/license/CardsView";
import {IconAlertTriangle} from "@tabler/icons-react";
import {AlertBanner} from "../../components/banner/AlertBanner";
import {toast} from "react-toastify";
import {useSetAtom} from "jotai";
import {RequestLicenseModalAtom} from "../../atoms";

export const RoyaltyTemplatePublicPage = () => {
  const {templateId} = useParams();
  const setLicenseRequest = useSetAtom(RequestLicenseModalAtom);
  const [license, setLicense] = useState(null);
  const [nft, setNft] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "licenses", templateId);
        const docSnap = await getDoc(docRef);

        if (docSnap?.exists()) {
          const license = {...docSnap?.data()}

          if (license?.archive) {
            toast.info("License is archived and is not available for public view");
            navigate("/")
          }
          setLicense(license);
        } else {
          navigate("/")
        }

      } catch (error) {
        console.log(error);
      }
    }

    if (templateId) {
      fetchData();
    }

  }, [templateId])

  useEffect(() => {
    if (templateId) {
      const unsubscribe = onSnapshot(doc(db, "licenses", templateId), (doc) => {
        const data = doc.data();
        if (doc.exists()) {
          if (data?.archive) {
            toast.info("License is archived and is not available for public view", {toastId: "archive"})
            navigate("/")
          }
          if (data) {
            setLicense(data);
          }
        } else {
          navigate("/")
        }

      });

      return () => unsubscribe();
    }
  }, [templateId])

  useEffect(() => {
    if (license && license?.nftUid) {
      const unsubscribe = onSnapshot(doc(db, "royalty_templates", license?.nftUid), (doc) => {
        const data = doc.data();
        if (!data || !data?.uid) {
          navigate("/")
        }
        setNft(data);
      });

      return () => unsubscribe();
    }
  }, [license])

  return (
    license && nft ?
      <Stack direction={"column"} gap={2} sx={{
        width: {xs: "90vw", md: "60vw"},
        minHeight: "50vh",
        p: {xs: 3, md: 5},
        bgcolor: "background.paper",
        m: "auto",
        borderRadius: 5,
        boxShadow: 10
      }}>
        <Typography variant={"h2"} align={"center"} fontWeight={700}>{license?.publicTitle}</Typography>
        <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"}>
          <Button className={"sign-button"}
                  onClick={() => setLicenseRequest(license)}
                  sx={{
                    borderRadius: 5,
                    px: 2,
                    ml: "auto"
                  }}>
            Contact Creator
          </Button>
        </Stack>
        <CardView nft={nft} license={license} hideTitle/>
        {
          !auth.currentUser &&
          <AlertBanner
            icon={<IconAlertTriangle color={"#6D57C3"} size={40}/>}
            color={"#6D57C3"}
            fontSize={15}
            bgcolor={`linear-gradient(94deg, rgba(109, 87, 195, 0.3) -10.69%, rgba(82, 123, 228, 0.25) 59.43%, rgba(75, 188, 252, 0.2) 104.02%)`}
            text={<Typography className={"alert-text"}>
              Creator?
              <Typography component={Link} to={"/register"}
                          style={{color: "#6D57C3", fontWeight: "bold"}}>Create</Typography>
              your account today!
            </Typography>}
          />
        }

      </Stack>
      : <LoadingScreen/>
  )
}