import Modal from "@mui/material/Modal";
import {Box, Button, Grid, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import {IconChevronRight, IconMail, IconPhoneCall} from "@tabler/icons-react";
import {doc, setDoc, updateDoc} from "firebase/firestore";
import {auth, db} from "../../../../config/firebase";
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {AppContext} from "../../../../context/AppContext";
import {LicenseType, LicenseRequestType} from "../../../../utils/enums/LicenseRequestType";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-47%, -50%)',
  maxWidth: {xs: "90vw", md: "60vw", lg: "40vw"}, maxHeight: "95dvh", minWidth: "300px",
  minHeight: '80 dvh',
  bgcolor: 'background.paper',
  borderRadius: 8, border: 0,
  boxShadow: 24, p: 3, pr: 5, pb: 5, overflowY: "auto"
};

const validationSchema = yup.object({
  firstName: yup
    .string('Enter your name')
    .required('Name is required'),
  lastName: yup
    .string('Enter your last name')
    .required('Last name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string('Enter your phone number')
    .notRequired(),
  recipientMail: yup
    .string('Enter recipient email')
    .required('Recipient email is required'),
});

export const SignTemplateModal = ({open, handleClose, license, setLicense}) => {
  const [loading, setLoading] = useState(false);
  const {state: {userDetails}} = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      email: userDetails?.email || '',
      phoneNumber: userDetails?.phoneNumber || '',
      recipientMail: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!license || !license?.uid) {
          return;
        }

        toast.success(`License request sent to '${values.recipientMail}'`);
        const uid = uuid();

        const createdAt = new Date().getTime();
        
        await setDoc(
          doc(db, "license_requests", uid),
          {
            uid,
            ...values,
            state: LicenseRequestType.PENDING,
            type: LicenseType.SEND_LICENCE,
            licenseUid: license?.uid,
            nftUid: license?.nftUid,
            createdAt: createdAt,
            expirationDate: new Date(createdAt + (7 * 24 * 60 * 60 * 1000)).getTime(),
          }
        )

        formik.resetForm({
          firstName: userDetails?.firstName || '',
          lastName: userDetails?.lastName || '',
          email: userDetails?.email || '',
          phoneNumber: userDetails?.phoneNumber || '',
          recipientMail: ""
        })
        handleClose();
      } catch (e) {
        console.log(e);
        toast.error("Failed to sign template")
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (open) {
      formik.setValues({
        firstName: userDetails?.firstName || '',
        lastName: userDetails?.lastName || '',
        email: userDetails?.email || '',
        phoneNumber: userDetails?.phoneNumber || '',
        recipientMail: ""
      })
    }
  }, [open, userDetails])

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Grid container component={"form"} onSubmit={formik.handleSubmit} spacing={2} sx={style}>
        <Grid item xs={12}>
          <Stack direction={"column"} gap={1} maxWidth={"sm"} sx={{textAlign: 'center', mx: "auto"}}>
            <Typography variant={"h3"} fontWeight={600}>Send to licensee</Typography>
            <Typography variant={"caption"} color="GrayText" sx={{pb: 1, pt: 0, mt: 2}} fontWeight={400}>
              We will send a
              notification to the licensee to accept this license.
            </Typography>
            <Typography variant="caption" color="GrayText">
              What is your contact information?
              Only your Username will be shared with the licensee.
              We contact the licensee via C3’s email address.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{flex: 1}} fullWidth
            InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
            InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
            label={"First name *"}
            placeholder={"Name"}
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={userDetails?.firstName}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{flex: 1}} fullWidth
            InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
            InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
            label={"Last name *"}
            placeholder={"Last name"}
            id="lastName"
            name="lastName"
            disabled={userDetails?.lastName}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{flex: 1}} fullWidth
            InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
            InputProps={{
              sx: {borderRadius: 3, bgcolor: "#FFF"},
              endAdornment: <InputAdornment position={"end"}><IconMail/></InputAdornment>
            }}
            label={"Email *"}
            placeholder={"yourmail@gmail.com"}
            id="email"
            name="email"
            disabled
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{flex: 1}} fullWidth
            InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
            InputProps={{
              sx: {borderRadius: 3, bgcolor: "#FFF"},
              endAdornment: <InputAdornment position={"end"}><IconPhoneCall/></InputAdornment>
            }}
            label={"Phone *"}
            placeholder={"Enter your phone number"}
            id="phoneNumber"
            name="phoneNumber"
            disabled={userDetails?.phoneNumber}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <Stack direction={"column"} alignItemx={"center"} gap={1}>

            <Typography variant="caption" color="GrayText">{"What is the recipient’s email?"}</Typography>
            <TextField
              fullWidth
              InputLabelProps={{shrink: true, sx: {color: "#000", fontWeight: 700}}}
              InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
              label={"E-mail *"}
              placeholder={"recipient-mail@gmail.com"}
              id="recipient"
              name="recipientMail"
              value={formik.values.recipientMail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.recipientMail && Boolean(formik.errors.recipientMail)}
              helperText={formik.touched.recipientMail && formik.errors.recipientMail}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            type={"submit"} disabled={loading || !formik.isValid}
            endIcon={<IconChevronRight/>} className={"sign-button"}
            sx={{borderRadius: 3, py: 1}} fullWidth
          >
            Send License
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}