import {Box, Toolbar} from "@mui/material";
import {MiniHeader} from "./MiniHeader";
import {Outlet} from "react-router-dom";
import {RequestLicenseModal} from "../../components/modals/RequestLicenseModal";

export const MiniLayout = () => {

  return (
    <Box className={"mini-bgcolor"}>
      <RequestLicenseModal />
      <MiniHeader />
      <Toolbar sx={{zIndex: -1}} />
      <Outlet />
    </Box>
  )
}