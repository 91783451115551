import {StripeProductTypes} from "../../utils/enums/StripeProductTypes";
import {MintNFT} from "../../components/stripe/MintNFT";
import {MintLicense} from "../../components/stripe/MintLicense";


export const MintPage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('sessionId');
  const publicAddress = urlParams.get('publicAddress');
  const nftUid = urlParams.get('nftUid');
  const productType = urlParams.get('productType');

  return (
    <>
      {
        {
          [StripeProductTypes.NFT]: <MintNFT nftUid={nftUid} publicAddress={publicAddress} sessionId={sessionId} />,
          [StripeProductTypes.LICENSE]: <MintLicense requestUid={nftUid} publicAddress={publicAddress} sessionId={sessionId} />
        }[productType]
      }
    </>
  )
}