import {Box, TextField, Typography} from "@mui/material";
import React from "react";

export const MoreInformation = ({registerWorkForm, onUpdate}) => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 1
          }}
        >
          Title <Typography component={"span"} style={{color: "red"}}>*</Typography>
        </Typography>
        <TextField
          fullWidth
          InputLabelProps={{shrink: true}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          placeholder={"Starry Night"}
          value={registerWorkForm?.title}
          onChange={(e) => {
            onUpdate("title", e.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 1
          }}
        >
          Description <Typography component={"span"} style={{color: "red"}}>*</Typography>
        </Typography>
        <TextField
          multiline
          rows={3}
          fullWidth
          value={registerWorkForm?.description}
          onChange={(e) => {
            onUpdate("description", e.target.value);
          }}
          InputLabelProps={{shrink: true}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          placeholder={"A painting of a quiet village, embraced by a thousand glistening stars in my 'Starry Night'"}
        />
      </Box>
    </>
  )
}
