import {Box, TextField, Typography} from "@mui/material";
import React from "react";
import {UploadFile} from "./UploadFile";

export const PublicInformation = ({registerWorkForm, onUpdate}) => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 1
          }}
        >
          Public Title <Typography component={"span"} style={{color: "red"}}>*</Typography>
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.9rem",
            pb: 1
          }}
        >
          This title is publicly visible if someone clicks on your NFT in the Polygon chain and appears in your wallet.
        </Typography>
        <TextField
          fullWidth
          InputLabelProps={{shrink: true}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          placeholder={"Mona Lisa #07"}
          value={registerWorkForm?.publicTitle}
          onChange={(e) => {
            onUpdate("publicTitle", e.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 1
          }}
        >
          Public Description <Typography component={"span"} style={{color: "red"}}>*</Typography>
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "0.9rem",
            pb: 1
          }}
        >
          This description is publicly visible if someone clicks on your NFT in the Polygon chain and appears in your wallet. If you do not want a custom public description, we will write “Minted by IPRights4All”
        </Typography>
        <TextField
          multiline
          rows={3}
          fullWidth
          value={registerWorkForm?.publicDescription}
          onChange={(e) => {
            onUpdate("publicDescription", e.target.value);
          }}
          InputLabelProps={{shrink: true}}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          placeholder={"Mona Lisa #07"}
        />
      </Box>
      <UploadFile
        file={registerWorkForm?.publicImage}
        title={<>Public Image <Typography component={"span"} style={{color: "red"}}>*</Typography></>}
        subtitle={<>
          This image will be publicly visible if someone clicks on your NFT in the Polygon chain as well as appear in your wallet.
          <br/>
          If you do not want a custom public image, do not upload anything and we will use the C3 logo when minting.
        </>}
        onUpdate={(file) => onUpdate("publicImage", file)} types={["png", "jpg", "jpeg", "svg"]}
      />

    </>
  )
}