import {ProtectionType} from "../../utils/enums/ProtectionType";

export const registerWorkInitialState = {
  uid: crypto.randomUUID(),
  location: null,
  protection: ProtectionType.PROTECT_IP,
  projectTypes: [],
  projectDetails: "",
  file: null,
  originalWork: true,
  ownWork: false,
  attributions: "",
  // nonOriginalIP: [],
  title: "",
  description: "",
  publicTitle: "",
  publicDescription: "",
  publicImage: "",
  // collection: null,
  explicitContent: false,
  licenses: [], //Here we save licenses UUID
  // type: RegisterWorkType.TEMPLATE,
  // monetize: true,
  // template: null,
  // licenses: {
  //   REPRODUCING: false,
  //   DERIVATIVE_WORKS: false,
  //   DISTRIBUTING_COPIES: false,
  //   AUDIOVISUAL_WORKS: false,
  //   PERFORMING_PUBLICLY: false,
  //   DISPLAYING_PUBLICLY: false,
  //   SOUND_RECORDINGS: false,
  //   DIGITAL_AUDIO_TRANSMISSION: false,
  //   SUBLICENSING: false,
  // },
  // royaltyTemplate: null,
  // royaltyType: RoyaltyType.EXCLUSIVE,
  // price: null,
  // termsOfYears: null,
  // nonExclusiveType: NonExclusiveType.DEFAULT_PRICE,
  // royaltyPercent: null,
  // copies: null,
  // territory: null,
  // state: null,
  // terminationEvents: [],
  // limitations: null,
  publicLicense: false,
  publicNFT: false,
}
