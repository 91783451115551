import {useEffect, useState} from "react";
import ReusableModal from "../ReusableModal";
import {Button, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";


export const PhantomWalletPrompt = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasWallet = getProvider();
    if (!hasWallet) {
      setOpen(true);
    } else {
      handleClose();
    }
  }, [])

  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }
    return null;
    // window.open('https://phantom.app/', '_blank');
  };

  const handleClose = () => setOpen(false);

  return (
    <ReusableModal open={open} sx={{maxWidth: "360px", width: {xs: "80%", md: "90%"} }} >
      <Stack direction={"column"} gap={2}>
        <Typography align={"center"} fontWeight={500}>
          To document your creative work, we recommend you connect a digital wallet like <Typography component={"span"} className={"text-purple"} fontWeight={600}>Phantom Wallet's</Typography> web browser extension.
        </Typography>

        <Stack direction={"column"} gap={1}>
          <Button
            onClick={handleClose}
            sx={{
              color: grey[600],
              bgcolor: grey[100],
              "&:hover": {
                color: grey[700],
                bgcolor: grey[200]
              }
            }}
          >
            Continue without a wallet
          </Button>
          <Button
            className={"sign-button"}
            sx={{display: {xs: 'none', md: "flex"}, flex: 1 }}
            component={"a"} target={"_blank"}
            href={"https://phantom.app/"}
          >
            Download a digital wallet
          </Button>
          {/*<Button*/}
          {/*  className={"sign-button"}*/}
          {/*  sx={{display: {xs: 'flex', md: "none"}, flex: 1 }}*/}
          {/*  component={"a"} target={"_blank"}*/}
          {/*  href={`https://phantom.app/ul/browse/${window.location.href}%2Fregister-work?ref=${window.location.href}`}*/}
          {/*>*/}
          {/*  Download App*/}
          {/*</Button>*/}
        </Stack>
      </Stack>
    </ReusableModal>
  )
}