import {IconReproducing} from "../assets/icons/IconReproducing";
import {IconTermination} from "../assets/icons/IconTermination";
import {IconExclusivity} from "../assets/icons/IconExclusivity";
import {IconDuration} from "../assets/icons/IconDuration";
import {IconRoyalty} from "../assets/icons/IconRoyalty";
import {IconReuseability} from "../assets/icons/IconReuseability";
import {IconSoundRecordings} from "../assets/icons/IconSoundRecordings";
import {IconDisplayingPublicly} from "../assets/icons/IconDisplayingPublicly";
import {IconPerformingPublicly} from "../assets/icons/IconPerformingPublicly";
import {IconSublicensing} from "../assets/icons/IconSublicensing";
import {IconDistributingCopies} from "../assets/icons/IconDistributingCopies";
import {IconDerivativeWorks} from "../assets/icons/IconDerivativeWorks";
import {IconLayout} from "@tabler/icons-react";

export const licenseDescriptionData = {
  "Attribution Required": {
    name: "Attribution Required",
    falseDescription: "Other people don't have to give you credit to use your work.",
    trueDescription: "Other people must give you credit to use your work.",
    icon: IconLayout
  },
  "Copy & Publish": {
    name: "Copy & Publish",
    falseDescription: "Other people are not allowed to make copies of your work.",
    trueDescription: "Other people can make copies of your work."
  },
  "Commercial Use": {
    name: "Commercial Use",
    falseDescription: "Other people can't make money using your work.",
    trueDescription: "Other people can make money using your work.",
    icon: IconLayout
  },
  "Modify & Adapt": {
    name: "Modify & Adapt",
    falseDescription: "Other people are not allowed to make new works based on your work.",
    trueDescription: "Other people are allowed to make new works based on your work.",
    icon: IconLayout
  },
  "Change License Type": {
    name: "Change License Type",
    falseDescription: "Other people are not allowed to make third parties' licenses more restrictive than this one, whether they're for your work or other works based on your work.",
    trueDescription: "Other people are allowed to make third parties' licenses more restrictive than this one, whether they're for your work or other works based on your work.",
    icon: IconLayout
  },
  "Reproducing": {
    name: "Reproducing",
    trueDescription: "Other people are allowed to make copies of your work.",
    falseDescription: "Other people are not allowed to make copies of your work.",
    icon: IconReproducing
  },
  "Derivative Works": {
    name: "Preparing Derivative Works",
    trueDescription: "Other people are not allowed to make new works based on your work.",
    falseDescription: "Other people are allowed to make new works based on your work.",
    icon: IconDerivativeWorks
  },
  "Distributing Copies": {
    name: "Distributing Copies",
    trueDescription: "Other people are allowed to sell/give copies of your work.",
    falseDescription: "Other people are not allowed to sell/give copies of your work.",
    icon: IconDistributingCopies
  },
  "Sublicensing": {
    name: "Sublicensing",
    trueDescription: "Other people are allowed to license their license to third parties their rights and duties to third parties.",
    falseDescription: "Other people are not allowed to license their license to third parties their rights and duties to third parties.",
    icon: IconSublicensing
  },
  "Audio Performing Publicly": {
    name: "Audio Performing Publicly",
    trueDescription: "Anyone is allowed to play this work to the public via radio, Spotify, or other digital audio transmission. Public broadcasting is permitted.",
    falseDescription: "No one is allowed to play this work to the public via radio, Spotify, or other digital audio transmission. Public broadcasting is prohibited.",
    icon: IconPerformingPublicly
  },
  "Performing Publicly": {
    name: "Performing Publicly",
    trueDescription: "Other people are allowed to perform your work for the public.",
    falseDescription: "Other people are not allowed to perform your work for the public.",
    icon: IconPerformingPublicly
  },
  "Displaying Publicly": {
    name: "Displaying Publicly",
    trueDescription: "Other people are allowed to display your work to the public.",
    falseDescription: "Other people are not allowed to display your work to the public.",
    icon: IconDisplayingPublicly
  },
  "Sound Recordings": {
    name: "Performing Publicly (Only for sound recordings)",
    trueDescription: "Other people are allowed to play your work to the public via radio, Spotify, or other digital audio transmission.",
    falseDescription: "Other people are not allowed to play your work to the public via radio, Spotify, or other digital audio transmission.",
    icon: IconSoundRecordings
  },
  "Reusability": {
    name: "Reusability",
    trueDescription: "-",
    falseDescription: "Other people can only use this license once.",
    icon: IconReuseability
  },
  "Royalty Structure": {
    name: "Royalty Structure",
    trueDescription: "-",
    falseDescription: "This license costs no money.",
    icon: IconRoyalty
  },
  "Duration": {
    name: "Duration",
    trueDescription: "-",
    falseDescription: "This license will expire on [DATE]. Then, it will no longer be useable.",
    icon: IconDuration
  },
  "Exclusivity": {
    name: "Exclusivity",
    trueDescription: "-",
    falseDescription: "This license is non-exclusive. The work can be licensed as many times as desired.",
    icon: IconExclusivity
  },
  "Termination Events": {
    name: "Termination Events",
    trueDescription: "-",
    falseDescription: "These events cause the license to end and no longer be useable: [...]",
    icon: IconTermination
  },
  "Other Limitations": {
    name: "Other Limitations",
    trueDescription: "-",
    falseDescription: "These events cause the license to end and no longer be useable: [...]",
    icon: IconReproducing
  }
};
