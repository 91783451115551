import React from 'react';
import {Modal, Box, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "sm", maxWidth: "md", width: {xs: "90%", sm: "90%", md: "60%", lg: "40%"},
  maxHeight: {xs: "100dvh", md: "80dvh"}, height: {xs: "95%", sm: "auto"}, overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 10,
  borderRadius: 5,
  p: 4,
};

const ReusableModal = ({open, handleClose, children, ...props}) => {
  return (
    <Modal open={open} onClose={handleClose} sx={{backdropFilter: "blur(4px)"}}>
      <Box sx={props?.sx ? {...modalStyle, ...props.sx} : modalStyle}>
        {handleClose && <IconButton onClick={handleClose} sx={{position: "absolute", top: 15, right: 15, zIndex: 100000000}}>
          <CloseIcon/>
        </IconButton>}
        {children}
      </Box>
    </Modal>
  );
};

export default ReusableModal;