import {Box, Button, Stack, Typography} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {IconAlertTriangle, IconConfetti, IconFileUpload} from "@tabler/icons-react";
import React, {useEffect, useState} from "react";
import {AlertBanner} from "../../../components/banner/AlertBanner";
import AnimationSuccess from "../../../components/lottie-animations/AnimationSuccess";
import {useSetAtom} from "jotai/index";
import {ContactFormAtom} from "../../../atoms";

const fileTypes = [
  "doc", "docx", "pdf", "rtf", "txt", "md",
  "pps", "ppt", "pptx",
  "bmp", "gif", "giff", "jpg", "jpeg", "jfif", "pic", "pict", "png", "psd", "pub", "tga", "tif", "tiff",
  "aif", "aiff", "au", "mp3", "wav", "wma", "mp4", "m4a", "m4p",
  "avi", "mov", "mpg", "mpeg", "swf", "wmv", "m4v",
  "xls", "xlsx", "csv"
];

export const UploadFile = ({
                             file,
                             onUpdate,
                             title = "Upload file",
                             subtitle = "Choose a file or drag it here",
                             types = fileTypes
                           }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const setOpenContactModal = useSetAtom(ContactFormAtom);

  const handleContactOpen = () => {
    setOpenContactModal({
      open: true,
      message: "I want to upload larger file than 100mb",
      title: "File upload problem"
    })
  }

  useEffect(() => {
    let timeout = null;
    if (file) {
      setIsUploaded(true);
      timeout = setTimeout(() => {
        setIsUploaded(false);
      }, 3000)
    } else {
      setIsUploaded(false)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setIsUploaded(false);
      }
    }
  }, [file])


  return (
    <Box>
      {
        isUploaded &&
        <AnimationSuccess open={isUploaded}/>
      }
      {
        file ?
          <AlertBanner
            icon={<IconConfetti color={"#7758D7"} size={50}/>}
            color={"#7758D7"}
            text={<>
              <Typography className={"alert-text"}>
                Thank you for uploading "<Typography component={"b"} fontWeight={700}
                                                     style={{fontStyle: "italic"}}>{file?.name}</Typography>"!!
                <Button variant={"text"} onClick={() => onUpdate(null)} className={"text-grey"}
                        sx={{display: "block", textDecoration: "underline"}}>Click here to upload a different
                  file.</Button>
              </Typography>
            </>}
          /> :
          <>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1rem",
                color: "#2D2D30",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "0.85rem",
                color: "#898989",
              }}
            >
              {subtitle}
            </Typography>
            <FileUploader
              multiple={false}
              handleChange={(file) => {
                try {
                  if (file?.size) {
                    const size = (file?.size / 1024)
                    if (size <= 100000) {
                      onUpdate(file);
                      setErrorFile(false);
                    } else {
                      setErrorFile(true);
                    }
                  }
                } catch (e) {
                  console.log(e);
                  setErrorFile(true);
                }
              }}
              name="file"
              types={types}
            >
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                className={"drag-n-drop-file"}
                sx={{
                  mt: 1,
                  borderStyle: "dashed",
                  p: 2,
                  height: "17rem",
                  textAlign: "center",
                  cursor: "pointer"
                }}
              >
                <IconFileUpload size={40} color={"rgba(141, 87, 195, 1)"}/>
                <Typography fontWeight={"bold"} color={"rgba(141, 87, 195, 1)"} textTransform={"uppercase"}
                            fontSize={18}>
                  Choose file or drop it here.
                </Typography>
                <Typography color={"rgba(141, 87, 195, 1)"} textTransform={"lowercase"} fontSize={15}>TXT, DOCX, PDF,
                  WAV, MP$, JPEG, SVG, and more</Typography>
                <Typography color={"rgba(141, 87, 195, 1)"} fontSize={15}>Max size: 100MB.</Typography>
              </Stack>
            </FileUploader>
            {
              errorFile &&
              <AlertBanner
                icon={<IconAlertTriangle color={"#d93f74"} size={50}/>}
                color={"#D55380"}
                text={(
                  <Typography className={"alert-text"}>
                    There was an issue uploading your file. Please try again with a file under 100MB.<br/>
                    If you must upload larger file, please <Typography component={"span"} className={"alert-text"} onClick={handleContactOpen}>contact us</Typography>
                  </Typography>
                )}
              />
            }
          </>
      }
    </Box>
  )
}
