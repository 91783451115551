import React from "react";
import {Button, Stack, Toolbar, Typography} from "@mui/material";
import logo from "../../assets/img/logo-img.png";
import {Link} from "react-router-dom";
import {Logo} from "../main/header/logo";

export const MiniHeader = () => {
    return (
        <Toolbar
            sx={{
                position: "fixed",
                top: 0, left: 0, right: 0,
                bgcolor: "transparent"
            }}
        >
            <Stack direction={"row"} sx={{width: "100%"}} gap={1} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Button
                    component={Link}
                    to={"/"}
                    variant={"text"}
                    sx={{color: "#000", borderRadius: 0, zIndex: 10000000}}
                  >
                    <Logo />
                    <Typography sx={{ml: 1, fontSize: "1.2rem", fontWeight: 600}} textTransform={"capitalize"} fontWeight={"bold"}>
                      IPRights4All
                    </Typography>
                  </Button>
                </Stack>
            </Stack>
        </Toolbar>
    )
}
