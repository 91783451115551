import {storage} from "../config/firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";

export const uploadFile = async ({img, path}) => {
  if (!img || typeof img !== "object") {
    return img || null;
  }
  const fileFolderRef = ref(storage, path);
  const imgRef = await uploadBytes(fileFolderRef, img);
  return await getDownloadURL(imgRef.ref);
}