import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
const useWalletConfirm = (customTitle, customMessage) => {
  const [promise, setPromise] = useState(null);
  const [title, setTitle] = useState("Are you sure you want to delete this?");
  const [message, setMessage] = useState("You will not be able to recover it?");

  useEffect(() => {
    setTitle((prev) => customTitle || prev);
    setMessage((prev) => customMessage || prev);
  }, [customTitle, customMessage]);

  const confirm = (customTitle = null, customMessage = null) => {
    setTitle((prev) => customTitle || prev);
    setMessage((prev) => customMessage || prev);

    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject });
    });
  };

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // You could replace the Dialog with your library's version
  const ConfirmationDialog = () => (
    <Dialog
      open={promise !== null}
      PaperProps={{
        sx: { pt: 5, px: 5, pb: 8, borderRadius: 3 },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mt: 3, flexDirection: {xs: "column", sm: "row"} }}>
        <Button
          startIcon={<ArrowBackIosNewIcon />}
          variant={"contained"}
          color={"secondary"}
          sx={{ borderRadius: 2, textAlign: "left", width: "35%", py: 2 }}
          onClick={handleCancel}
        >
          Nevermind.
          <br /> I'll connect a wallet
        </Button>
        <Button
          endIcon={<ArrowForwardIosIcon />}
          variant={"contained"}
          color={"primary"}
          className={"sign-button-vertical"}
          sx={{
            borderRadius: 2,
            textAlign: "left",
            width: "65%",
            py: 2,
          }}
          onClick={handleConfirm}
        >
          I don't want to connect a wallet now.
          <br /> Mint and hold the NFT for me.
        </Button>
      </DialogActions>
    </Dialog>
  );
  return [ConfirmationDialog, confirm];
};

export default useWalletConfirm;
