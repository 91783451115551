import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import Card from "../Card";
import {useWeb3React} from "@web3-react/core";

export const MintBanner = ({cost, title, description, setAccount}) => {
  const {connector, hooks} = useWeb3React()
  return (
    <Box maxWidth={"sm"} sx={{mt: 2}}>
      {
        cost || description ?
          <>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1rem",
                color: "#2D2D30",
                pb: 1
              }}
            >
              Cost to mint
            </Typography>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography color={"#D55380"} fontSize={"2rem"} fontWeight={700}>{cost}</Typography>
              <Stack direction={"column"}>
                {/*<Typography color={"#2D2D30"} fontSize={"0.9rem"} fontWeight={700}>{title}</Typography>*/}
                <Typography color={"#949494"} fontSize={"0.7rem"} fontWeight={500}>{description}</Typography>
              </Stack>
            </Stack>

          </> : null
      }

      <Card name={'Connect Phantom wallet'}
            connector={connector}
            hooks={hooks}
            setAccount={setAccount}
      />
    </Box>
  )
}
