import {LicenseRequestViewAtom} from "../../atoms";
import {useAtomValue} from "jotai";
import {LicenseRequestType} from "../../utils/enums/LicenseRequestType";
import {LicenseRequestDeclined} from "./LicenseRequestDeclined";
import {ViewLicenseRequest} from "./ViewLicenseRequest";
import {LicenseRequestAccepted} from "./LicenseRequestAccepted";


export const LicenseRequest = () => {
  const licenseRequest = useAtomValue(LicenseRequestViewAtom);

  return (
    <>
      {
        {
          [LicenseRequestType.PENDING]: <ViewLicenseRequest/>,
          [LicenseRequestType.ACCEPTED]: <LicenseRequestAccepted/>,
          [LicenseRequestType.DECLINED]: <LicenseRequestDeclined/>,
        }[licenseRequest.state]
      }
    </>
  )
}