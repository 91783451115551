import {useAtom} from "jotai";
import {WalletInstructionsAtom} from "../../atoms";
import Modal from "@mui/material/Modal";
import {IconButton, Stack, Typography} from "@mui/material";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import phantomImg1 from "../../assets/img/phantom-wallet-1.png";
import phantomImg2 from "../../assets/img/phantom-wallet-2.png";
import {grey} from "@mui/material/colors";

// Import Swiper styles
import 'swiper/css/navigation';
// import required modules
import { Navigation } from 'swiper/modules';
import {IconX} from "@tabler/icons-react";

const style = {
  position: 'relative',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "40vw", maxWidth: {xs: "85vw", md: "70vw", lg: "60vw"},
  maxHeight: "80dvh", minHeight: {xs: "70dvh", md: "70dvh", lg: "60dvh"},
  bgcolor: 'background.paper',
  borderRadius: 5,
  border: "0 !important",
  boxShadow: 24, p: {xs: 2, md: 5}
};

export const WalletInstructionsModal = () => {
  const [open, setOpen] = useAtom(WalletInstructionsAtom);

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack direction={"column"} gap={3} alignItems={"center"} justifyContent={"center"} sx={style}>
        <IconButton sx={{position: "absolute", top: 15, right: 15}} onClick={handleClose} size={"large"} ><IconX /></IconButton>
        <Typography variant={"h5"} fontWeight={600} align={"center"}>Instructions to view work in your digital wallet</Typography>
        <Typography fontWeight={400} fontSize={14} align={"center"}>Open your digital wallet extension in the top right corner of your browser.</Typography>
        <Swiper
          style={{maxWidth: "100%", marginTop: "20px"}}
          spaceBetween={50}
          slidesPerView={1}
          navigation loop
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide style={{textAlign: "center", height: "100%"}}>
            <img
              src={phantomImg1} alt={"Phantom 01"}
              width={"75%"} height={"auto"}
              style={{marginTop: "auto"}}
            />
            <Typography color={grey[500]} fontWeight={600} fontSize={14} sx={{mt: "auto"}}>Open your digital wallet extension in the top right corner of your browser.</Typography>
          </SwiperSlide>
          <SwiperSlide style={{textAlign: "center", height: "100%"}}>
            <img
              src={phantomImg2} alt={"Phantom 02"}
              width={"75%"} height={"auto"}
              style={{marginTop: "auto"}}
            />
            <Typography color={grey[500]} fontWeight={600} fontSize={14} sx={{mt: "auto"}}>Click the four squares icon to see your collections.</Typography>
          </SwiperSlide>
        </Swiper>
      </Stack>
    </Modal>
  )
}