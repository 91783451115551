import {IconHome, IconPhoto, IconSchool, IconScriptPlus, IconTemplate, IconWritingSign} from "@tabler/icons-react";
import {Divider, List, useMediaQuery} from "@mui/material";
import React, {useCallback} from "react";
import {SettingsItem} from "./SettingsItem";
import {SidebarItem} from "./SideBarItem";

export const SidebarItems = ({handleClose, open}) => {

  const items = [
    {icon: <IconHome/>, title: "Discovery", path: "/discovery"},
    {icon: <IconScriptPlus/>, title: "Register a new work", path: "/register-work"},
    {icon: <IconPhoto/>, title: "My portfolio", path: "/portfolio"},
    {icon: <IconSchool/>, title: "Learn about copyright with Tilly", path: "/learn-with-tilly"},
    {icon: <IconWritingSign/>, title: "Read our blog", path: "/blog"},
  ]

  return (
    <>
      <List sx={{minWidth: 24}}>
        {items?.map(item => <SidebarItem key={item?.path} icon={item?.icon} title={item?.title} path={item?.path} handleClose={handleClose} />)}
      </List>
      <Divider sx={{mt: "auto"}}/>
      <List sx={{pb: 2, minWidth: 24}}>
        <SettingsItem handleClose={handleClose}/>
      </List>
    </>
  )
}
