import React, {useContext, useState} from "react";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow, Typography
} from "@mui/material";
import {
  IconAppWindow,
  IconArchive, IconArchiveOff,
  IconChevronDown,
  IconChevronRight,
  IconCopyright, IconCreditCard,
  IconDotsVertical, IconEye,
  IconFile, IconLicense, IconQrcode, IconSignature, IconTransfer, IconTrash
} from "@tabler/icons-react";
import {PrintProofModal} from "./print/PrintProofModal";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {ShareModal} from "../../../components/share/ShareModal";
import {ViewLicenseModal} from "./ViewLicenseModal";
import {grey} from "@mui/material/colors";
import {toast} from "react-toastify";
import {TransferWorkModal} from "./nft/TransferWorkModal";
import useConfirm from "../../../hooks/useConfirm";
import {deleteDoc, doc} from "firebase/firestore";
import {db} from "../../../config/firebase";
import {AppContext} from "../../../context/AppContext";
import {useSetAtom} from "jotai";
import {ContactFormAtom} from "../../../atoms";

export const TemplateRow = ({nftData, onMint, handleViewDocument, handleArchive, handleDelete}) => {
  const [open, setOpen] = useState(false);
  const [transferWork, setTransferWork] = useState(false);
  const [shareLicense, setShareLicense] = useState(null);
  const [viewLicense, setViewLicense] = useState(null);

  return (
    <>
      <ShareModal
        url={shareLicense ? `${window.location.origin}/license/${shareLicense?.uid}` : ""}
        handleClose={() => setShareLicense(null)}
        open={!!shareLicense}
      />
      <ViewLicenseModal
        open={!!viewLicense} handleClose={() => setViewLicense(null)}
        nftUid={nftData?.uid} licenseUid={viewLicense?.uid}
      />
      <TransferWorkModal
        open={transferWork}
        handleClose={() => setTransferWork(false)}
        nft={nftData}
      />
      <TableRow>
        <TableCell className={"text-grey"}>
          <IconButton disabled={nftData?.licenses?.length === 0}
                      onClick={() => setOpen(!open)}>
            {open ? <IconChevronDown/> : <IconChevronRight/>}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <img src={nftData?.publicImage} width={50} height={"auto"} alt={nftData?.name}/>
            <Typography component={"span"}>{nftData?.title}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{display: {xs: "none", sm: "table-cell"} }}>{nftData?.projectTypes?.join(", ") || "N/A"}</TableCell>
        <TableCell sx={{display: {xs: "none", sm: "table-cell"} }}>{moment(nftData?.createdAt).format("DD/MM/YYYY")}</TableCell>
        <TableCell>
          <Stack direction={"row"} justifyContent={"flex-end"} gap={1}>
            {/*<Button*/}
            {/*  disabled={!nftData?.nftTxnHash}*/}
            {/*  component={"a"} href={"https://polygonscan.com/tx/" + nftData?.nftTxnHash} target={"_blank"}*/}
            {/*  color={"primary"} variant={"text"}*/}
            {/*  // startIcon={<IconEye />}*/}
            {/*  sx={{display: {xs: "none", sm: "flex"}, px: 2, borderRadius: 5, whiteSpace: 'nowrap'}}*/}
            {/*>*/}
            {/*  {nftData?.nftTxnHash ? "View on polygonscan.com" : "Not Minted"}*/}
            {/*</Button>*/}
            <Button className={"sign-button"} onClick={() => handleViewDocument(nftData)}
                    startIcon={<IconAppWindow size={20}/>}
                    sx={{display: {xs: "none", sm: "flex"}, minWidth: "200px", borderRadius: 5, px: 2, whiteSpace: 'nowrap'}}>
              Explore Work
            </Button>

            {
              !nftData?.publicWalletAddress ?
                <Button variant={"outlined"} color={"warning"}
                        startIcon={<IconTransfer size={20}/>}
                        onClick={() => setTransferWork(true)}
                        sx={{display: {xs: "none", sm: "flex"}, minWidth: "200px", borderRadius: 5, px: 2, whiteSpace: 'nowrap'}}>
                  Transfer Work
                </Button> :
                nftData?.paid ?
                  <Button
                    variant={"outlined"} color={"secondary"}
                    component={Link} to={`/nft/${nftData?.uid}/license`}
                    sx={{display: {xs: "none", sm: "flex"}, minWidth: "200px", borderRadius: 5, px: 2, whiteSpace: 'nowrap'}} startIcon={<IconLicense/>}
                    disabled={!nftData?.nftTxnHash}
                  >
                    Create license
                  </Button>
                  :
                  <Button
                    variant={"outlined"}
                    component={Link} to={`/register-work/${nftData?.uid}`}
                    sx={{display: {xs: "none", sm: "flex"}, minWidth: "200px", borderRadius: 5, px: 2, whiteSpace: 'nowrap'}}
                    startIcon={<IconCreditCard size={20}/>}
                  >
                    Mint Work
                  </Button>
            }
            <MintSettingsMenu nft={nftData} handleViewDocument={handleViewDocument} setTransferWork={setTransferWork} />
          </Stack>
        </TableCell>
      </TableRow>
      {open &&
        <>
          {nftData?.licenses?.map(license => {

            return (
              <TableRow key={license?.uid}>
                <TableCell align={"center"} sx={{pr: 4}}>
                  {
                    license?.archive ?
                      <IconArchive color={grey[400]}/> :
                      license?.signees?.length ? <IconSignature/> : null
                  }
                </TableCell>
                <TableCell sx={{color: license?.archive ? `${grey[400]} !important` : "inherit"}}>{license?.name}</TableCell>
                <TableCell sx={{display: {xs: "none", sm: "table-cell"}, color: license?.archive ? `${grey[400]} !important` : "inherit"}}>License</TableCell>
                <TableCell
                  sx={{display: {xs: "none", sm: "table-cell"}, color: license?.archive ? `${grey[400]} !important` : "inherit"}}>{moment(license?.createdAt).format("DD/MM/YYYY")}</TableCell>
                <TableCell>
                  <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
                    <Button
                      className={"sign-button"}
                      sx={{
                        px: 2,
                        borderRadius: 5,
                        filter: `grayscale(${license?.archive ? 0.5 : 0}) !important`,
                        display: {xs: "none", sm: "flex"}, minWidth: "200px",
                        whiteSpace: 'nowrap'
                      }}
                      startIcon={<IconAppWindow size={20}/>}
                      onClick={() => setViewLicense(license)}
                    >
                      View License
                    </Button>
                    <Button
                      variant={"outlined"} color={"warning"} disabled={!!license?.archive}
                      onClick={() => setShareLicense(license)}
                      startIcon={<IconQrcode size={20}/>}
                      sx={{
                        px: 2,
                        borderRadius: 5,
                        filter: `grayscale(${license?.archive ? 0.5 : 0}) !important`,
                        display: {xs: "none", sm: "flex"}, minWidth: "200px",
                        whiteSpace: 'nowrap'
                      }}>
                      Share License
                    </Button>
                    <LicenseSettingsMenu license={license} nft={nftData} handleArchive={handleArchive}
                                         handleDelete={handleDelete} setShareLicense={setShareLicense} setViewLicense={setViewLicense}/>
                  </Stack>
                </TableCell>
              </TableRow>
            )
          })}

        </>
      }
    </>
  )
}


const MintSettingsMenu = ({nft, handleViewDocument, setTransferWork}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [DeleteDialog, confirmDelete] = useConfirm("Are you sure you want to delete this work?", "Once a work is deleted, it cannot be recovered.")
  const {state: {royaltyTemplates}, dispatch} = useContext(AppContext);
  const setOpenContactModal = useSetAtom(ContactFormAtom);

  const handleContactOpen = () => {
    setOpenContactModal({
      open: true,
      message: `I'd like to register ${nft.title} with the US Copyright Office`,
      title: "Register with the US Copyright Office"
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const generatePdf = () => {
    setOpenPrintModal(true);
    handleClose();
  }

  const handleDelete = async () => {
    const response = await confirmDelete();
    if (response) {
      try {
        if (nft?.nftTxnHash || nft?.licenses?.length) {
          toast.info("Nft work cant be deleted once is minted or signed!");
          return;
        }
        const nftRef = doc(db, 'royalty_templates', nft?.uid)
        await deleteDoc(nftRef);
        toast.success("Nft work is deleted.");
        const items = royaltyTemplates?.filter(item => item?.uid !== nft?.uid);
        dispatch({type: "update_royalty_templates", payload: items});
        handleClose();
      } catch (e) {
        toast.error("Failed to delete work")
      }
    }
  }

  return (
    <div>
      <DeleteDialog/>
      <PrintProofModal
        open={openPrintModal} handleClose={() => setOpenPrintModal(false)}
        nft={nft}
      />
      <IconButton
        id="mint-settings-button"
        aria-controls={open ? 'mint-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IconDotsVertical/>
      </IconButton>
      <Menu
        id="mint-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'mint-settings-button',
        }}
        slotProps={{
          paper: {sx: {borderRadius: 3}}
        }}
      >
        <MenuItem onClick={handleContactOpen} sx={{p: 2}}>
          <ListItemIcon><IconCopyright/></ListItemIcon>
          <ListItemText primary={"Register with the US Copyright Office"}/>
        </MenuItem>
        <MenuItem onClick={generatePdf} sx={{p: 2}}>
          <ListItemIcon><IconFile/></ListItemIcon>
          <ListItemText primary={"Download proof of mint"}/>
        </MenuItem>
        <MenuItem
          disabled={!nft?.nftTxnHash}
          component={"a"} href={"https://polygonscan.com/tx/" + nft?.nftTxnHash} target={"_blank"}
          sx={{display: {xs: "flex", sm: "none"} }}
        >
          <ListItemIcon><IconEye/></ListItemIcon>
          <ListItemText primary={nft?.nftTxnHash ? "View on polygonscan.com" : "Not Minted"}/>
        </MenuItem>
        <MenuItem
          onClick={() => handleViewDocument(nft)}
          sx={{display: {xs: "flex", sm: "none"} }}
        >
          <ListItemIcon><IconAppWindow/></ListItemIcon>
          <ListItemText primary={"Explore Work"}/>
        </MenuItem>
        <MenuItem
          onClick={() => setTransferWork(true)}
          sx={{display: {xs: !nft?.publicWalletAddress ? "flex" : "none", sm: "none"} }}
        >
          <ListItemIcon><IconTransfer/></ListItemIcon>
          <ListItemText primary={"Transfer Work"}/>
        </MenuItem>
        <MenuItem
          component={Link} to={`/nft/${nft?.uid}/license`}
          sx={{display: {xs: nft?.publicWalletAddress && nft?.paid ? "flex" : "none", sm: "none"} }}
        >
          <ListItemIcon><IconLicense/></ListItemIcon>
          <ListItemText primary={"Create license"}/>
        </MenuItem>
        <MenuItem
          component={Link} to={`/register-work/${nft?.uid}`}
          sx={{display: {xs: nft?.publicWalletAddress && !nft?.paid ? "flex" : "none", sm: "none"} }}
        >
          <ListItemIcon><IconCreditCard/></ListItemIcon>
          <ListItemText primary={"Mint Work"}/>
        </MenuItem>

        <MenuItem onClick={handleDelete} disabled={nft?.licenses?.length > 0 || !nft?.nftTxnHash} sx={{p: 2}}>
          <ListItemIcon><IconTrash/></ListItemIcon>
          <ListItemText primary={"Delete Work"}/>
        </MenuItem>
      </Menu>
    </div>
  );
}

const LicenseSettingsMenu = ({nft, license, handleArchive, handleDelete, setShareLicense, setViewLicense}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="mint-settings-button"
        aria-controls={open ? 'mint-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IconDotsVertical/>
      </IconButton>
      <Menu
        id="mint-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'mint-settings-button',
        }}
        slotProps={{
          paper: {sx: {borderRadius: 3, minWidth: "15rem"}}
        }}
      >
        <MenuItem onClick={() => handleArchive(license, nft)} sx={{p: 2}}>
          <ListItemIcon>{license?.archive ? <IconArchiveOff/> : <IconArchive/>}</ListItemIcon>
          <ListItemText primary={license?.archive ? "Unarchive License" : "Archive License"}/>
        </MenuItem>
        <MenuItem
          onClick={() => setViewLicense(license)}
          sx={{display: {xs: "flex", sm: "none"} }}
        >
          <ListItemIcon><IconAppWindow/></ListItemIcon>
          <ListItemText primary={"View License"}/>
        </MenuItem>
        <MenuItem
          onClick={() => setShareLicense(true)}
          sx={{display: {xs: "flex", sm: "none"} }}
        >
          <ListItemIcon><IconQrcode/></ListItemIcon>
          <ListItemText primary={"Share License"}/>
        </MenuItem>
        <MenuItem onClick={() => handleDelete(license, nft)} sx={{p: 2}}>
          <ListItemIcon><IconTrash/></ListItemIcon>
          <ListItemText primary={"Delete License"}/>
        </MenuItem>
      </Menu>
    </div>
  );
}
