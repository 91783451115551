import {Box, MenuItem, Select, TextField, Typography} from "@mui/material";
import React from "react";

export const TerminationEvents = ({license, onUpdate}) => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 1
          }}
        >
          Events that terminate the license
        </Typography>
        <Select
          value={license?.terminationEvents || []}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            onUpdate(
              "terminationEvents",
              typeof value === 'string' ? value.split(',') : value
            );
          }}
          multiple
          placeholder={"Select all events"}
          sx={{borderRadius: "0.3125rem", flexShrink: 0, width: "100%"}}
          MenuProps={{
            sx: {
              borderRadius: "0.3125rem",
            }
          }}
        >
          <MenuItem value={"Breach of Contract"}>Breach of Contract</MenuItem>
          <MenuItem value={"Non use or Abandonment"}>Non use or Abandonment</MenuItem>
          <MenuItem value={"Mutual Agreement"}>Mutual Agreement</MenuItem>
          <MenuItem value={"IP Infringement"}>IP Infringement</MenuItem>
        </Select>
      </Box>
    </>
  )
}