import React from "react";
import {Autocomplete, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {countryData} from "../../../utils/countryData";
import {statesData} from "../../../utils/statesData";

export const CountryInfo = ({
                              isRequired = false,           //added isRequired prop to put a red '*' next to the primary title
                              selectedCountry, updateCountry,
                              selectedState, updateState,
                              showState = false,
                              primaryTitle = "Territory",
                              secondaryTitle = "Geography Selections for Copyright Enforcement"
                            }) => {

  const sortedCountries = Object.values(countryData).sort((a, b) => a.name.localeCompare(b.name));
  const sortedStates = Object.values(statesData).sort((a, b) => a.state.localeCompare(b.state));

  return (
    <Stack direction={"column"} gap={1}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "1rem",
          color: "#2D2D30",
          pb: 1
        }}
      >
        {primaryTitle} {isRequired && <Typography component={"span"} style={{color: "red"}}>*</Typography>}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "0.7rem",
          color: "#2D2D30",
          display: secondaryTitle ? "block" : "none"
        }}
      >
        {secondaryTitle}
      </Typography>

      <Autocomplete
        options={sortedCountries.map(option => option.name)}
        value={selectedCountry} clearOnBlur={false} blurOnSelect
        onChange={(event, newValue) => updateCountry(newValue || null)}
        renderInput={(params) => <TextField {...params} placeholder={"Select your country"} sx={{flexShrink: 0, width: "100%", borderRadius: 4}} />}
      />
      {
        showState && selectedCountry === countryData.US.name &&
        <Select
          sx={{borderRadius: "0.3125rem", flexShrink: 0, width: "100%"}}
          MenuProps={{
            sx: {
              borderRadius: "0.3125rem",
            }
          }}
          value={selectedState || "none"}
          onChange={(e) => {
            const value = e.target.value === "none" ? null : e.target.value;
            updateState(value);
          }}
        >
          <MenuItem value={"none"}>Select state</MenuItem>
          {
            sortedStates?.map(item => (
              <MenuItem key={item?.state} value={item?.state}>{item?.state}</MenuItem>
            ))
          }
        </Select>
      }
    </Stack>
  )
}