import {Button, Box, Stack, Typography} from "@mui/material";
import {useAtom} from "jotai";
import {LicenseRequestViewAtom} from "../../atoms";
import React from "react";
import AnimationSuccess from "../lottie-animations/AnimationSuccess";
import {Link} from "react-router-dom";
import {IconChevronRight} from "@tabler/icons-react";

export const LicenseRequestPurchased = () => {
  const [licenseRequest, setLicenseRequest] = useAtom(LicenseRequestViewAtom);

  const from = licenseRequest?.firstName || licenseRequest?.lastName ? `${licenseRequest?.firstName} ${licenseRequest?.lastName}`?.trim() : licenseRequest?.email;

  return (
    <Stack direction={"column"} gap={2}>
      <Typography variant={"h2"} fontWeight={700} align={"center"} gutterBottom>License purchased!</Typography>
      <AnimationSuccess/>
      <Typography fontWeight={400} maxWidth={"sm"} sx={{mx: "auto"}} className={"text-grey"} align={"center"}>
        You have purchased license from {from}
      </Typography>
      <Box sx={{textAlign: "center"}}>
        <Button component={Link} to={"/portfolio"}
                className={"sign-button"} sx={{px: 3, borderRadius: 3}}
                endIcon={<IconChevronRight/>}
                onClick={() => setLicenseRequest(null)}
        >
          View your license in you portfolio
        </Button>
      </Box>
    </Stack>
  )
}