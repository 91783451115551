import {
  Badge,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {
  IconAxe,
  IconBell,
  IconBellFilled, IconBrandMastercard,
  IconChecks,
  IconCircleX,
  IconMailFast, IconX
} from "@tabler/icons-react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {collection, onSnapshot, query, orderBy, limit, where} from "firebase/firestore";
import {db} from "../../../config/firebase";
import {AppContext} from "../../../context/AppContext";
import {LicenseRequestType} from "../../../utils/enums/LicenseRequestType";
import {useSetAtom} from "jotai";
import {LicenseRequestViewAtom} from "../../../atoms";


export const NotificationsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [notifications, setNotifications] = useState([])
  const {state: {userDetails}} = useContext(AppContext)
  const setLicenseRequestModal = useSetAtom(LicenseRequestViewAtom)

  useEffect(() => {
    const fetchNotifications = () => {
      let unsubscribe = null;
      try {
        console.log(userDetails);
        const notificationsQuery = query(
          collection(db, "license_requests"),
          where("recipientMail", "==", userDetails?.email),
          orderBy("createdAt", "desc"),
          limit(6)
        );
        unsubscribe = onSnapshot(notificationsQuery, (querySnapshot) => {
          let notifications = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            notifications.push(data);
          });
          setNotifications(notifications);
        });
      } catch (error) {
        console.log(error);
      }
      return unsubscribe;
    }
    if (userDetails && userDetails?.email) {
      const unsubscribe = fetchNotifications();
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [userDetails])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const notReadedNotifications = useMemo(() => {
    return notifications?.filter(i => i.state === LicenseRequestType.PENDING)
  }, [notifications])

  return (
    <>
      <IconButton
        ref={anchorEl}
        onClick={handleClick}
        className={notReadedNotifications.length ? "text-purple" : ""}
      >
        <Badge badgeContent={notReadedNotifications.length}
               className={notReadedNotifications?.length ? "text-purple" : ""}>
          {notReadedNotifications.length ? <IconBellFilled/> : <IconBell/>}
        </Badge>
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {borderRadius: 3, minWidth: 260, maxWidth: 320, p: 1.5}
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {p: 0}
        }}
      >
        <Typography fontWeight={600} fontSize={"1rem"} sx={{pb: 1}}>Notifications</Typography>

        {
          notifications?.length === 0 ? <Typography>There is no notifications for you</Typography> :
            notifications?.map(notification => {
                const message = {
                  [LicenseRequestType.PENDING]: `${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} has sent you license.`,
                  [LicenseRequestType.ACCEPTED]: `You have accepted license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                  [LicenseRequestType.DECLINED]: `You have declined license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                  [LicenseRequestType.PAID]: `The payment was successfully for license ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                  [LicenseRequestType.MINTED]: `Your license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} is minted.`,
                  [LicenseRequestType.FAILED]: `Your license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} failed in some proccess please contact us.`,
                }[notification.state];
                const icon = {
                  [LicenseRequestType.PENDING]: <IconMailFast className={"text-purple"}/>,
                  [LicenseRequestType.ACCEPTED]: <IconChecks color={"#11ff11"}/>,
                  [LicenseRequestType.DECLINED]: <IconX color={"#ff1111"}/>,
                  [LicenseRequestType.PAID]: <IconBrandMastercard color={"#3c0cd3"}/>,
                  [LicenseRequestType.MINTED]: <IconAxe color={"#3c0cd3"}/>,
                  [LicenseRequestType.FAILED]: <IconCircleX color={"#ff1111"}/>,
                }[notification.state];


                return (
                  <MenuItem key={notification?.uid} component={Link} to={`/notifications/${notification?.uid}`} onClick={handleClose}
                            sx={{borderRadius: 2}}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      primary={message}
                      secondary={moment(notification.createdAt).format("DD MMM YYYY")}
                      primaryTypographyProps={{
                        sx: {fontWeight: 500},
                        noWrap: true
                      }}
                      secondaryTypographyProps={{
                        sx: {fontSize: "0.8rem"}
                      }}
                    />
                  </MenuItem>
                )
              })}
        {
          notifications?.length > 0 &&
              <Button component={Link} to={"/notifications"} onClick={handleClose} fullWidth sx={{borderRadius: 2, mt: 1}}>
                See all notifications
              </Button>
        }
      </Menu>
    </>
  )
}