import Box from "@mui/material/Box";
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import React from "react";
import {IconHeartBroken, IconLayout} from "@tabler/icons-react";
import moment from "moment";
import {CreativeCommonLicenseTemplates} from "../../../../utils/CreativeCommonLicenseTemplates";
import {licenseDescriptionData} from "../../../../utils/licenseDescriptionData";


function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const CardView = ({nft, license, hideTitle=false}) => {
  console.log(nft, license)
  let attributions = [];
  if (license?.template) {
    attributions = CreativeCommonLicenseTemplates[license?.template]?.attributions;
  } else {
    attributions = Object.keys(license?.licenses)?.map(key => ({attribution: key, value: license?.licenses[key]}))
  }

  console.log(attributions);
  return (
    nft &&
    <Stack direction={"column"} gap={1} sx={{flex: 1, overflowY: "auto", p: 1, textAlign: "center"}}>
      {
        !hideTitle &&
        <>
          <Typography variant={"h2"} fontWeight={500}>{nft?.title}</Typography>
          <Typography variant={"subtitle2"} sx={{color: grey[700], fontSize: "1rem"}}>{moment(nft?.createdAt || new Date()).format("DD/MM/YYYY")}</Typography>
        </>
      }
     <Grid container spacing={2}>
        {
          attributions?.filter(({attribution}) => attribution !== "Audiovisual Works" && attribution !== "Digital Audio Transmission")
          .map(({attribution, value}) => {
            const title = toTitleCase(attribution?.replaceAll("_", " ")?.toLowerCase())
            const {trueDescription, falseDescription, icon} = licenseDescriptionData[title] || {trueDescription: "", falseDescription: "", icon: IconLayout};
            const description = value ? trueDescription : falseDescription;
            const Icon = icon || IconLayout;
            return (
              <Grid key={attribution} item xs={12} sm={12} md={6} lg={4}>
                <Card direction={"column"} className={"border-shadow"} sx={{borderRadius: 3, height: "100%", minHeight: "18vh"}}>
                  <Box sx={{borderTopLeftRadius: "inherit", borderTopRightRadius: "inherit", height: 15, bgcolor: value ? "#1DB158" : "#E45271"}}/>
                  <CardContent sx={{p: 1.5}}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                      <Icon fontSize={50} color={value ? "#1DB158" : "#E45271"} />
                      <Typography variant={"h4"} color={"#424242"} fontWeight={700} textTransform={"capitalize"}>{title}</Typography>
                    </Stack>
                    <Typography variant={"subtitle2"} fontWeight={400} fontSize={"0.8rem"} sx={{color: grey[500], textAlign: "left", pt: 1}}>
                      {description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card direction={"column"} className={"border-shadow"} sx={{borderRadius: 3, height: "100%", minHeight: "18vh"}}>
            <Box sx={{borderTopLeftRadius: "inherit", borderTopRightRadius: "inherit", height: 15, bgcolor: "#E45271"}}/>
            <CardContent sx={{p: 1}}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <IconHeartBroken color={"#E45271"} />
                <Typography variant={"h4"} color={"#424242"} fontWeight={700}>Termination Events</Typography>
              </Stack>
              <Typography variant={"subtitle2"} fontWeight={400} fontSize={"0.8rem"} sx={{color: grey[500], textAlign: "left", pt: 1}}>
                These events cause the license to end and no longer be usable:
                {nft?.terminationEvents?.join(", ")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card direction={"column"} className={"border-shadow"} sx={{borderRadius: 3, height: "100%", minHeight: "18vh"}}>
            <Box sx={{borderTopLeftRadius: "inherit", borderTopRightRadius: "inherit", height: 15, bgcolor: "#E45271"}}/>
            <CardContent sx={{p: 1}}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <IconHeartBroken color={"#E45271"} />
                <Typography variant={"h4"} color={"#424242"} fontWeight={700}>Other Limitations</Typography>
              </Stack>
              <Typography variant={"subtitle2"} fontWeight={400} fontSize={"0.8rem"} sx={{color: grey[500], textAlign: "left", pt: 1}}>
                These events cause the license to end and no longer be useable:
                {nft?.otherLimitations}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}
