import {useParams} from "react-router-dom";
import {usePrismicDocumentByID} from "@prismicio/react";
import {Button, Grid, Stack, Box, Typography, ListItemButton, List, ListItemText} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconChevronLeft} from "@tabler/icons-react";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {Helmet} from "react-helmet";
import {useBack} from "../../hooks/useBack";

export const ArticlePage = () => {
  const {id} = useParams();
  const [article] = usePrismicDocumentByID(id);
  const navigate = useBack();

  const goToArticle = (index) => {
    const articleEl = document.getElementById(`#article-${index}`);
    console.log(articleEl);
    if (articleEl) {
      console.log("Scroll")
      articleEl.scrollIntoView({behavior: "smooth"})
    }
  }

  return (
    <>
      <Helmet>
        <title>{article?.data.title[0].text}</title>
        <meta name="description" content={article?.data.title[0].text}/>
        <meta property="og:title" content={article?.data.title[0].text}/>
        <meta property="og:description" content={article?.data.title[0].text}/>
        <meta property="og:image" content={article?.data.cover_image?.url}/>
      </Helmet>
      {
        article ? <Box maxWidth={"lg"} sx={{mx: 'auto', textAlign: "left", pb: 10}}>
            <Grid container>
              <Grid item xs={12} sm={2} sx={{py: 1}}>
                <Button
                  sx={{
                    py: 1, px: 2,
                    border: 0, borderColor: "divider",
                    borderRadius: 3,
                    bgcolor: grey[100], color: grey[700],
                    "&:hover": {
                      bgcolor: grey[200], color: grey[800],
                      borderColor: "divider", border: 0
                    }
                  }}
                  onClick={() => navigate("/blog")}
                  startIcon={<IconChevronLeft/>}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} sm={10} sx={{py: 1}}>
                <img src={article?.data?.cover_image?.url || ""} alt={article?.id} height={"auto"} width={"100%"}
                     style={{objectFit: "cover", objectPosition: "center", borderRadius: "16px", maxHeight: "60vh"}}/>
              </Grid>
              <Grid item xs={1} sm={2} sx={{display: {xs: "none", sm: "flex"}, py: 1}}>
              </Grid>
              <Grid item xs={12} sm={10} sx={{py: 2}}>
                <Stack direction={"column"} gap={2} maxWidth={"lg"} sx={{mx: 'auto', textAlign: "left"}}>
                  <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
                    <Typography className={"text-purple"}
                                textTransform={"uppercase"}>{article?.data?.category[0]?.text || ""}</Typography>
                    <Typography className={"text-grey"}>-- {article?.data?.read_time[0]?.text}</Typography>
                  </Stack>
                  <Typography variant={"h4"} fontWeight={700}>{article?.data?.title[0]?.text || ""}</Typography>
                  <Typography className={"text-grey"}>by {article?.data?.author_name[0]?.text}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={1} sm={2} sx={{display: {xs: "none", sm: "flex"}, py: 1}}>
                <List>
                  {
                    article?.data?.article_body_section?.map((body_section, index) => {
                      return <ListItemButton key={index} onClick={() => goToArticle(index)} className={"text-grey"}
                                             sx={{mb: 1}}>
                        <ListItemText primary={`Section ${index + 1}`} />
                      </ListItemButton>
                    })
                  }
                </List>
              </Grid>
              <Grid item xs={12} sm={10} sx={{py: 1}}>
                <Stack direction={"column"} gap={3} maxWidth={"lg"} sx={{mx: 'auto', textAlign: "left", pb: 10}}>
                  {
                    article?.data?.article_body_section?.map((body_section, index) => {
                      return <Typography key={index} id={`#article-${index}`}>
                        {body_section?.article_body_text?.map(body_text => body_text?.text)}
                      </Typography>
                    })
                  }
                </Stack>
              </Grid>

            </Grid>
          </Box> :
          <LoadingScreen/>
      }
    </>
  )
}