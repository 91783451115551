import {WalletInstructionsModal} from "./WalletInstructionsModal";
import {ContactFormModal} from "./ContactFormModal";
import React from "react";
import {LicenseRequestModal} from "./LicenseRequestModal";
import {RequestLicenseModal} from "./RequestLicenseModal";


// Here we will add all modals that are using atom as open/close
const Modals = () => {
  return (
    <>
      <WalletInstructionsModal/>
      <ContactFormModal/>
      {/*<LicenseRequestModal/>*/}
      <RequestLicenseModal/>
    </>
  )
}

export default Modals;