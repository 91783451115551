import {
  Button,
  IconButton,
  Stack, ClickAwayListener,
  Toolbar, Typography, useMediaQuery
} from "@mui/material";
import {
  IconChevronCompactLeft,
  IconChevronCompactRight
} from "@tabler/icons-react";
import React, {useCallback, useEffect} from "react";
import {SidebarItems} from "./SidebarItems";
import {Logo} from "../header/logo";
import {Link} from "react-router-dom";

export const Sidebar = ({open, setOpen}) => {
  const breakpointDownSm = useMediaQuery(theme => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (breakpointDownSm) {
      setOpen(false);
    }
  }, [breakpointDownSm])

  return (
    <Stack direction={"column"} gap={0.5}
           className={`sidebar ${open ? "active" : ""}`}
           sx={{
             display: {xs: "none", md: "flex"},
             position: "fixed",
             maxWidth: "330px",
             bgcolor: "background.default",
             width: "auto",
             height: "100dvh",
             pl: 1, pr: 2, py: 1,
             zIndex: 10
           }}>
      <Toolbar>
      </Toolbar>
      <SidebarItems />
      {/*<IconButton*/}
      {/*  onClick={() => setOpen(!open)}*/}
      {/*  size={"small"}*/}
      {/*  sx={{*/}
      {/*    position: "absolute", top: "50%", right: 0,*/}
      {/*    transform: `translate(${open ? "0%" : "50%"}, -50%)`,*/}
      {/*    bgcolor: "rgba(0,0,0, 0.025)",*/}
      {/*    backdropFilter: "blur(4px)",*/}
      {/*    display: {xs: open ? "flex" : "none", md: "flex"},*/}
      {/*    "&:hover": {*/}
      {/*      bgcolor: "rgba(0,0,0, 0.05)",*/}
      {/*      zoom: 1.08*/}
      {/*    }*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {open ? <IconChevronCompactLeft/> : <IconChevronCompactRight/>}*/}
      {/*</IconButton>*/}
    </Stack>
  )
}

