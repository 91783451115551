import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {IconArrowRight} from "@tabler/icons-react";
import {CardRounded} from "../../components/card/CardRounded";
import "./blog.css";
import Box from "@mui/material/Box";
import {useAllPrismicDocumentsByType} from "@prismicio/react";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {useEffect, useState} from "react";

export const BlogPage = () => {
  const [documents, {state}] = useAllPrismicDocumentsByType('blog_post');

  return (
    <>
      {state !== "loaded" && <LoadingScreen/>}
      <TrendingTopics documents={documents?.slice(0, 4) || []}/>
      {
        documents?.length > 4 && <MostRecentTopics documents={documents?.slice(4) || []}/>
      }
    </>
  )
}

const MostRecentTopics = ({documents}) => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    let newItems = [];
    if (documents && documents?.length) {
      newItems = documents?.map(item => {
        return {
          id: item?.id,
          image: item?.data?.cover_image?.url,
          title: item?.data?.title[0]?.text || "",
          category: item?.data?.category[0]?.text || "",
          readable: item?.data?.read_time[0]?.text || "",
        }
      })
    }
    setItems(newItems);
    setSelectedItem(newItems[0] || null)

  }, [documents])

  const index = items?.findIndex(item => item?.id === selectedItem?.id);

  return (
    <Grid container spacing={4} sx={{pt: 10, pb: 5, textAlign: "left", color: "#4B4B4B"}}>
      <Grid item xs={12}>
        <Typography variant={"h2"} fontWeight={700}>Most Recent</Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        <List>
          {items?.map((item) => {
            const {id, title, readable} = item;
            return (
              <ListItem
                key={id}
                sx={{borderBottom: 1, py: 1.5, borderColor: "divider"}}>
                <ListItemText
                  primary={title} secondary={readable}
                  primaryTypographyProps={{
                    variant: "h4", fontWeight: 700,
                    textDecoration: "none"
                  }}
                  secondaryTypographyProps={{
                    className: "text-grey", sx: {pt: 1.5}
                  }}
                />
                <ListItemIcon sx={{mb: "auto"}}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedItem(item);
                    }}
                  >
                    <IconArrowRight/>
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            )
          })}
        </List>
      </Grid>

      {
        selectedItem &&
        <Grid item xs={12} sm={7} sx={{marginTop: `${index * 120}px`}}>
          <CardRounded className={"article-section-bg"} style={{backgroundImage: `url(${selectedItem?.image})`}}
                       sx={{p: 4}}>
            <Box maxWidth={"300px"}
                 sx={{borderRadius: 5, bgcolor: "rgba(255,255,255,0.8)", color: "#4B4B4B", p: 3, mt: "auto"}}>
              <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
                <Typography className={"text-purple"}textDecoration={"none"} textTransform={"uppercase"}>
                  {selectedItem?.category}
                </Typography>
                <Typography className={"text-grey"}>-- {selectedItem?.readable}</Typography>
              </Stack>
              <Typography
                variant={"h4"} fontWeight={700} gutterBottom
                component={Link} to={`/blog/${selectedItem?.id}`}
                sx={{textDecoration: "none", color: "inherit"}}
              >
                {selectedItem?.title}
              </Typography>
            </Box>
          </CardRounded>
        </Grid>
      }
    </Grid>
  )
}

const TrendingTopics = ({documents}) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  useEffect(() => {
    let newItems = [];
    if (documents && documents?.length) {
      newItems = documents?.map(item => {
        return {
          id: item?.id,
          image: item?.data?.cover_image?.url,
          title: item?.data?.title[0]?.text || "",
          category: item?.data?.category[0]?.text || "",
          readable: item?.data?.read_time[0]?.text || "",
        }
      })
    }

    setItems(newItems);
  }, [documents])

  return (
    <Grid container spacing={4} sx={{py: 1, textAlign: "left", color: "#4B4B4B"}}>
      <Grid item xs={12}>
        <Typography variant={"h2"} fontWeight={700} gutterBottom>Trending Topics</Typography>
      </Grid>
      {items?.map(item => {
        const {id, image, title, category, readable} = item;
        return (
          <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
            <Stack direction={"column"} gap={2} sx={{cursor: "pointer", "&:hover": {scale: "1.03"} }} onClick={() => navigate(`/blog/${id}`)}>
              <img src={image} alt={title} height={"250px"} width={"auto"}
                   style={{objectFit: "cover", objectPosition: "center", borderRadius: "16px"}}/>
              <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
                <Typography className={"text-purple"} textTransform={"uppercase"}>{category}</Typography>
                <Typography className={"text-grey"}>-- {readable}</Typography>
              </Stack>
              <Typography variant={"h4"} color={"inherit"} fontWeight={700}
                          sx={{textDecoration: "none"}} gutterBottom>{title}</Typography>
            </Stack>
          </Grid>
        )
      })}
    </Grid>
  )
}