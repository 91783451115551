import {Modal, Box, Button, Stack} from "@mui/material";
import {MintBanner} from "../../../../components/banner/MintBanner";
import React, {useContext, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {toast} from "react-toastify";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../../../config/firebase";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "90vw", md: "40vw"}, maxHeight: "80dvh", minWidth: "300px",
  bgcolor: 'background.paper',
  borderRadius: 8, border: 0,
  boxShadow: 24, pt: 2, px: 5, pb: 5, overflowY: "auto"
};

export const TransferWorkModal = ({open, handleClose, nft}) => {
  const {state: {royaltyTemplates}, dispatch} = useContext(AppContext);
  const [publicWalletAddress, setPublicWalletAddress] = useState(null);

  const handleConfirm = async () => {
    if (publicWalletAddress && nft) {
      try {
        if (nft?.publicWalletAddress) {
          toast.error("There is owner in this work!")
          return;
        }
        const nftRef = doc(db, 'royalty_templates', nft?.uid)
        await updateDoc(nftRef, {publicWalletAddress})
        const nfts = royaltyTemplates?.map(template => {
          if (nft?.uid === template?.uid) {
            return {...template, publicWalletAddress}
          }
          return {...template};
        })
        dispatch({type: "update_royalty_templates", payload: nfts});
        toast.success("Successfully transfered work!");
        handleClose();
      } catch (e) {
        toast.error("Failed to transfer work!")
      }
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack direction={"column"} sx={style}>
        <MintBanner
          setAccount={setPublicWalletAddress}
        />
        <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
          <Button
            variant={"outlined"}
            color={"secondary"}
            sx={{borderRadius: 5, px: 3}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            // color={"primary"}
            className={"sign-button"} disabled={!publicWalletAddress}
            sx={{borderRadius: 5, px: 3, color: "#FFF"}}
            onClick={handleConfirm}
          >
            Transfer
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}