import React from "react";
import {
  Button,
  Stack,
  Toolbar,
  AppBar,
  IconButton, Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import {
  IconBell,
  IconMenu,
} from "@tabler/icons-react";
import {Logo} from "./logo";
import {NotificationsMenu} from "./NotificationsMenu";

export const Header = ({handleOpenDrawer}) => {

    return (
        <>
            <AppBar position="fixed"
                    sx={{
                        bgcolor: "transparent",
                        boxShadow: 0
                    }}
            >
                <Toolbar
                    sx={{
                        bgcolor: "rgba(255,255,255,0.7)",
                        backdropFilter: "blur(4px)", py: 1,
                        // display: { xs: 'none', md: "inherit" }
                    }}
                >
                    <Stack direction={"row"} sx={{width: "100%"}} gap={2} justifyContent={"space-between"}
                           alignItems={"center"}>
                      <Button
                        component={Link}
                        to={"/"}
                        variant={"text"}
                        sx={{color: "#000", borderRadius: 0}}
                      >
                        <Logo />
                        <Typography sx={{ml: 1, fontSize: "1.2rem", fontWeight: 600}} textTransform={"capitalize"} fontWeight={"bold"}>
                          IPRights4All
                        </Typography>
                      </Button>
                      <Stack direction={"row"} gap={1}>
                        {/*<Input*/}
                        {/*  size={"small"}*/}
                        {/*  disableUnderline*/}
                        {/*  placeholder={"Search"}*/}
                        {/*  sx={{borderRadius: 3, bgcolor: "rgba(0,0,0,0.04)", px: 2}}*/}
                        {/*  endAdornment={<InputAdornment position={"end"}><IconSearch /></InputAdornment>}*/}
                        {/*/>*/}
                        {/*<IconButton><IconLanguage /></IconButton>*/}
                        <NotificationsMenu />
                        <IconButton onClick={handleOpenDrawer} sx={{ display: {xs: "flex", md: "none"} }}><IconMenu/></IconButton>
                      </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    )
}
