import {Stack, Typography, Box, Button} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {LicenseEntryPage} from "./components/LicenseEntryPage";
import {LicenseForm} from "./components/LicenseForm";
import {toast} from "react-toastify";
import {RightLicenseType} from "../../utils/enums/RightLicenseType";
import {RoyaltyType} from "../../utils/enums/RoyaltyType";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import {collection, doc, getDoc, onSnapshot, or, query, where, writeBatch} from "firebase/firestore";
import {auth, db} from "../../config/firebase";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {v4 as uuid} from "uuid";
import {LicenseCreated} from "./components/LicenseCreated";
import {useAtom} from "jotai";
import {LicenseTemplatesAtom} from "../../atoms";
import {LicenseType} from "../../utils/enums/LicenseType";
import {IconChevronLeft, IconChevronRight} from "@tabler/icons-react";
import {countryData} from "../../utils/countryData";
import {NonExclusiveType} from "../../utils/enums/NonExclusiveType";
import {grey} from "@mui/material/colors";
import {ChooseLicenseTemplate} from "./components/ChooseLicenseTemplate";


export const CreateLicensePage = () => {
  const {nftId} = useParams();
  const {state: {royaltyTemplates, userDetails}, dispatch} = useContext(AppContext);

  const [rights, setRights] = useAtom(LicenseTemplatesAtom);
  const [isConnected, setIsConnected] = useState(false);

  const [nft, setNft] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [license, setLicense] = useState({
    type: LicenseType.MONETIZED,
    exclusiveType: RoyaltyType.EXCLUSIVE,
    licenses: {
      [RightLicenseType.REPRODUCING]: true,
      [RightLicenseType.DERIVATIVE_WORKS]: true,
      [RightLicenseType.DISTRIBUTING_COPIES]: true,
      [RightLicenseType.PERFORMING_PUBLICLY]: true,
      [RightLicenseType.DISTRIBUTING_COPIES]: true,
      [RightLicenseType.PERFORMING_PUBLICLY]: true,
      [RightLicenseType.DISPLAYING_PUBLICLY]: true,
      [RightLicenseType.AUDIO_PERFORMING_PUBLICLY]: true,
      [RightLicenseType.SUBLICENSING]: true
    }
  });
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (rights?.length && license?.type) {
      const rightsFiltered = rights?.filter(item => item?.type === license?.type)?.sort((a, b) => b.title.localeCompare(a.title));
      const firstSelected = rightsFiltered[0];
      if (firstSelected) {
        onSelectTemplate(firstSelected);
      } else {
        onUpdate("licenses", {})
      }
    }
  }, [rights, license?.type])

  useEffect(() => {
    if (userDetails) {
      const licenseTemplates = query(
        collection(db, "license_templates"),
        or(
          where("createdBy", "==", null),
          where("createdBy", "==", auth?.currentUser?.uid)
        )
      );
      const unsubscribe = onSnapshot(licenseTemplates, (querySnapshot) => {
        let templates = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          templates.push(data);
        });
        setRights(templates);
      });
      return () => unsubscribe();
    }
  }, [userDetails])

  useEffect(() => {
    const fetchNft = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "royalty_templates", nftId);
        const docSnap = await getDoc(docRef);

        if (docSnap?.exists()) {
          const nftData = {...docSnap?.data()}
          setNft(nftData);
        }
      } catch (e) {
        console.error(e);
        toast.error("Failed to fetch NFT.")
        navigate("/portfolio")
      } finally {
        setLoading(false);
      }
    }

    if (nftId && userDetails) {
      const nft = royaltyTemplates?.find(item => item?.uid === nftId);
      if (nft) {
        setNft(nft);
      } else {
        fetchNft();
      }
    }
  }, [nftId, royaltyTemplates, userDetails])

  useEffect(() => {
    if (nft) {
      if (nft?.userUid !== userDetails?.uid) {
        navigate("/portfolio")
      }
    }
  }, [nft, userDetails])

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    if (!key) {
      toast.info("Please add 'name' attribute on input field");
      return;
    }
    onUpdate(key, value)
  }

  const onUpdate = (key, value) => {
    setLicense(prev => ({...prev, [key]: value}))
  }

  const onSelectTemplate = (template) => {
    if (template) {
      const newTemplate = {...template};
      const rights = {...newTemplate.rights};
      delete newTemplate.createdBy;
      delete newTemplate.uid;
      delete newTemplate.rights;
      newTemplate.licenses = rights;

      setLicense(prev => ({...prev, ...newTemplate}))
    }
  }

  const onSubmit = async (e) => {
    e?.preventDefault();
    if (step === 0) {
      setStep(prev => prev + 1);
      return;
    }

    if (!checkIsValid()) {
      return;
    }

    try {
      setSubmitting(true);
      const newLicense = {...license};
      newLicense.uid = uuid();
      newLicense.userUid = userDetails?.uid || null;
      newLicense.nftUid = nft?.uid;
      newLicense.archived = false;
      newLicense.createdAt = new Date().getTime();
      newLicense.name = `License (${license?.exclusiveType?.replaceAll("_", "-")?.toLowerCase()}, ${license?.price || license?.percent || "Not monetized"}${license?.price ? " $" : license?.percent ? " %" : ""})`
      const licenses = [];
      if (nft?.licenses && nft?.licenses?.length) {
        licenses.push(...nft.licenses)
      }
      licenses.push({
        uid: newLicense.uid,
        name: newLicense.name,
        createdAt: newLicense.createdAt,
        exclusiveType: newLicense.exclusiveType,
        archived: newLicense.archived
      });
      const updateNft = {licenses};
      const nftRef = doc(db, 'royalty_templates', nft?.uid)
      const licenseRef = doc(db, 'licenses', newLicense?.uid)

      const batch = writeBatch(db);

      batch.set(licenseRef, newLicense);
      batch.update(nftRef, updateNft);

      await batch.commit();
      const templates = royaltyTemplates?.map(template => {
        if (nft?.uid === template?.uid) {
          return {...nft, ...updateNft};
        }
        return {...template};
      })
      setLicense(newLicense);
      dispatch({type: "update_royalty_templates", payload: templates});
      toast.success("Successfully created license");
      handleNextStep();
    } catch (e) {
      console.log(e);
      toast.error(e?.message || "Failed to create license")
    } finally {
      setSubmitting(false);
    }
  }

  const handleNextStep = () => setStep(prev => prev + 1);

  const handlePrevStep = () => setStep(prev => prev - 1);

  const checkIsValid = () => {
    /* if (!license?.limitations) {
       return false;
     }*/
    if (step === 0) {
      return true;
    }
    if (license?.type === LicenseType.MONETIZED) {
      if (!license?.location || (license?.location === countryData.US.name && !license?.state) || isConnected === false) {
        return false;
      }
      if (license?.exclusiveType === RoyaltyType.EXCLUSIVE) {
        return isFinite(license?.price) && isFinite(license?.termsOfYears) && license?.price !== "" && license?.termsOfYears !== "";
      } else {
        if (license?.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE) {
          return isFinite(license?.price) && isFinite(license?.termsOfYears) && license?.price !== "" && license?.termsOfYears !== "";
        }
        return isFinite(license?.termsOfYears) && isFinite(license?.percent) && isFinite(license?.copies) && license?.percent !== "" && license?.copies !== "" && license?.termsOfYears !== "";
      }
    }

    return true;
  }

  let isValid = useMemo(() => checkIsValid(), [license, isConnected]);

  return (
    <Stack direction={"column"} maxWidth={"lg"} alignItems={"stretch"}
           sx={{mx: "auto", px: 2, textAlign: "left", pb: 15}} gap={3}>
      {loading && <LoadingScreen/>}
      <Box sx={{textAlign: 'center'}} fullWidth>
        <Typography variant={"h2"} fontWeight={700} fullWidth align={"center"} gutterBottom>Creating a
          License</Typography>
        <Typography variant={"subtitle2"} className={"text-grey"} fontWeight={300}>Three quick tips & one example to
          explain licenses</Typography>
      </Box>
      {
        {
          0: <ChooseLicenseTemplate onNextStep={handleNextStep} onUpdate={onUpdate} />,
          // 1: <LicenseEntryPage />,
          1: <LicenseForm setIsConnected={setIsConnected} license={license} nft={nft} onChange={onChange} onUpdate={onUpdate} onSelectTemplate={onSelectTemplate} />,
          2: <LicenseCreated nft={nft} license={license}/>
        }[step]
      }
      <Stack direction={"row"} gap={2} maxWidth={"lg"} useFlexGap flexWrap={"wrap"} sx={{display: step === 2 || step === 0 ? "none" : "flex"}}>
        <Button
          sx={{
            py: 1, px: 2,
            border: 0, borderColor: "divider",
            borderRadius: 3,
            bgcolor: grey[100], color: grey[700],
            "&:hover": {
              bgcolor: grey[200], color: grey[800],
              borderColor: "divider", border: 0
            }
          }}
          disabled={step === 0}
          onClick={handlePrevStep}
          startIcon={<IconChevronLeft/>}
        >
          Back
        </Button>
        <Button className={"sign-button"} onClick={onSubmit} disabled={!isValid || submitting} size={"large"}
                endIcon={<IconChevronRight/>} sx={{mx: "auto", px: 10, py: 1.5, borderRadius: 3, flex: 1}}>
          Create this license
        </Button>
      </Stack>
    </Stack>
  )
}