import React from "react";
import {signOut} from "firebase/auth";
import {auth} from "../../../config/firebase";
import {
  Avatar,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography, useMediaQuery
} from "@mui/material";
import {
  IconBell,
  IconCreditCard,
  IconLogout,
  IconSettings,
  IconUser,
  IconUserCog,
  IconWallet
} from "@tabler/icons-react";
import {Link} from "react-router-dom";

export const SettingsItem = ({handleClose}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandle = async () => {
    try {
      await signOut(auth);
      handleCloseUserMenu();
      handleClose();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <ListItemButton
        onClick={handleOpenUserMenu}
        className={"text-grey nav-item-btn"}
        sx={{py: 1.5, borderRadius: 4, minWidth: "0 !important",}}
      >
        <ListItemIcon sx={{minWidth: "0 !important"}}><IconSettings/></ListItemIcon>
        <ListItemText primary={"Settings"} sx={{pl: 1.5, display: "none"}} className={"nav-item-text"} primaryTypographyProps={{sx: {fontSize: 16}}}/>
      </ListItemButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        keepMounted
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'center',}}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        sx={{zIndex: 90000000000000}}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { width: anchorElUser && anchorElUser.offsetWidth - 20}
        }}
        slotProps={{
          paper: {
            sx: {borderRadius: 5, boxShadow: 3}
          }
        }}
      >
        <Stack
          direction={"row"} gap={1} alignItems={"center"}
          sx={{
            px: 2, py: 1.5,
          }}
        >
          <Avatar alt="Remy Sharp" size={"small"} sx={{bgcolor: "#D55380"}} src={auth?.currentUser?.photoURL}/>
          <Typography fontWeight={"bold"}>{auth?.currentUser?.displayName || auth?.currentUser?.email}</Typography>
        </Stack>
        <Divider/>
        <MenuItem component={Link} to={`/view-profile`} onClick={handleClose} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconUser /></ListItemIcon>
          <ListItemText primary={"View Profile"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
        <MenuItem component={Link} to={"/connect-wallet"} onClick={handleClose} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconWallet /></ListItemIcon>
          <ListItemText primary={"Connect wallet"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
        <MenuItem component={Link} to={"/payment-settings"} onClick={handleClose} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconCreditCard /></ListItemIcon>
          <ListItemText primary={"Payment Settings"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
        <MenuItem component={Link} to={"/notifications"} onClick={handleClose} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconBell /></ListItemIcon>
          <ListItemText primary={"Notifications"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
        <MenuItem component={Link} to={"/account-settings"} onClick={handleClose} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconUserCog /></ListItemIcon>
          <ListItemText primary={"Account settings"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logoutHandle} sx={{px: 2, py: 1.5, fontSize: "0.75rem"}}>
          <ListItemIcon><IconLogout /></ListItemIcon>
          <ListItemText primary={"Logout"} primaryTypographyProps={{sx: {fontSize: 15}}}/>
        </MenuItem>
      </Menu>
    </>
  )
}
