import {
  Autocomplete,
  Avatar,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, {useContext, useState} from "react";
import {toast} from "react-toastify";
import {grey} from "@mui/material/colors";
import {IconHelpCircle, IconTrash} from "@tabler/icons-react";
import * as yup from "yup";
import {useFormik} from "formik";
import {projectTypes} from "../../register-work/components/ProtectIp";
import {countryData} from "../../../utils/countryData";
import {uploadFile} from "../../../utils/uploadFile";
import {signOut, updateProfile} from "firebase/auth";
import {auth, db} from "../../../config/firebase";
import {deleteDoc, doc} from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import useConfirm from "../../../hooks/useConfirm";
import {AppContext} from "../../../context/AppContext";

const validationSchema = yup.object({
  firstName: yup
    .string('First name')
    .required('First name is required'),
  lastName: yup
    .string('Last name')
    .required('Last name is required'),
  username: yup
    .string('Username')
    .required('Username is required'),
  email: yup
    .string('Email')
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string('Phone number'),
  projects: yup
    .array().of(yup.string()),
  location: yup
    .string('Country of residence')
});

export const AccountSettingsPage = () => {
  const {state: {userDetails}, updateUserDetails} = useContext(AppContext);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ConfirmationDialog, confirmDelete] = useConfirm("Are you sure you want to delete this account?", "You will not be able to recover it?")
  const formik = useFormik({
    initialValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      username: userDetails?.username || '',
      email: userDetails?.email || '',
      phoneNumber: userDetails?.phoneNumber ||'',
      projects: userDetails?.projects || [],
      location: userDetails?.location || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (image) {
          const url = `users/${userDetails?.id}-${image?.name}`;
          const photoURL = await uploadFile({img: image, path: url});
          values.photoURL = photoURL;
          const displayName = `${values.firstName || ""} ${values.lastName || ""}`
          await updateProfile(auth.currentUser, { displayName, photoURL });
        }
        await updateUserDetails(values);
        toast.success("Account information saved successfully");
      } catch (e) {
        toast.error("Failed to update user information");
      } finally {
        setLoading(false);
      }
    },
  });

  const onDelete = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently signed in.");
      return;
    }
    const response = await confirmDelete();
    if (!response) {
      return;
    }

    try {
      setLoading(true);
      // Run transaction to delete the Firestore document
      const userDocRef = doc(db, "users", auth?.currentUser?.uid);;

      await deleteDoc(userDocRef)

      // Delete the user from Firebase Authentication
      await deleteUser(user)

      await signOut(auth);
      toast.success("User and user document deleted successfully.");
    } catch (error) {
      console.log(error);
      toast.error("Error deleting user and user document:", error?.message?.replaceAll("Firebase:", ""));
      // Handle errors appropriately here
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <ConfirmationDialog />
      <Stack direction={"column"} gap={3} maxWidth={"sm"}>
        <Stack direction={"row"} gap={3} alignItems={"center"}>
          <Avatar
            src={image ? URL.createObjectURL(image) : userDetails?.photoURL}
            alt={"User"}
            sx={{width: 100, height: 100}}
          />
          <Button
            variant={"outlined"}
            sx={{px: 5, borderRadius: 5, color: grey[700], borderColor: grey[700], "&:hover": {bgcolor: grey[50], borderColor: grey[700]} }}
            component="label"
          >
            Update
            <input
              type="file"
              hidden
              onChange={(event) => setImage(event.target.files[0])}
              accept="image/*"
            />
          </Button>
          <Button startIcon={<IconTrash />} sx={{px: 5, borderRadius: 5, color: grey[700], borderColor: grey[700], "&:hover": {bgcolor: grey[50], borderColor: grey[700]} }}>
            Remove
          </Button>
        </Stack>
        <InputLabel
          id="firstName"
          name="firstName"
          label={"First name"}
          helperText={"Your first name"}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          placeholder={"Enter your name"}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        />
        <InputLabel
          id="lastName"
          name="lastName"
          label={"Last name"}
          helperText={"Your last name"}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          placeholder={"Enter your last name"}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        />
        <InputLabel
          id="username"
          name="username"
          label={"Username"}
          helperText={"Your username"}
          value={formik.values.username}
          onChange={formik.handleChange}
          placeholder={"Enter your username"}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
        />
        <InputLabel
          id="email"
          name="email"
          label={"Email"}
          disabled
          helperText={"Your email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={"Enter your email"}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        <InputLabel
          id="phoneNumber"
          name="phoneNumber"
          label={<>Phone Number <Typography component={"i"}>(include country code)</Typography></>}
          helperText={"Your phone number"}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          placeholder={"Enter your phone number"}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        />
        <SelectLabel
          id="projects"
          name="projects"
          label={"Type of projects"}
          helperText={"Select all type of projects"}
          value={formik.values.projects}
          onChange={formik.handleChange}
          placeholder={"Select your type of projects"}
          onBlur={formik.handleBlur}
          error={formik.touched.projects && Boolean(formik.errors.projects)}
          items={projectTypes?.map(project => ({value: project?.name, name: project?.name}))}
          multiple
        />
        <AutocompleteLabel
          id="location"
          name="location"
          label={"Country of residence"}
          helperText={"Your country of residence"}
          value={formik.values.location}
          onChange={(e, newValue) => formik.setFieldValue("location", newValue)}
          placeholder={"Select your country of residence"}
          onBlur={formik.handleBlur}
          error={formik.touched.location && Boolean(formik.errors.location)}
          items={Object.values(countryData)?.map(option => option?.name)}
        />
      </Stack>
      <Stack direction={"row"} gap={2} alignItems={"center"} sx={{mt: 5}}>
        <Button variant={"outlined"} disabled={loading} type={"button"} onClick={() => formik.resetForm()} sx={{px: 3, borderRadius: 5, color: grey[700], borderColor: grey[700], "&:hover": {bgcolor: grey[50], borderColor: grey[700]} }}>
          Cancel
        </Button>
        <Button className={"sign-button"} disabled={loading} type={"submit"} sx={{px: 3, borderRadius: 5}}>
          Save Changes
        </Button>
        <Button variant={"contained"} disabled={loading} color={"error"} type={"button"} onClick={onDelete} sx={{px: 3, ml: 'auto', borderRadius: 5}}>
          Delete Account
        </Button>

      </Stack>
    </form>
  )
}

const InputLabel = ({label, helperText, value, onChange, ...props}) => {

  return (
    <Stack direction={"column"} gap={1} className={"text-grey"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={600}>{label}</Typography>
        {helperText && <Tooltip title={helperText}><IconHelpCircle size={16} /></Tooltip>}
      </Stack>
      <TextField
        {...props}
        value={value}
        onChange={onChange}
      />
    </Stack>
  )
}

const SelectLabel = ({label, helperText, value, onChange, items, ...props}) => {

  return (
    <Stack direction={"column"} gap={1} className={"text-grey"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={600}>{label}</Typography>
        {helperText && <Tooltip title={helperText}><IconHelpCircle size={16} /></Tooltip>}
      </Stack>
      <Select
        {...props}
        value={value}
        onChange={onChange}
      >
        {items?.map(item => <MenuItem key={item?.value} value={item?.value}>{item?.name}</MenuItem>)}
      </Select>
    </Stack>
  )
}
const AutocompleteLabel = ({label, helperText, value, onChange, items, ...props}) => {

  return (
    <Stack direction={"column"} gap={1} className={"text-grey"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={600}>{label}</Typography>
        {helperText && <Tooltip title={helperText}><IconHelpCircle size={16} /></Tooltip>}
      </Stack>
      <Autocomplete
        {...props}
        value={value}
        onChange={onChange}
        options={items}
        renderInput={(params) => <TextField {...params} sx={{flexShrink: 0, width: "100%", borderRadius: 4}} />}
      />
    </Stack>
  )
}