import {Button, Grid, Typography} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import "./discovery.css";
import {WhyLicenseIPCard} from "./components/WhyLicenseIPCard";
import {UnlockPotentialWork} from "./components/UnlockPotentialWork";
import {BottomDiscoveryCards} from "./components/BottomDiscoveryCards";

export const DiscoveryPage = () => {

  return (
    <>
      <Grid container maxWidth={"lg"} sx={{py: 5, mx: {xs: "", md: "auto"}}}>
        <Grid item xs={6} sm={6} sx={{pb: 3, textAlign: "left", p:1}}>
          <Typography variant={"h2"} fontWeight={700}>Discover</Typography>
        </Grid>
        <Grid item xs={6} sm={6} sx={{pb: 3, textAlign: "right", p:1}}>
          <Button component={Link} to={"/register-work"} className={"gradient-text"}
                  startIcon={<IconPlus color={"#7758D7"} strokeWidth={2.5}/>}>
            <Typography fontWeight={700} fontSize={16} sx={{display: {xs: "none", sm: "flex"}}}>Register a Creative
              Work</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} sx={{p: 1, "&:hover > div": {transform: "scale(1.01)", boxShadow: 5} }}>
          <WhyLicenseIPCard/>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} sx={{p: 1, "&:hover > div": {transform: "scale(1.01)", boxShadow: 5} }}>
          <UnlockPotentialWork/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{p: 1, "&:hover > div": {transform: "scale(1.01)", boxShadow: 5} }}>
          <BottomDiscoveryCards id={"ZnrSmhEAAB8A1NMA"} position={"top"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{p: 1, "&:hover > div": {transform: "scale(1.01)", boxShadow: 5} }}>
          <BottomDiscoveryCards id={"ZnrR1BEAAB8A1NGA"} position={"top"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} sx={{p: 1, "&:hover > div": {transform: "scale(1.01)", boxShadow: 5} }}>
          <BottomDiscoveryCards id={"ZnmZ1REAACAA0uGP"} />
        </Grid>
      </Grid>
    </>
  )
}




