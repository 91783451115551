import React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {NonExclusiveType} from "../../../utils/enums/NonExclusiveType";
import {RoyaltyType} from "../../../utils/enums/RoyaltyType";

export const CompensationStructure = ({license, onUpdate}) => {

  const isExclusive = license?.exclusiveType === RoyaltyType.EXCLUSIVE;
  const isNonExclusive = license?.exclusiveType === RoyaltyType.NON_EXCLUSIVE;

  return (
    <Stack direction={"column"} gap={1}>
      <Typography
        sx={{
          fontWeight: 600,
          color: "#2D2D30",
        }}
        variant={"subtitle1"}
      >
        Compensation Structure
      </Typography>
      <Typography
        sx={{fontWeight: 600}}
        className={"text-grey"}
        fontSize={"0.8rem"}
      >
        Custom royalty setup? <a href={"#"} style={{color: "#E45271"}}>Contact us.</a>
      </Typography>
      <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
        sx={{
          borderColor: "#E45271 !important",
          color: "#E45271",
          border: 0,
          py: 1,
        }}
        
      >
        <Button
          sx={{
            color: isExclusive ? "#FFF" : "#E45271",
            bgcolor: isExclusive ? "#E45271" : "transparent",
            border: 1,
            borderColor: "#E45271",
            "&:hover": {
              bgcolor: "#e03458",
              color: "#FFF"
            }
          }}
          onClick={() => onUpdate("exclusiveType", RoyaltyType.EXCLUSIVE)}
        >
          Exclusive
        </Button>
        <Button
          sx={{
            color: isNonExclusive ? "#FFF" : "#E45271",
            bgcolor: isNonExclusive ? "#E45271" : "transparent",
            border: 1,
            borderColor: "#E45271",
            "&:hover": {
              bgcolor: "#e03458",
              color: "#FFF"
            }
          }}
          onClick={() => onUpdate("exclusiveType", RoyaltyType.NON_EXCLUSIVE)}
        >
          Non Exclusive
        </Button>
      </ButtonGroup>
      <Stack direction={"row"} gap={1} sx={{display: isNonExclusive ? "flex" : "none"}}>
        <Button
          onClick={() => onUpdate("nonExclusiveType", NonExclusiveType.DEFAULT_PRICE)}
          sx={{
            bgcolor: license.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "#000" : "#FFF",
            color: license.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "#FFF" : "#000",
            borderRadius: 5,
            border: 1,
            borderColor: "#000",
            textTransform: "none",
            px: 2,
            "&:hover": {
              bgcolor: license.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "#1f1f1f" : "#FFF",
              color: license.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "#FFF" : "#1f1f1f",
            }
          }}
        >
          Default Price
        </Button>
        <Button
          onClick={() => onUpdate("nonExclusiveType", NonExclusiveType.ROYALTIES)}
          sx={{
            bgcolor: license.nonExclusiveType !== NonExclusiveType.DEFAULT_PRICE ? "#000" : "#FFF",
            color: license.nonExclusiveType !== NonExclusiveType.DEFAULT_PRICE ? "#FFF" : "#000",
            borderRadius: 5,
            border: 1,
            borderColor: "#000",
            textTransform: "none",
            px: 2,
            "&:hover": {
              bgcolor: license.nonExclusiveType !== NonExclusiveType.DEFAULT_PRICE ? "#1f1f1f" : "#FFF",
              color: license.nonExclusiveType !== NonExclusiveType.DEFAULT_PRICE ? "#FFF" : "#1f1f1f",
            }
          }}
        >
          Royalties %
        </Button>
      </Stack>

      <Grid container spacing={2} maxWidth={"md"}>
        <Grid item xs={12} sm={6} sx={{display: isExclusive || license?.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "none" : "block" }}>
          <Typography fontSize={"1rem"} fontWeight={600}>Royalty: <Typography component={"span"} style={{color: "red"}}>*</Typography></Typography>
          <TextField
            reqired
            type={"number"}
            placeholder={"10"}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>%</InputAdornment>
            }}
            onChange={e => {
              onUpdate("percent", e.target.value);
            }}
            value={license?.percent}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: isExclusive || license?.nonExclusiveType === NonExclusiveType.DEFAULT_PRICE ? "block" : "none" }}>
          <Typography fontSize={"1rem"} fontWeight={600}>Price: <Typography component={"span"} style={{color: "red"}}>*</Typography></Typography>
          <TextField
            required
            type={"number"}
            placeholder={"10"}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position={"end"}>USD</InputAdornment>
            }}
            onChange={e => {
              onUpdate("price", e.target.value);
            }}
            value={license?.price}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: isNonExclusive ? "block" : "none" }} />
        <Grid item xs={12} sm={6}>
          <Typography fontSize={"1rem"} fontWeight={600}>Terms of years for the licenses: <Typography component={"span"} style={{color: "red"}}>*</Typography></Typography>
          <TextField
            required
            type={"number"}
            placeholder={"10"}
            fullWidth
            value={license?.termsOfYears}
            onChange={e => {
              onUpdate("termsOfYears", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: isNonExclusive ? "block" : "none" }}>
          <Typography fontSize={"1rem"} fontWeight={600}>Number of distribution copies allowed: <Typography component={"span"} style={{color: "red"}}>*</Typography></Typography>
          <TextField
            required
            type={"number"}
            placeholder={"10"}
            fullWidth
            value={license?.copies}
            onChange={e => {
              onUpdate("copies", e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
