import {Avatar, Button, Stack, Typography} from "@mui/material";
import {green, grey} from "@mui/material/colors";
import {IconChecks, IconChevronRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import React from "react";
import {useSetAtom} from "jotai";
import {WalletInstructionsAtom} from "../../../atoms";
import AnimationSuccess from "../../../components/lottie-animations/AnimationSuccess";

export const NftCreated = ({nft}) => {
  const setWalletInstructions = useSetAtom(WalletInstructionsAtom);

  return (
    <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
           justifyContent={'center'} alignContent={'center'}
           sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
      <AnimationSuccess open />

      <Typography variant={"h3"}>
        {nft.title} has been created!
      </Typography>
      <Typography sx={{color: grey[600]}}>View your NFT on the blockchain at</Typography>
      {nft?.nftTxnHash && <Typography>
        <a href={"https://polygonscan.com/tx/" + nft?.nftTxnHash} target={'_blank'}>
          Polygonscan.com
        </a>
      </Typography>}
      <Typography sx={{color: grey[600]}} align={"center"}>
        By timestamping your work as an NFT, you've taken a proactive step to safeguard your creative
        work, helping to protect against copyright infringement in the future. 🔥💪
      </Typography>
      <Typography sx={{color: grey[600]}} align={"center"}>
        Your work is now in your digital wallet. <Button onClick={() => setWalletInstructions(true)}>See how to access it in your wallet.</Button>
      </Typography>

      <Button
        component={Link}
        to={"/portfolio"}
        className={"sign-button"}
        endIcon={<IconChevronRight/>}
        sx={{textTransform: "none", px: 4, py: 1.5, borderRadius: 5}}
      >
        View this work in your portfolio
      </Button>
    </Stack>
  )
}