import React, {useState} from "react";
import ReusableModal from "../ReusableModal";
import {TableView} from "../../pages/portfolio/components/nft/TableView";
import {Stack, Button, Divider} from "@mui/material";
import {CardView} from "../../pages/portfolio/components/license/CardsView";

const PageView = {
  NFT: "NFT",
  LICENSE: "LICENSE"
}

export const ViewLicenseDetails = ({open, handleClose, nft, license}) => {
  const [viewPage, setViewPage] = useState(PageView.NFT);

  console.log(nft);

  return (
    <ReusableModal open={open} handleClose={handleClose}>
      <Stack direction={"column"} gap={2}>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Button className={viewPage === PageView.NFT ? "gradient-text" : "text-grey"} onClick={() => setViewPage(PageView.NFT)} sx={{px: 2, borderRadius: 2, borderColor: viewPage === PageView.NFT ? "primary !important" : "transparent"}}>
            NFT
          </Button>
          <Button className={viewPage === PageView.LICENSE ? "gradient-text" : "text-grey"} onClick={() => setViewPage(PageView.LICENSE)} sx={{px: 2, borderRadius: 2, borderColor: viewPage === PageView.LICENSE ? "primary !important" : "transparent"}}>
            LICENSE
          </Button>
        </Stack>
        <Divider />
        {
          {
            [PageView.NFT]: <TableView nft={nft}/>,
            [PageView.LICENSE]: <CardView nft={nft} license={license} hideTitle/>
          }[viewPage]
        }
      </Stack>
    </ReusableModal>
  )
}