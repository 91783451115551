import React, {useContext, useMemo, useState} from "react";
import {
  Box,
  Button,
  Divider,
  List, ListItem, ListItemText,
  Stack,
  TextField,
  Popover,
  Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {green, grey, lightBlue, orange, teal, yellow} from "@mui/material/colors";
import GoogleButton from "react-google-button";
import {ArrowForwardIos, Circle} from "@mui/icons-material";
import {MiniHeader} from "../../../layouts/mini/MiniHeader";
import {auth, db, googleProvider} from "../../../config/firebase";
import {createUserWithEmailAndPassword, signInWithPopup} from "firebase/auth"
import {toast} from "react-toastify";
import {doc, setDoc} from "firebase/firestore";
import {updateProfile} from "firebase/auth";
import "../../../miscellaneous-styles.css";
import {AppContext} from "../../../context/AppContext";

export const RegisterPage = () => {
  const {getUserDetails} = useContext(AppContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const onSubmitHandle = async (e) => {
    e?.preventDefault();
    if (!email || !confirmEmail || !password || !confirmPassword || !firstName || !lastName) {
      toast.error("All fields are required!");
      return;
    }
    if (email !== confirmEmail) {
      toast.error("Emails do not match");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const displayName = `${firstName} ${lastName}`?.trim();
      await updateProfile(auth.currentUser, {displayName})
      await setDoc(doc(db, "users", auth.currentUser.uid),
        {
          uid: auth?.currentUser?.uid,
          firstName: firstName,
          lastName: lastName,
          displayName: displayName || auth.currentUser?.displayName,
          acceptTerms: false,
          email: email,
          publicAccount: false,
          authUid: auth?.currentUser?.uid,
          photoURL: auth?.currentUser?.photoURL,
        });
      await getUserDetails();
    } catch (err) {
      toast.error(err.message?.replaceAll("Firebase:", ""));
    }
  }

  async function handleLoginGoogle() {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/my-counts")
    } catch (err) {
      console.log(err);
    }
  }

  const isDisabled = !email || !confirmEmail || (email !== confirmEmail) || !password || !confirmPassword || (password !== confirmPassword) || !firstName || !lastName;

  return (
    <Stack direction={"column"} sx={{width: "100%", height: "100%"}} justifyContent={'center'}
           alignItems={"center"}>
      <Box sx={{
        bgcolor: "#FFF",
        border: 1,
        borderRadius: 5,
        borderColor: "divider",
        p: 5,
        backgroundColor: "rgba(255,255,255, 0.65)",
        padding: "40px 60px",
        minWidth: "300px"
      }}>
        <Stack direction={"column"} gap={3} sx={{width: "100%", height: "100%"}} justifyContent={'center'}
               alignItems={"center"}>
          {/*HEADER*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
            {/*TITLE*/}
            <Typography variant={"h2"} fontWeight={700} className={"login-title"}>
              Sign up
            </Typography>
            {/*SIGN UP*/}
            <Box style={{display: "inline-flex", gap: 8, alignItems: "center"}}>
              <Typography className={"login-subtitle"}>Already have an account?</Typography>
              <Typography component={Link} to={"/login"} className={"login-subtitle alt-color no-underline"}>Log
                in</Typography>
            </Box>
          </Stack>
          {/*LOGIN WITH MAIL*/}
          <Stack component={"form"} onSubmit={onSubmitHandle} direction={"column"} gap={2} sx={{width: '100%'}}>
            <TextField
              type={"text"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant={"standard"}
              required
              fullWidth
              label={"First Name"}
              placeholder={"Enter your name"}
            />
            <TextField
              type={"text"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant={"standard"}
              required
              fullWidth
              label={"Last Name"}
              placeholder={"Enter your last name"}
            />
            <TextField
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant={"standard"}
              required
              fullWidth
              label={"Email"}
              placeholder={"Enter your email address"}
            />
            <TextField
              type={"email"}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              variant={"standard"}
              required
              fullWidth
              label={"Type your email again"}
              placeholder={"Enter your email address"}
            />
            <PasswordInput password={password} setPassword={setPassword}/>

            <TextField
              type={"password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              variant={"standard"}
              required
              fullWidth
              label={"Type your password again"}
              placeholder={"Password"}
            />

            <Button
              fullWidth
              variant={"contained"}
              type={"submit"}
              className={"sign-button"}
              disabled={isDisabled}
              sx={{
                textTransform: "capitalize",
                bgcolor: lightBlue[400],
                fontWeight: "bold",
                marginTop: "20px",
                '&:hover': {bgcolor: lightBlue[600]}
              }}
            >
              Sign up <ArrowForwardIos sx={{fontSize: 12}}/>
            </Button>
          </Stack>
          <Box sx={{width: "100%", margin: "7px 0px"}}>
            <Divider className={"small-subtitle"}>or sign in with</Divider>
          </Box>
          <Box sx={{width: "100%"}}>

            <GoogleButton
              style={{width: "100%", borderRadius: "8px", boxShadow: "none"}}
              onClick={() => handleLoginGoogle()}
              type={"light"}
            />
          </Box>

        </Stack>
      </Box>
    </Stack>
  )
}


export const PasswordInput = ({password, setPassword}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    console.log("ANC OPEN")
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("ANC CLOSE")
    setAnchorEl(null);
  };

  const {bgcolor, counter} = useMemo(() => {
    let counter = 0;
    let bgcolor = grey[300];

    if (password?.length > 0) {
      bgcolor = yellow[500];
      counter += 1;
      if (password?.length > 5) {
        counter += 1;
        if (/(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)) {
          counter += 1;
        }
        if (/(?=.*[-+_!@#$%^&*.,?])/.test(password)) {
          counter += 1;
        }
        if (/(?=.*[\d])/.test(password)) {
          counter += 1;
        }
      }
    }

    if (counter === 1) {
      bgcolor = yellow[500]
    } else if (counter === 2) {
      bgcolor = orange[300]
    } else if (counter === 3) {
      bgcolor = orange[600]
    } else if (counter === 4) {
      bgcolor = green[400]
    } else if (counter === 5) {
      bgcolor = green[600]
    }

    return {bgcolor, counter}
  }, [password])

  return (
    <>
      <TextField
        type={"password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant={"standard"}
        onInput={handleOpen}
        onBlur={handleClose}
        id={"demo-positioned-button"}
        required
        fullWidth
        label={"Password"}
        placeholder={"Password"}
      />
      <Popover
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            boxShadow: 6,
            p: 2,
            borderRadius: 5
          }
        }}
      >
        <Box sx={{width: "100%",}}>
          <Typography fontSize={13}>Must have at least 6 characters.</Typography>
          <Stack direction={"row"} sx={{width: "100%", height: 3, mt: 1}} gap={1}>
            <Divider color={bgcolor} sx={{flex: 1}}/>
            <Divider color={counter >= 2 ? bgcolor : grey[300]} sx={{flex: 1}}/>
            <Divider color={counter >= 3 ? bgcolor : grey[300]} sx={{flex: 1}}/>
            <Divider color={counter >= 4 ? bgcolor : grey[300]} sx={{flex: 1}}/>
          </Stack>
          <Typography sx={{mt: 2}} fontSize={13}>It's better to have:</Typography>
          <List>
            <ListItem sx={{my: 0, py: 0}}>
              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                  Upper & lower case letters
                </>}
              />
            </ListItem>
            <ListItem sx={{my: 0, py: 0}}>

              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                  A symbol (#$&)
                </>}
              />
            </ListItem>
            <ListItem sx={{my: 0, py: 0}}>

              <ListItemText
                sx={{fontSize: 13, px: 0, mx: 0, textAlign: "left"}}
                secondary={<>
                  <Circle sx={{color: orange[500], fontSize: 10, mr: 1}}/>
                  A longer password
                </>}
              />
            </ListItem>

          </List>
        </Box>
      </Popover>
    </>
  )
}