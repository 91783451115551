import React from "react";
import {Button, Fade, IconButton, Stack, Typography} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {IconCopy, IconDownload, IconLayoutCollage, IconLink, IconPictureInPicture, IconX} from "@tabler/icons-react";
import {deepPurple, grey} from "@mui/material/colors";
import QRCode from "react-qr-code";
import svgDownloader from "save-svg-as-png"
import {copyToClipboard} from "../../utils/functions";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "280px", maxWidth: "400px", width: "90%",
  bgcolor: 'background.paper',
  borderRadius: 5,
  border: "0 !important",
  boxShadow: 24, p: {xs: 2, md: 5}
};

export const ShareModal = ({open, url, handleClose}) => {

  const onDownload = () => {
    const svg = document.getElementById(url);
    if (!svg) {
      return;
    }
    try {

      svgDownloader.saveSvgAsPng(svg, "qrcode.png", {backgroundColor: "white", scale: 5, encoderOptions: 1});

    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(4px)"
      }}
    >
      <Fade in={open} timeout={200}>
          <Stack direction={"column"} gap={3} sx={style}>
            <Typography variant={"h3"} fontWeight={600} align={"center"}>License for My Creative Work</Typography>
            {url && <QRCode
              size={"70%"}
              id={url}
              value={url}
              level={"H"}
              style={{margin: "auto"}}
            /> }
            <Box sx={{textAlign: 'center'}}>
              <Typography>Your short link</Typography>
              <Typography component={"a"} href={url} target={"_blank"} sx={{color: "#D55380"}} noWrap>{url?.substr(0, 35)}...</Typography>
            </Box>
            <Stack direction={"row"} gap={2}>
              <Button
                onClick={() =>  copyToClipboard(url)}
                startIcon={<IconLink size={17} />}
                className={"sign-button"}
                sx={{flex: 1, borderRadius: 5, px: 2}}
              >
                Copy Link
              </Button>
              <Button
                onClick={onDownload}
                startIcon={<IconLayoutCollage size={17} />}
                color={"secondary"} variant={"outlined"}
                sx={{flex: 1, borderRadius: 5, px: 2}}
              >
                Save QR
              </Button>
            </Stack>
          </Stack>
      </Fade>
    </Modal>
  )
}
