import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconChevronRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import axios from "axios";
import {AppContext} from "../../context/AppContext";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {getStripeSession, loadNFts, mintNft} from "../../utils/functions";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import AnimationSuccess from "../lottie-animations/AnimationSuccess";
import AnimationFailed from "../lottie-animations/AnimationFailed";

export const MintNFT = ({sessionId, publicAddress, nftUid}) => {
    const [status, setStatus] = useState(null);
    const [nftData, setNftData] = useState(null);
    const [nftTxnHash, setNftTxnHash] = useState('');
    const {state: {royaltyTemplates}, dispatch} = useContext(AppContext);

    useEffect(() => {
        setStatus("verifying")

        async function getSessionStatusAndMint(sessionId) {
            try {
                const sessionStatus = await getStripeSession({sessionId});
                console.log("Session Status: ", sessionStatus.data)
                if (sessionStatus.data === 'complete') {
                    setStatus('minting')
                    await mintNft(publicAddress, nftUid, setNftTxnHash, setStatus, nftData)
                } else {
                    const nftRef = doc(db, 'royalty_templates', nftUid)
                    await updateDoc(nftRef, {paid: false, nftTxnHash: ''})
                    setStatus(sessionStatus.data)
                }
            } catch (error) {
                console.log(error)
            }
        }

        // loadNFts(dispatch, royaltyTemplates)
        sessionId && getSessionStatusAndMint(sessionId)

    }, []);

    useEffect(() => {
        if (!royaltyTemplates || !nftUid) return
        const nftData = royaltyTemplates.filter(nft => nft.uid === nftUid)[0]
        setNftData(nftData)
    }, [royaltyTemplates, nftUid])

    useEffect(() => {
        if (nftData && nftTxnHash) {
            nftData.transaction_hash = nftTxnHash
            const royaltyTemplatesWithRemovedNft = royaltyTemplates.filter(template => template.uid !== nftData.uid)
            dispatch({type: "update_royalty_templates", payload: [...royaltyTemplatesWithRemovedNft, nftData]});
        }
    }, [nftTxnHash])


    if (!nftData) {
        return <LoadingScreen/>
    }

    if (status === null || status === 'verifying') {
      return (
        <LoadingScreen />
      )
    }

    if (status === 'open') {
        //ToDo handle by returning to the previous page
        return (
            <Typography>Minting failed</Typography>
        )
    }

    if (status === 'minting') {
        return (
            <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
                   justifyContent={'center'} alignContent={'center'}
                   sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
                <Avatar
                    sx={{
                        width: 110,
                        height: 110
                    }}
                >
                    <CircularProgress/>
                </Avatar>

                <Typography variant={"h3"}>
                    Minting in progress... {nftData.name}!
                </Typography>
                <Typography sx={{color: grey[600]}}>
                    Please wait while we register your NFT on the blockchain.</Typography>
            </Stack>
        )
    }

    if (status === 'complete') {
        return (
            <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
                   justifyContent={'center'} alignContent={'center'}
                   sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
              <AnimationSuccess open />
              <Typography variant={"h3"}>
                    {nftData.title} has been minted!
                </Typography>
                <Typography sx={{color: grey[600]}}>View your NFT on the blockchain at</Typography>
                <Typography>
                    <a href={"https://polygonscan.com/tx/" + nftTxnHash} target={'_blank'}>
                        Polygonscan.com
                    </a>
                </Typography>
                <Typography sx={{color: grey[600]}}>
                    By timestamping your work as an NFT, you've taken a proactive step to safeguard your creative
                    work,
                    helping to protect against copyright infringement in the future. 🔥💪 </Typography>

                <Button
                    component={Link}
                    to={"/portfolio"}
                    className={"sign-button"}
                    endIcon={<IconChevronRight/>}
                    sx={{textTransform: "none", px: 2, borderRadius: 3}}
                >
                    View this NFT in your portfolio
                </Button>
            </Stack>
        )
    }

    if (status === 'failed') {
        return (
            <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
                   justifyContent={'center'} alignContent={'center'}
                   sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
                <AnimationFailed open />

                <Typography variant={"h3"}>
                    {nftData.title} has failed to mint!
                </Typography>

                <Button
                    component={Link}
                    to={"/portfolio"}
                    className={"sign-button"}
                    endIcon={<IconChevronRight/>}
                    sx={{textTransform: "none", px: 2, borderRadius: 3}}
                >
                    View this work in your portfolio and retry minting
                </Button>
            </Stack>
        )
    }
}

