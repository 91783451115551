import {
  Box,
  Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import {grey} from "@mui/material/colors";
import {AlertBanner} from "../../../components/banner/AlertBanner";
import IconExport from "../../../assets/icons/IconExport";
import CelebrateIcon from "../../../assets/icons/celebrate.png";

export const AcknowledgeOriginalWork = ({originalWork, ownWork, onUpdate}) => {
  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <Typography color={"#000"} fontWeight={700} fontSize={16}>Is this an original work?</Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={originalWork}
          onChange={(e) => onUpdate("originalWork", e.target.value)}
        >
          <FormControlLabel value={true} control={<Radio color={"warning"} />} label="Yes" />
          <FormControlLabel value={false} control={<Radio color={"warning"} />} label="No" />
        </RadioGroup>
      </FormControl>
      <Stack direction={"row"} gap={1}>
        <Box>
          <Checkbox
            checked={ownWork}
            onChange={(e) => onUpdate("ownWork", e.target.checked)}
            inputProps={{'aria-label': 'controlled'}}
            color={"warning"}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              color: "#2D2D30",
              pb: 1
            }}
          >
            This is my work.<Typography component={"span"} style={{color: "red"}}>*</Typography>
          </Typography>
          <Typography fontWeight={500} fontSize={"0.8rem"} color={grey[600]}>
            By clicking this checkbox, you acknowledge that this is your own work.
          </Typography>
        </Box>
      </Stack>
      {
          ownWork &&
        <AlertBanner
          icon={<IconExport iconPath={CelebrateIcon}/>}
          color={"#7758D7"}
          text={<Typography className={"alert-text"}>Thank you for uploading an original work!</Typography>}
        />
      }

    </>
  )
}