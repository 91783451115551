import React, {useState} from "react";
import {
  Button,
  Fade,
  IconButton,
  Stack,
  Typography
} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  IconX
} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import {TableView} from "./nft/TableView";
import {PrintProofModal} from "./print/PrintProofModal";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80vw", maxHeight: "80dvh",
  minWidth: "60vw", minHeight: "60dvh",
  bgcolor: 'background.paper',
  borderRadius: 8, border: 0,
  boxShadow: 24, p: 3, overflowY: "auto"
};


export const ViewNftModal = ({open, handleClose, nft}) => {

  return (
    nft &&
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Fade in={open} timeout={200}>
        <Stack direction={"column"} sx={style}>

          <Header
            nft={nft}
            handleClose={handleClose}
          />
          <Box sx={{flex: 1, overflowY: "auto", p: 1,}}>
            <TableView nft={nft}/>
          </Box>
        </Stack>
      </Fade>
    </Modal>
  )
}


const Header = ({nft, handleClose}) => {
  const [print, setPrint] = useState(false);

  return (
    <>
      <PrintProofModal
        open={print} handleClose={() => setPrint(false)}
        nft={nft}
      />
      <Stack direction={"row"} alignItems={"center"} gap={1}
             sx={{pb: 1.5, mx: 1, borderBottom: 1, borderColor: "divider"}}>
        <Typography variant={"h2"} color={"#2D2D30"} fontWeight={700} sx={{flex: 1}}
                    noWrap>{nft?.title}</Typography>
        <Button
          component={"a"} target={"_blank"}
          href={"https://polygonscan.com/tx/" + nft?.nftTxnHash}
          className={"sign-button"} disabled={!nft?.nftTxnHash}
          sx={{borderRadius: 5, px: 2, py: 1, ml: "auto"}}
        >
          View on PolygonScan
        </Button>
        <Button
          color={"secondary"}
          variant={"outlined"}
          onClick={() => setPrint(true)}
          sx={{
            borderRadius: 5,
            px: 2, py: 1
          }}
        >
          Download PDF
        </Button>
        <IconButton
          onClick={handleClose}
          color={"#FFF"}
        >
          <IconX color={grey[500]}/>
        </IconButton>
      </Stack>
    </>
  )
}