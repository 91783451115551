import React, {useState} from "react";
import {Box, Button, Divider, Stack, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {lightBlue} from "@mui/material/colors";
import {ArrowForwardIos, ArrowBackIos} from "@mui/icons-material";
import {MiniHeader} from "../../../layouts/mini/MiniHeader";
import {auth} from "../../../config/firebase";
import {sendPasswordResetEmail} from "firebase/auth";
import {toast} from "react-toastify";
import "../../../miscellaneous-styles.css";
import {AlertBanner} from "../../../components/banner/AlertBanner";
import {IconConfetti} from "@tabler/icons-react";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmitHandle = async (e) => {
    e.preventDefault();
    try {
      if (email) {
        await sendPasswordResetEmail(auth, email);
        setIsSubmitted(true);
        toast.success("Password reset link is sent to your email address.")
      }
    } catch (err) {
      toast.error(err.message.replace("Firebase: ", ""));
    }
  }

  return (
    <Stack direction={"column"}
           sx={{width: "100%", height: "100%"}}
           justifyContent={'center'}
           alignItems={"center"}>
      <Box sx={{
        bgcolor: "#FFF",
        border: 1,
        borderRadius: 5,
        borderColor: "divider",
        p: 5,
        minWidth: "300px",
        maxWidth: "360px",
        backgroundColor: "rgba(255,255,255, 0.65)",
        padding: "40px 60px",
      }}>
        <Stack component={"form"} onSubmit={onSubmitHandle} direction={"column"} gap={2}
               sx={{width: "100%", height: "100%"}} justifyContent={'center'}
               alignItems={"center"}>
          {/*HEADER*/}
          <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
            {/*TITLE*/}
            <Typography variant={"h2"} fontWeight={700} className={"login-title"}>
              Forgot Password
            </Typography>
          </Stack>
          {/*LOGIN WITH MAIL*/}
          {
            isSubmitted ?
              <Stack direction={"column"} gap={2} sx={{width: '100%', mt: 2}}>
                <AlertBanner
                  icon={<IconConfetti color={"#7758D7"} size={50}/>}
                  color={"#7758D7"}
                  text={<>Password reset link sent. Please check your email.</>}
                />
              </Stack>
              :
              <Stack direction={"column"} gap={2} sx={{width: '100%', mt: 2}}>
                <TextField
                  type={"email"}
                  variant={"standard"}
                  required
                  fullWidth
                  label={"Email"}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder={"Enter your email address"}
                />

                <Button
                  fullWidth
                  variant={"contained"}
                  type={"submit"}
                  className={"sign-button"}
                  sx={{
                    textTransform: "capitalize",
                    bgcolor: lightBlue[400],
                    fontWeight: "bold",
                    '&:hover': {bgcolor: lightBlue[600]}
                  }}
                  endIcon={<ArrowForwardIos sx={{fontSize: 12}}/>}
                >
                  Reset Password
                </Button>


              </Stack>
          }
          <Box sx={{width: "100%", margin: "7px 0px"}}>
            <Divider className={"small-subtitle"}>or</Divider>
          </Box>
          <Box sx={{width: "100%"}}>
            <Button
              fullWidth
              variant={"text"}
              type={"button"} color={"info"}
              component={Link}
              to={"/login"}
              startIcon={<ArrowBackIos sx={{fontSize: 12}}/>}
            >
              Back to login
            </Button>
          </Box>

        </Stack>
      </Box>
    </Stack>
  )
}
