import {toast} from "react-toastify";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {auth, db} from "../config/firebase";
import axios from "axios";

export const copyToClipboard = (data) => {
    try {
        navigator.clipboard.writeText(data);
        toast.success("Successfully copied to clipboard")
    } catch (error) {
        toast.error("Error copying URL")
    }
}


export async function loadNFts(dispatch, royaltyTemplates) {
    if (royaltyTemplates.length > 0) return
    try {
        const userCollectionsCollection = collection(db, "royalty_templates");
        const customQuery = query(userCollectionsCollection, where("userUid", "==", auth.currentUser.uid))
        // const querySnapshot = await getDocs(customQuery);
        // const nftWorks = []
        // querySnapshot.forEach((doc) => {
        //     nftWorks.push(doc.data());
        // })
        onSnapshot(customQuery, (querySnapshot) => {
            let newData = [];
            querySnapshot.forEach(doc => {
                const data = doc.data();
                newData.push(data);
            });
            for (const template of royaltyTemplates) {
                const index = newData?.findIndex(i => i?.uid === template?.uid);
                if (index < 0) {
                    newData.push(template);
                }
            }
            newData = newData.sort((a, b) => b.createdAt - a.createdAt);
            dispatch({type: "update_royalty_templates", payload: [...royaltyTemplates, ...newData]});
        });
    } catch (error) {
        toast.error("Error loading your works, please refresh!")
        console.error('Error:', error);
    }
}


export async function mintNft(publicAddress, nftUid, setNftTxnHash, setStatus, nftData, dispatch) {
    const url = process.env.REACT_APP_ENVIRONMENT === 'development' ?
        'http://localhost:5001/c3-nft-c3/us-central1/mintNft' :
        'https://us-central1-c3-nft-c3.cloudfunctions.net/mintNft'
    console.log(publicAddress)
    const params = {publicAddress: publicAddress, nftUid: nftUid};
    try {
        const response = await axios.get(url, {params});
        console.log("response.data")
        console.log(response.data)
        setNftTxnHash && setNftTxnHash(response.data.transaction_hash)

        const nftRef = doc(db, 'royalty_templates', nftUid)
        await updateDoc(nftRef, {nftTxnHash: response.data.transaction_hash, paid: true})
        setStatus && setStatus('complete')
        if (nftData) nftData.transaction_hash = response.data.transaction_hash
        return nftData
    } catch (error) {
        //todo: if this fails move the customer back to the  registerWork page and prefill all the data they had before
        //  update that the nft was paid for but not minted
        const nftRef = doc(db, 'royalty_templates', nftUid)
        await updateDoc(nftRef, {paid: true, nftTxnHash: ''})
        setStatus && setStatus('failed')
        console.log(error)
        return null
    }
}

export async function mintLicense(publicAddress, nftUid, durationInDays, licenseUid) {
    const url = process.env.REACT_APP_ENVIRONMENT === 'development' ?
        'http://localhost:5001/c3-nft-c3/us-central1/mintLicense' :
        'https://us-central1-c3-nft-c3.cloudfunctions.net/mintLicense'

    console.log(publicAddress)
    console.log(nftUid)
    console.log(durationInDays)
    console.log(licenseUid)

    const params = {
        publicAddress: publicAddress,
        nftUid: nftUid,
        durationInDays: durationInDays,
        licenseUid: licenseUid
    };
    try {
        const response = await axios.get(url, {params});
        console.log("response.data")
        console.log(response.data)
        return response.data.transaction_hash
    } catch (error) {
        //todo: if this fails move the customer back to the  registerWork page and prefill all the data they had before
        //  update that the nft was paid for but not minted
        return null
    }
}


export const getStripeSession = ({sessionId}) => {
    const url = process.env.REACT_APP_ENVIRONMENT === 'development' ?
        'http://localhost:5001/c3-nft-c3/us-central1/session_status' :
        'https://us-central1-c3-nft-c3.cloudfunctions.net/session_status'
    const params = {session_id: sessionId}
    return axios.get(url, {params})
}
