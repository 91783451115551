import React, {createContext, useEffect, useReducer} from 'react'
import {getAuth} from "firebase/auth";
import {collection, doc, getDoc, getDocs, onSnapshot, or, query, setDoc, updateDoc, where} from "firebase/firestore";
import {db} from "../config/firebase";
import {toast} from "react-toastify";


const initialState = {
  workName: "Uylsses",
  royaltyTemplates: [],
  licenses: [],
  userDetails: null,
}

export const AppContext = createContext(initialState);
const auth = getAuth()

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_royalty_templates':
      return {...state, royaltyTemplates: action.payload}
    case 'update_licenses':
      return {...state, licenses: action.payload}
    case 'update_user':
      return {...state, userDetails: {...state.userDetails, ...action.payload}}
    default:
      return state;
  }
};

export const AppContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state?.userDetails?.uid) {
      const requestCollection = collection(db, "user_licenses");
      const customQuery = query(requestCollection, where("userUid", "==", state?.userDetails?.uid))
      const unsubscribe = onSnapshot(customQuery, (querySnapshot) => {
        let newData = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newData.push(data);
        });
        newData = newData.sort((a, b) => b.createdAt - a.createdAt);
        console.log(newData);
        dispatch({type: "update_licenses", payload: [...newData]});
      });
      return () => unsubscribe();
    }
  }, [state?.userDetails])

  useEffect(() => {
    if (state?.userDetails?.uid) {
      const nftUids = state?.licenses?.map(license => license?.nftUid) || [];
      const userCollectionsCollection = collection(db, "royalty_templates");
      let customQuery = query(userCollectionsCollection, where("userUid", "==", auth.currentUser.uid));
      if (nftUids?.length) {
        customQuery = query(userCollectionsCollection,
          or(
            where("userUid", "==", auth.currentUser.uid),
            where("uid", "in", nftUids)
          )
        );
      }
      const unsubscribe = onSnapshot(customQuery, (querySnapshot) => {
        let newData = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newData.push(data);
        });
        newData = newData.sort((a, b) => b.createdAt - a.createdAt);
        dispatch({type: "update_royalty_templates", payload: [...newData]});
      });

      return () => unsubscribe();
    }

  }, [state?.userDetails, state?.licenses])

  const getUserDetails = async () => {
    if (auth?.currentUser?.uid) {
      try {
        const docRef = doc(db, "users", auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap?.exists()) {
          const userDetails = {...docSnap?.data()}
          if (userDetails?.firstName || userDetails?.lastName) {
            userDetails.displayName = `${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`?.trim();
          }
          dispatch({type: "update_user", payload: userDetails});
        } else {
          const userDetails = {
            uid: auth?.currentUser?.uid,
            firstName: "",
            lastName: "",
            displayName: auth.currentUser?.displayName,
            acceptTerms: false,
            email: auth?.currentUser?.email,
            authUid: auth?.currentUser?.uid,
            photoURL: auth?.currentUser?.photoURL,
          }
          await setDoc(doc(db, "users", auth.currentUser.uid), userDetails);
          dispatch({type: "update_user", payload: userDetails});
        }
      } catch (err) {
        console.log(err);
        //TODO: handle error
        // toast.error(err.message);
      }
    }
  }

  const updateUserDetails = async (data) => {
    if (data) {
      try {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(docRef, {...data});
        dispatch({type: "update_user", payload: {...data}});
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  }


  return (
    <AppContext.Provider value={{state, dispatch, getUserDetails, updateUserDetails}}>
      {props.children}
    </AppContext.Provider>
  )
}
