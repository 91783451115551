export const statesData = {
  "AL": { state: "Alabama", abbreviation: "AL" },
  "AK": { state: "Alaska", abbreviation: "AK" },
  "AZ": { state: "Arizona", abbreviation: "AZ" },
  "AR": { state: "Arkansas", abbreviation: "AR" },
  "CA": { state: "California", abbreviation: "CA" },
  "CO": { state: "Colorado", abbreviation: "CO" },
  "CT": { state: "Connecticut", abbreviation: "CT" },
  "DE": { state: "Delaware", abbreviation: "DE" },
  "DC": { state: "District of Columbia", abbreviation: "DC" },
  "FL": { state: "Florida", abbreviation: "FL" },
  "GA": { state: "Georgia", abbreviation: "GA" },
  "HI": { state: "Hawaii", abbreviation: "HI" },
  "ID": { state: "Idaho", abbreviation: "ID" },
  "IL": { state: "Illinois", abbreviation: "IL" },
  "IN": { state: "Indiana", abbreviation: "IN" },
  "IA": { state: "Iowa", abbreviation: "IA" },
  "KS": { state: "Kansas", abbreviation: "KS" },
  "KY": { state: "Kentucky", abbreviation: "KY" },
  "LA": { state: "Louisiana", abbreviation: "LA" },
  "ME": { state: "Maine", abbreviation: "ME" },
  "MT": { state: "Montana", abbreviation: "MT" },
  "NE": { state: "Nebraska", abbreviation: "NE" },
  "NV": { state: "Nevada", abbreviation: "NV" },
  "NH": { state: "New Hampshire", abbreviation: "NH" },
  "NJ": { state: "New Jersey", abbreviation: "NJ" },
  "NM": { state: "New Mexico", abbreviation: "NM" },
  "NY": { state: "New York", abbreviation: "NY" },
  "NC": { state: "North Carolina", abbreviation: "NC" },
  "ND": { state: "North Dakota", abbreviation: "ND" },
  "OH": { state: "Ohio", abbreviation: "OH" },
  "OK": { state: "Oklahoma", abbreviation: "OK" },
  "OR": { state: "Oregon", abbreviation: "OR" },
  "MD": { state: "Maryland", abbreviation: "MD" },
  "MA": { state: "Massachusetts", abbreviation: "MA" },
  "MI": { state: "Michigan", abbreviation: "MI" },
  "MN": { state: "Minnesota", abbreviation: "MN" },
  "MS": { state: "Mississippi", abbreviation: "MS" },
  "MO": { state: "Missouri", abbreviation: "MO" },
  "PA": { state: "Pennsylvania", abbreviation: "PA" },
  "RI": { state: "Rhode Island", abbreviation: "RI" },
  "SC": { state: "South Carolina", abbreviation: "SC" },
  "SD": { state: "South Dakota", abbreviation: "SD" },
  "TN": { state: "Tennessee", abbreviation: "TN" },
  "TX": { state: "Texas", abbreviation: "TX" },
  "UT": { state: "Utah", abbreviation: "UT" },
  "VT": { state: "Vermont", abbreviation: "VT" },
  "VA": { state: "Virginia", abbreviation: "VA" },
  "WA": { state: "Washington", abbreviation: "WA" },
  "WV": { state: "West Virginia", abbreviation: "WV" },
  "WI": { state: "Wisconsin", abbreviation: "WI" },
  "WY": { state: "Wyoming", abbreviation: "WY" }
};
