import {useParams} from "react-router-dom";
import {LicenseType} from "../../utils/enums/LicenseRequestType";
import {LicenseRequest} from "../../components/license/LicenseRequest";
import {LicenseSend} from "../../components/license/LicenseSend";
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {doc, onSnapshot} from "firebase/firestore";
import {db} from "../../config/firebase";
import {useAtom} from "jotai/index";
import {LicenseRequestViewAtom} from "../../atoms";

export const LicenseRequestPage = () => {
  const {uid} = useParams();
  const [licenseRequest, setLicenseRequest] = useAtom(LicenseRequestViewAtom);

  useEffect(() => {
    if (uid) {
      const licenseRef = doc(db, 'license_requests', uid)
      const unsubscribe = onSnapshot(licenseRef, (doc) => {
        const data = doc.data();
        setLicenseRequest(data ? {...data} : null);
      });

      return () => unsubscribe();
    }
  }, [uid])

  return (
    licenseRequest && <Box maxWidth={"md"} sx={{mx: "auto"}}>
      {
        {
          [LicenseType.REQUEST_LICENCE]: <LicenseRequest />,
          [LicenseType.SEND_LICENCE]: <LicenseSend />,
        }[licenseRequest?.type]
      }
    </Box>
  )
}