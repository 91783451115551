import React, {useContext, useEffect, useState} from "react";
import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {AppContext} from "../../context/AppContext";
import {loadNFts, mintNft} from "../../utils/functions";
import {toast} from "react-toastify";
import {auth} from "../../config/firebase";
import {TemplateRow} from "./components/PortfolioTableItem";
import {ViewNftModal} from "./components/ViewNftModal";
import {handleArchiveLicense} from "../../utils/archiveLicense";
import useConfirm from "../../hooks/useConfirm";

export const Portfolio = () => {
    const {state: {royaltyTemplates}, dispatch} = useContext(AppContext);
    const [selectedNft, setSelectedNft] = useState(null);
    const [status, setStatus] = useState(null);
    const [DeleteDialog, confirmDelete] = useConfirm("Are you sure you want to delete this license?", "Once a license is deleted, it cannot be recovered. You are responsible for any losses that might occur.")
    const [ArchiveDialog, confirmArchive] = useConfirm("Are you sure you want to archive this license?", "Archiving will disable the Share URL and public view, which can prevent anyone else from accepting the license.\n\nYou’ll still be able to view archived licenses in your portfolio.")

    useEffect(() => {
        if (royaltyTemplates && royaltyTemplates.length === 0) {
            console.log('loading nfts')
            // auth.currentUser && loadNFts(dispatch, royaltyTemplates)
        }
        //TODO: fix this to only load the nfts if the user is logged in
        // the error happens when we create the nft and locally it's put as not paid but we don't update it to paid other than in db
    }, [])

    useEffect(() => {
        if (selectedNft && royaltyTemplates?.length) {
            const selected = royaltyTemplates?.find(template => template?.uid === selectedNft?.uid);
            setSelectedNft(selected || null);
        }
    }, [royaltyTemplates, selectedNft])

    async function tryMintingAgain(nftData) {
        toast.info("Attempting to mint...")
        const updatedNft = await mintNft(nftData.publicWalletAddress, nftData.uid, null, setStatus, nftData)
        if (updatedNft) {
            const royaltyTemplatesWithRemovedNft = royaltyTemplates.filter(template => template.uid !== nftData.uid)
            console.log('dispatching')
            dispatch({type: "update_royalty_templates", payload: [...royaltyTemplatesWithRemovedNft, updatedNft]});
            //refresh the page
            
            toast.success("NFT minted")
        } else {
            toast.error("Error minting NFT, try again later")
        }
    }

    const handleViewDocument = (template) => {
        setSelectedNft(template);
    }

    const handleArchive = async (license, nft) => {
        try {
            const handleSuccess = (newLicense, newNft) => {
                const items = royaltyTemplates?.map(item => {
                  if (item?.uid === newNft?.uid) {
                    return {...newNft};
                  }
                  return {...item};
                });
                dispatch({type: "update_royalty_templates", payload: items});
                toast.success(`License ${newLicense?.archive ? "archived" : "unarchived"} successfully`)
            }
            const response = await confirmArchive();
            if (response) {
              await handleArchiveLicense({license, nft, handleSuccess});
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to Archive license")
        }
    }

    const handleDelete = async (license, nft) => {
      try {
        const handleSuccess = (newNft) => {
          const items = royaltyTemplates?.map(item => {
            if (item?.uid === newNft?.uid) {
              return {...newNft};
            }
            return {...item};
          });
          dispatch({type: "update_royalty_templates", payload: items});
          toast.success(`License deleted successfully`)
        }
        const handleSignedLicense = async (license, nft) => {
          try {
            console.log("HANDLE SIGNED LICENSE")
            const handleSuccess = (newLicense, newNft) => {
              const items = royaltyTemplates?.map(item => {
                if (item?.uid === newNft?.uid) {
                  return {...newNft};
                }
                return {...item};
              });
              dispatch({type: "update_royalty_templates", payload: items});
              toast.success(`License ${newLicense?.archive ? "archived" : "unarchived"} successfully`)
            }
            const response = await confirmArchive("We’re sorry, this license cannot be deleted.", "We can’t delete a license has already been accepted.\n\nConsider archiving the license. Archiving will disable the Share URL and public view, which can prevent anyone else from accepting the license.");
            console.log(response);
            if (response) {
              await handleArchiveLicense({license, nft, handleSuccess});
            }
          } catch (error) {
            console.log(error);
            toast.error("Failed to Archive license")
          }
        };

        const response = await confirmDelete();
        if (response) {
          // await handleDeleteLicense({license, nft, handleSuccess, handleSignedLicense});
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to Delete license")
      }
    }


    return (
        <>
            <DeleteDialog />
            <ArchiveDialog />
            <ViewNftModal
                open={!!selectedNft}
                handleClose={() => setSelectedNft(null)}
                nft={selectedNft}
            />
            <Stack
                direction={"row"}
                gap={2}
                maxWidth={"lg"}
                sx={{px: 2, width: "100%", mx: {xs: "", sm: "auto"} }}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Typography
                    sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        fontSize: "2rem",
                        color: "#2D2D30"
                    }}
                >
                    Portfolio
                </Typography>
            </Stack>

            <TableContainer component={Paper} sx={{my: 1, maxWidth: "lg", boxShadow: 0, mx: {xs: "", sm: "auto"} }}>
                <Table sx={{ minWidth: {xs: "auto", sm: 650} }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell sx={{color: "#666D74 !important"}}>Name</TableCell>
                            <TableCell sx={{color: "#666D74 !important", display: {xs: "none", sm: "table-cell"}}}  align="left">Type</TableCell>
                            <TableCell sx={{color: "#666D74 !important"}} align="left">Mint</TableCell>
                            <TableCell sx={{color: "#666D74 !important"}} align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            royaltyTemplates
                                ?.map((nftData, index) => <TemplateRow key={nftData?.uid} nftData={nftData} onMint={tryMintingAgain}
                                                                       handleArchive={handleArchive} handleDelete={handleDelete} handleViewDocument={handleViewDocument}/>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
