import {Stack, Typography} from "@mui/material";
import AnimationFailed from "../lottie-animations/AnimationFailed";
import React from "react";
import {useAtomValue} from "jotai";
import {LicenseRequestViewAtom} from "../../atoms";

export const LicenseRequestDeclined = () => {
  const licenseRequest = useAtomValue(LicenseRequestViewAtom);

  const from = licenseRequest?.firstName || licenseRequest?.lastName ? `${licenseRequest?.firstName} ${licenseRequest?.lastName}`?.trim() : licenseRequest?.email;

  return (
    <Stack direction={"column"} gap={2}>
      <Typography variant={"h2"} fontWeight={700} align={"center"} gutterBottom>License Declined</Typography>
      <AnimationFailed />
      <Typography fontWeight={400} maxWidth={"sm"} sx={{mx: "auto"}} className={"text-grey"} align={"center"}>
        You have declined license from {from}
      </Typography>

    </Stack>
  )
}