import React, {useContext, useEffect, useState} from "react";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where, writeBatch} from "firebase/firestore";
import {auth, db} from "../../config/firebase";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {AppContext} from "../../context/AppContext";
import {getStripeSession, mintLicense} from "../../utils/functions";
import {LicenseRequestType} from "../../utils/enums/LicenseRequestType";
import MintingLoadingScreen from "../lottie-animations/MintingLoadingScreen";
import AnimationSuccess from "../lottie-animations/AnimationSuccess";
import {Button, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconChevronRight} from "@tabler/icons-react";
import AnimationFailed from "../lottie-animations/AnimationFailed";

export const MintLicense = ({requestUid, publicAddress, sessionId}) => {
  const navigate = useNavigate();

  const {state: {userDetails}} = useContext(AppContext);
  const [licenseRequest, setLicenseRequest] = useState(null);

  useEffect(() => {
    if (requestUid) {
      const unsubscribe = onSnapshot(doc(db, "license_requests", requestUid), (doc) => {
        const data = doc.data();
        if (!data || !data?.uid) {
          navigate("/")
        }
        setLicenseRequest(data);
      });

      return () => unsubscribe();
    }
  }, [requestUid])

  useEffect(() => {
    if (userDetails && licenseRequest && licenseRequest?.licenseUid && licenseRequest?.state !== LicenseRequestType?.MINTED) {
      createLicense();
    }
  }, [licenseRequest, userDetails])

  const createLicense = async () => {
    try {
      if (!sessionId) {
        return;
      }
      const sessionStatus = await getStripeSession({sessionId});
      console.log("session status:", sessionStatus.data);
      if (sessionStatus.data !== "complete") {
        const requestRef = doc(db, 'license_requests', requestUid)
        await updateDoc(requestRef, {paid: false, nftTxnHash: '', state: LicenseRequestType.FAILED})
        return;
      }

      const uid = licenseRequest?.licenseUid;
      const requestCollection = collection(db, "user_licenses");
      const customQuery = query(requestCollection, where("requestUid", "==", requestUid))

      let querySnap = await getDocs(customQuery);
      let license = {};
      let mintLicenseObject = {};

      if (querySnap.size) {
        querySnap.docs.forEach(data => {
          license = {...data.data()}
        })
        mintLicenseObject = {...license};
      } else {
        const docRef = doc(db, "licenses", uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap?.exists()) {
          toast.error("License not found.");
          return;
        }
        license = {...docSnap.data()};
        mintLicenseObject = {...license};
        mintLicenseObject.uid = uuid();
        mintLicenseObject.licenseUid = uid;
        mintLicenseObject.requestUid = licenseRequest.uid;
        mintLicenseObject.userUid = userDetails?.uid;
        mintLicenseObject.createdAt = new Date().getTime();
      }
      // Create new License that needs to be minted
      mintLicenseObject.paid = true;

      //make sure that this field exists
      mintLicenseObject.archived = false;
      // delete archive license
      delete mintLicenseObject.archived;

      // Mint license and get txn
      const txn = await mintLicense(publicAddress, mintLicenseObject.uid, mintLicenseObject.licenseUid, mintLicenseObject.durationInDays);
      // if (txn === null) {
      //   toast.error("License failed to mint.");
      //   // navigate(-1);
      //   // throw new Error()
      // }

      mintLicenseObject.txnHash = txn;

      const batch = writeBatch(db);

      const requestRef = doc(db, 'license_requests', licenseRequest?.uid)
      const mintLicenseRef = doc(db, "user_licenses", mintLicenseObject.uid)

      batch.set(mintLicenseRef, mintLicenseObject);
      batch.update(requestRef, {state: txn ? LicenseRequestType.MINTED : LicenseRequestType.PAID, paid: true, txnHash: txn});

      await batch.commit();
    } catch (e) {
      const requestRef = doc(db, 'license_requests', licenseRequest?.uid);
      await updateDoc(requestRef, {state: LicenseRequestType.FAILED});
    }
  }

  const message = {
    [LicenseRequestType.ACCEPTED]: "Waiting for response...",
    [LicenseRequestType.PAID]: "Payment was successfully",
    // [LicenseRequestType.MINTED]: "Your license was minted successfully",
  }[licenseRequest.state]

  return (
    <>
      {licenseRequest === null ? <LoadingScreen/> : null}

      {message && <MintingLoadingScreen
        message={message}
      />}

      {
        licenseRequest?.state === LicenseRequestType.MINTED && licenseRequest?.txnHash &&
        <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
               justifyContent={'center'} alignContent={'center'}
               sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
          <AnimationSuccess open />
          <Typography variant={"h3"}>
            License has been minted!
          </Typography>
          <Typography sx={{color: grey[600]}}>View your NFT on the blockchain at</Typography>
          <Typography>
            <a href={"https://polygonscan.com/tx/" + licenseRequest?.txnHash} target={'_blank'}>
              Polygonscan.com
            </a>
          </Typography>
          <Typography sx={{color: grey[600]}}>
            By timestamping your work as an NFT, you've taken a proactive step to safeguard your creative
            work, helping to protect against copyright infringement in the future. 🔥💪
          </Typography>

          <Button
            component={Link}
            to={"/portfolio"}
            className={"sign-button"}
            endIcon={<IconChevronRight/>}
            sx={{textTransform: "none", px: 2, borderRadius: 3}}
          >
            View this NFT in your portfolio
          </Button>
        </Stack>
      }
      {
        licenseRequest?.state === LicenseRequestType.FAILED &&
        <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
               justifyContent={'center'} alignContent={'center'}
               sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
          <AnimationFailed open />

          <Typography variant={"h3"}>
            License has failed to mint!
          </Typography>

          <Button
            component={Link}
            to={"/portfolio"}
            className={"sign-button"}
            endIcon={<IconChevronRight/>} size={"large"}
            sx={{textTransform: "none", px: 3, borderRadius: 3}}
          >
            View this work in your portfolio and retry minting
          </Button>
        </Stack>
      }
    </>
  )
}