import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {PreBuiltTemplates} from "./PreBuiltTemplates";
import {RightsCheckboxes} from "./RightsCheckboxes";
import {CompensationStructure} from "./CompensationStructure";
import {CountryInfo} from "../../register-work/components/CountryInfo";
import Box from "@mui/material/Box";
import {TerminationEvents} from "./TerminationEvents";
import {LicenseType} from "../../../utils/enums/LicenseType";
import {NotMonetizeTemplates} from "./NotMonetizeTemplates";

export const MonetizeWorkForm = ({license, onUpdate, onSelectTemplate}) => {

  return (
    <>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography>
          Already have a license you want to duplicate?
        </Typography>
        <Button className={"text-purple"} sx={{p: 0}}>Click here to duplicate it.</Button>
      </Stack>
      <PreBuiltTemplates license={license} onSelectTemplate={onSelectTemplate}/>
      {
        license?.type === LicenseType.MONETIZED ?
          <>
            <RightsCheckboxes licenses={license?.licenses} onUpdate={onUpdate}/>
            <Box maxWidth={"md"}>
              <CompensationStructure license={license} onUpdate={onUpdate}/>
            </Box>
            <Box maxWidth={"md"}>
              <CountryInfo
                isRequired = {true}
                primaryTitle={"Where are you located?"}
                selectedCountry={license?.location}
                updateCountry={(country) => onUpdate("location", country)}
                selectedState={license?.state}
                updateState={(state) => onUpdate("state", state)}
                showState={true}
              />
            </Box>

            <Box maxWidth={"md"}>
              <TerminationEvents license={license} onUpdate={onUpdate} />
            </Box>
          </> :
          <>
            <NotMonetizeTemplates license={license} />
          </>
      }

    </>
  )
}