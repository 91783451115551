import {Button, Grid, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {useAtomValue} from "jotai";
import {LicenseTemplatesAtom} from "../../../atoms";

export const PreBuiltTemplates = ({license, onSelectTemplate}) => {
  const [showMore, setShowMore] = useState(false);
  const rights = useAtomValue(LicenseTemplatesAtom);

  const checkIsSelected = (first, second) => {
    if (Object.keys(first).length !== Object.keys(second).length) {
      return false;
    }
    for (const key in first) {
      if (second[key] === undefined || first[key] !== second[key]) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <Typography color={grey[500]} fontWeight={600}>Select a pre-built template below:</Typography>
      <Grid container spacing={3} sx={{pb: 2}}>
        {rights?.filter(item => license?.type === item.type)?.sort((a,b) => b.title.localeCompare(a.title))?.slice(0, showMore ? rights?.length : 3)?.map(template => {

          const isActive = checkIsSelected(template.rights || {}, license?.licenses === undefined ? {} : license?.licenses)
          return (
            <Grid key={template?.uid} item xs={12} sm={6} md={4} sx={{display: "flex"}}>
              <Stack direction={"column"} gap={1} className={`protection-cart ${isActive ? "active" : ""}`}
                     onClick={() => onSelectTemplate(template)}
                     sx={{borderRadius: 5, cursor: "pointer", p: 2, border: 1, borderColor: "divider", flex: 1}}>
                <Box>
                  <Typography variant={"h6"} fontWeight={700}>{template?.title}</Typography>
                  {template?.shortName && <Typography variant={"subtitle2"} fontWeight={600}>{template?.shortName}</Typography>}
                </Box>
                <Typography variant={"subtitle2"} fontWeight={400}>{template?.description}</Typography>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{mx: 'auto'}}>
        <Button className={"text-purple"}
                onClick={() => setShowMore(!showMore)}>{showMore ? "Show Less templates" : "Show more templates"}</Button>
      </Box>
    </>
  )
}