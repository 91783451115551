import {useAtomValue} from "jotai";
import {LicenseRequestViewAtom} from "../../atoms";
import {LicenseRequestType} from "../../utils/enums/LicenseRequestType";
import {ViewLicense} from "./ViewLicense";
import {StripeEmbeddedForm} from "../stripe/StripeEmbeddedForm";
import {LicenseRequestDeclined} from "./LicenseRequestDeclined";
import {LicenseRequestFailed} from "./LicenseRequestFailed";
import MintingLoadingScreen from "../lottie-animations/MintingLoadingScreen";
import Box from "@mui/material/Box";
import {LicenseRequestPurchased} from "./LicenseRequestPurchased";
import {StripeProductTypes} from "../../utils/enums/StripeProductTypes";


export const LicenseSend = () => {
  const licenseRequest = useAtomValue(LicenseRequestViewAtom);

  return (
    <>
      {
        {
          [LicenseRequestType.PENDING]: <ViewLicense/>,
          [LicenseRequestType.ACCEPTED]: <StripeEmbeddedForm nftUid={licenseRequest?.nftUid} publicAddress={licenseRequest?.nftUid}
                                                             productType={StripeProductTypes.LICENSE} allChecksPassed={true}
                                                             setStatus={(status) => console.log(status)} />,
          [LicenseRequestType.DECLINED]: <LicenseRequestDeclined/>,
          [LicenseRequestType.PAID]: <Box sx={{position: "relative", minHeight: "50dvh"}}><MintingLoadingScreen message={"Your license is minting on blockchain"} /></Box>,
          [LicenseRequestType.MINTED]: <LicenseRequestPurchased/>,
          [LicenseRequestType.FAILED]: <LicenseRequestFailed/>,
        }[licenseRequest.state]
      }
    </>
  )
}