import {atom} from "jotai";

export const WalletInstructionsAtom = atom(false);
export const LicenseRequestViewAtom = atom(null);
export const RequestLicenseModalAtom = atom(null);

export const ContactFormAtom = atom({
  open: false,
  title: "",
  message: ""
})

export const BrowserTypeAtom = atom('None');

export const LicenseTemplatesAtom = atom([]);
export const CreateLicenseAtom = atom([]);
