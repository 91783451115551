import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {grey} from "@mui/material/colors";
import {NonExclusiveType} from "../../../../utils/enums/NonExclusiveType";
import React from "react";

export const TableView = ({nft}) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Title of work</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Description of work</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.description}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Public Title</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.publicTitle}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Public Description</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.publicDescription}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Geographic Area</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.location}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Category of work</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.projectTypes?.join(", ")}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Attributions</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem", textTransform: "capitalize"}}>
            {nft?.attributions}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
