export const CreativeCommonLicenseTemplates = {
  "CC0": {
    id: "CC0",
    title: "The Public Domain",
    description: "You want your work to be freely available to anyone for any reason.",
    link: "https://creativecommons.org/publicdomain/zero/1.0/",
    attributions: [
      {attribution: "Attribution Required", value: false},
      {attribution: "Copy & Publish", value: false},
      {attribution: "Commercial Use", value: false},
      {attribution: "Modify & Adapt", value: false},
      {attribution: "Change License Type", value: false},
    ]
  },
  "CC-BY": {
    id: "CC-BY",
    title: "The Anti-Plagiarist",
    description: "You just want credit. Otherwise, you want your work to be freely available to anyone for any reason. If your work inspires other people, they can license their adaptations as they see fit.",
    link: "https://creativecommons.org/licenses/by/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: true},
      {attribution: "Copy & Publish", value: true},
      {attribution: "Commercial Use", value: true},
      {attribution: "Modify & Adapt", value: true},
      {attribution: "Change License Type", value: true},
    ]
  },
  "CC-BY-SA": {
    id: "CC-BY-SA",
    title: "The Copyleft",
    description: "You want credit. Otherwise, you want your work to be freely available to anyone for any reason. If your work inspires other people, their adaptations have to be freely available to anyone for any reason.",
    link: "https://creativecommons.org/licenses/by-sa/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: false},
      {attribution: "Copy & Publish", value: false},
      {attribution: "Commercial Use", value: false},
      {attribution: "Modify & Adapt", value: false},
      {attribution: "Change License Type", value: false},
    ]
  },
  "CC-BY-ND": {
    id: "CC-BY-ND",
    title: "The One and Only",
    description: "You want credit. You want your work to be freely available, but other people shouldn't modify or adapt your work.",
    link: "https://creativecommons.org/licenses/by-nd/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: true},
      {attribution: "Copy & Publish", value: true},
      {attribution: "Commercial Use", value: true},
      {attribution: "Modify & Adapt", value: false},
      {attribution: "Change License Type", value: true},
    ]
  },
  "CC-BY-NC": {
    id: "CC-BY-NC",
    title: "The Anti-Materialist",
    description: "You want credit. Otherwise, you want your work to be freely available to anyone, but you don't want anyone to make money off of your work.",
    link: "https://creativecommons.org/licenses/by-nc/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: true},
      {attribution: "Copy & Publish", value: true},
      {attribution: "Commercial Use", value: false},
      {attribution: "Modify & Adapt", value: true},
      {attribution: "Change License Type", value: true},
    ]
  },
  "CC-BY-NC-SA": {
    id: "CC-BY-NC-SA",
    title: "The Anti-Materialist's Copyleft",
    description: "You want credit. Otherwise, you want your work to be freely available to anyone, but you don't want anyone to make money off of your work.  If your work inspires other people, their adaptations have to be freely available to anyone for any reason.",
    link: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: true},
      {attribution: "Copy & Publish", value: true},
      {attribution: "Commercial Use", value: false},
      {attribution: "Modify & Adapt", value: true},
      {attribution: "Change License Type", value: false},
    ]
  },
  "CC-BY-NC-ND": {
    id: "CC-BY-NC-ND",
    title: "The Anti-Materialist's One and Only",
    description: "You want credit. Otherwise, you want your work to be freely available to anyone. However, you don't want anyone to make money off of your work, nor should they modify or adapt your work.",
    link: "https://creativecommons.org/licenses/by-nc-nd/4.0/",
    attributions: [
      {attribution: "Attribution Required", value: true},
      {attribution: "Copy & Publish", value: true},
      {attribution: "Commercial Use", value: false},
      {attribution: "Modify & Adapt", value: false},
      {attribution: "Change License Type", value: true},
    ]
  },
}