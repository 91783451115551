import {useAtom} from "jotai/index";
import {LicenseRequestViewAtom} from "../../atoms";
import React, {useEffect, useState} from "react";
import {doc, getDoc, updateDoc, writeBatch} from "firebase/firestore";
import {db} from "../../config/firebase";
import {LicenseRequestType} from "../../utils/enums/LicenseRequestType";
import {toast} from "react-toastify";
import {Button, CircularProgress, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {MintBanner} from "../banner/MintBanner";
import {grey} from "@mui/material/colors";
import {ViewLicenseDetails} from "./ViewLicenseDetails";

export const ViewLicense = () => {
  const [licenseRequest, setLicenseRequest] = useAtom(LicenseRequestViewAtom);
  const [publicAddress, setPublicAddress] = useState(null);
  const [nft, setNft] = useState(null);
  const [license, setLicense] = useState(null);
  const [openViewLicense, setOpenViewLicense] = useState(false);

  useEffect(() => {
    const fetchLicense = async () => {
      const docRef = doc(db, "licenses", licenseRequest?.licenseUid);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        setLicense(docSnap.data());
      }
    }
    const fetchNft = async () => {
      const docRef = doc(db, "royalty_templates", licenseRequest?.nftUid);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        setNft(docSnap.data());
      }
    }

    if (licenseRequest?.nftUid && licenseRequest?.licenseUid) {
      fetchLicense();
      fetchNft()
    }
  }, [licenseRequest])

  const handleClose = () => setLicenseRequest(null)

  const onDecline = async () => {
    try {
      if (licenseRequest) {
        const licenseRequestRef = doc(db, 'license_requests', licenseRequest?.uid)
        await updateDoc(licenseRequestRef, {
          state: LicenseRequestType.DECLINED,
        })
        setLicenseRequest(prev => ({...prev, state: LicenseRequestType.DECLINED}))
        handleClose();
      }

    } catch (e) {
      console.log(e);
      toast.error(e?.message || "Failed to decline license request")
    }
  }

  const onAccept = async () => {
    try {
      if (licenseRequest) {
        const licenseRequestRef = doc(db, 'license_requests', licenseRequest?.uid)
        const batch = writeBatch(db);

        batch.update(licenseRequestRef, {state: LicenseRequestType.ACCEPTED});
        setLicenseRequest(prev => ({...prev, state: LicenseRequestType.ACCEPTED}))

        await batch.commit();
      }

    } catch (e) {
      console.log(e);
      toast.error(e?.message || "Failed to decline license request")
    }
  }

  const from = licenseRequest?.firstName || licenseRequest?.lastName ? `${licenseRequest?.firstName} ${licenseRequest?.lastName}`?.trim() : licenseRequest?.email;
  console.log(nft)
  return (
    <Stack direction={"column"} gap={2} sx={{position: "relative", maxHeight: "90%", overflowY: "auto"}}>
      <ViewLicenseDetails
        open={openViewLicense && !!nft && !!license}
        handleClose={() => setOpenViewLicense(false)}
        nft={nft} license={license}
      />
      <Typography variant={"h2"} fontWeight={700} align={"center"} gutterBottom>Accept License</Typography>

      <Box maxWidth={'sm'} sx={{mx: "auto", width: {xs: "80%", md: "50%"}, textAlign: "center", py: 1}}>
        {nft === null ? <CircularProgress/> : <img src={nft?.publicImage} alt={"Nft"} width={"100%"} height={"auto"} style={{maxHeight: "150px", objectFit: "contain"}}/>}
      </Box>
      <Typography fontWeight={400} maxWidth={"sm"} sx={{mx: "auto"}} className={"text-grey"} align={"center"}>
        Accept the license for <b>{nft?.title}</b> from <b>{from}</b>.
        Once you accept this license, it will appear in your portfolio.
      </Typography>
      <Box>
        <Button className={"gradient-text"} onClick={() => setOpenViewLicense(true)} sx={{px: 2, borderRadius: 3}}>
          View license details
        </Button>
      </Box>

      <Box sx={{textAlign: "left"}}>
        <MintBanner setAccount={(addr) => setPublicAddress(addr)}/>
      </Box>

      <Typography fontWeight={400} className={"text-grey"} align={"left"}>
        <Typography component={"b"} fontWeight={700} className={"gradient-text"}>Important:</Typography> Ensure that the licensor has all
        necessary rights and permissions to license this work. Although C3 requires users to affirmatively state that
        their work is original, bad actors can still make misrepresentations.
      </Typography>
      <Typography fontWeight={400} className={"text-grey"} align={"left"}>
        Regarding any licensing agreement you enter into, whether through C3 or otherwise,
        <Typography component={"b"} fontWeight={700}>you are responsible for paying any compensation via
          external means</Typography> not provided by C3,
        though C3 may provide the option for on-platform payment at a future date.
        The consequences for failure to pay compensation pursuant to your agreements
        may include but are not limited to account termination and civil liability.
      </Typography>
      <Stack direction={"row"} gap={2} fullWidth>
        <Button
          onClick={onDecline}
          sx={{
            py: 1, px: 2, flex: 1,
            border: 1, borderColor: "divider",
            borderRadius: 2,
            bgcolor: grey[100], color: grey[700],
            "&:hover": {
              bgcolor: grey[200], color: grey[800],
            }
          }}
        >
          Decline
        </Button>
        <Button className={"sign-button"} onClick={onAccept} disabled={!publicAddress} sx={{flex: 1, borderRadius: 2}}>
          Accept
        </Button>
      </Stack>
    </Stack>
  )
}