import {Stack, Typography} from "@mui/material";
import {useAtomValue} from "jotai/index";
import {LicenseRequestViewAtom} from "../../atoms";
import React from "react";
import AnimationSuccess from "../lottie-animations/AnimationSuccess";

export const LicenseRequestAccepted = () => {
  const licenseRequest = useAtomValue(LicenseRequestViewAtom);

  const from = licenseRequest?.firstName || licenseRequest?.lastName ? `${licenseRequest?.firstName} ${licenseRequest?.lastName}`?.trim() : licenseRequest?.email;

  return (
    <Stack direction={"column"} gap={2}>
      <Typography variant={"h2"} fontWeight={700} align={"center"} gutterBottom>License Accepted!</Typography>
      <AnimationSuccess />
      <Typography fontWeight={400} maxWidth={"sm"} sx={{mx: "auto"}} className={"text-grey"} align={"center"}>
        You have accepted license request from {from}. Please create license and send to the client <Typography component={"b"} fontWeight={700}>{licenseRequest?.email}</Typography>.
      </Typography>
    </Stack>
  )
}