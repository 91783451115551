import React, {useState} from "react";
import {Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Stack, Typography} from "@mui/material";
import {RightLicenseType} from "../../../utils/enums/RightLicenseType";

export const rights = [
  {
    type: RightLicenseType.REPRODUCING,
    name: "Reproducing",
    description: "This allows people to make copies of your work.",
    explainer: "The right to reproduce applies to all copyrightable works, including literature, music, visual art, sound recordings, audiovisual works, and performing arts. This right entails the exclusive ability to make copies of the original work, whether by printing a book, copying a digital file, or duplicating a painting. It covers both direct reproduction and indirect reproduction, such as scanning a physical photograph to create a digital version. By controlling reproduction, the copyright holder can manage how and where their work is copied, preventing unauthorized duplication. This right is fundamental to protecting the creator’s investment in their work. Without this right, anyone could easily replicate and distribute copies of the work, undermining the creator’s control and potential revenue.",
    child: []
  },
  {
    type: RightLicenseType.DERIVATIVE_WORKS,
    name: "Preparing Derivative Works.",
    description: "This allows people to make spin-offs of your work.",
    explainer: "The right to prepare derivative works applies to all forms of copyrightable material, such as literary works, music, visual arts, sound recordings, and audiovisual works. This right allows the creator to authorize adaptations or transformations of their original work, like turning a book into a movie, a painting into a print series, or a song into a remix. It includes creating works that are based on or derived from the original, ensuring the creator can control and benefit from these new versions. By managing derivative works, the creator maintains the integrity and intended expression of the original. This right also helps protect against unauthorized adaptations that could distort or misrepresent the original work. It is essential for creators who want to explore multiple expressions of their work across different media.",
    child: []
  },
  {
    type: RightLicenseType.DISTRIBUTING_COPIES,
    name: "Distributing Copies",
    description: "This allows people to sell or give away copies of your work.",
    explainer: "The right to distribute copies is applicable to all copyrighted works, including books, music, visual art, sound recordings, audiovisual materials, and performing arts. This right gives the creator control over the sale, transfer, or rental of copies of their work. It allows them to decide how and where their work is made available to the public, whether through physical copies like books and CDs or digital formats like e-books and MP3s. By controlling distribution, the creator can manage the market for their work and ensure they receive proper compensation. Unauthorized distribution can dilute the market value and infringe on the creator’s ability to profit from their work. This right is crucial for maintaining economic incentives for creativity and innovation.",
    child: []
  },
  {
    type: RightLicenseType.SUBLICENSING,
    name: "Sublicensing",
    description: "This allows people to license the rights that you provide them to other people.",
    explainer: "The right to sublicense applies to all types of copyrighted works, including literary, musical, visual, and audiovisual works, as well as sound recordings and performing arts. This right allows the creator to grant permission to third parties to use their work under the terms of the original license. Sublicensing enables creators to expand the reach of their work while maintaining control over its use and distribution. It can be particularly useful for creators who want to collaborate with others or enter new markets without directly managing every licensing agreement. Unauthorized sublicensing can lead to unapproved uses and potential legal disputes. By controlling sublicensing, creators can ensure their work is used in ways that align with their intentions and agreements.",
    child: []
  },
  {
    name: "For Literary, Musical Compositions, Dramatic, Choreographic Works",
    description: "Only for literary, musical, dramatic, choregraphic, pantomime, motion picture, and other audiovisual works.",
    child: [
      {
        type: RightLicenseType.PERFORMING_PUBLICLY,
        name: "Performing publicly",
        description: "This allows people to perform your work live to the public.",
        explainer: "The right to perform publicly applies primarily to literary, musical, and dramatic works, as well as audiovisual works and certain choreographic works. This right allows the creator to control live or recorded performances of their work in public venues, such as theaters, concert halls, and broadcast media. It ensures that performances are authorized and that the creator receives appropriate recognition and compensation. Public performance rights are particularly important for playwrights, musicians, and filmmakers whose works are often experienced in communal settings. Unauthorized public performances can undermine the creator’s control and financial returns. By managing public performances, creators can sustain their careers and continue to produce new works.",
        child: []
      },
      {
        type: RightLicenseType.DISPLAYING_PUBLICLY,
        name: "Displaying publicly",
        description: "This allows people to display your work to the public.",
        explainer: "The right to display publicly is relevant to visual arts, such as paintings, sculptures, photographs, and certain audiovisual works. This right gives the creator control over how their work is shown in public spaces, including museums, galleries, and online platforms. It ensures that the work is displayed in a manner consistent with the creator’s intentions and that they are credited appropriately. Public display rights are crucial for artists who rely on exhibitions to gain exposure and sell their work. Unauthorized displays can misrepresent the artist’s work and diminish its value. By regulating public displays, creators can protect their reputation and artistic vision.",
        child: []
      },
    ]
  },
  {
    name: "For Sound Recordings Only",
    description: "",
    child: [
      {
        type: RightLicenseType.AUDIO_PERFORMING_PUBLICLY,
        name: "Perform publicly via digital audio transmission.",
        description: "This allows people to play your song to the public via the radio, Spotify, or similar method.",
        explainer: "The right to (audio) perform publicly is specific to sound recordings, including music tracks, spoken word, and other audio content. This right allows the creator to control how their recordings are played in public venues, such as radio stations, clubs, and streaming services. It ensures that the creator is compensated whenever their work is publicly broadcast or performed. This right is essential for musicians and producers who rely on public performances for a significant portion of their income. Unauthorized audio performances can lead to lost revenue and diminished control over the work. By managing public audio performances, creators can ensure their work is used in ways that support their financial and artistic goals.",
        child: []
      },
    ]
  },
]

export const RightsCheckboxes = ({licenses, onUpdate}) => {
  console.log(licenses);

  const onChange = (key, checked) => {
    const newLicenses = {
      ...licenses,
      [key]: checked
    }
    const item = rights.find(i => i.type === key);
    if (item) {
      for (const child of item.child) {
        newLicenses[child.type] = checked;
      }
    }
    onUpdate("licenses", newLicenses);
  }

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "1.25rem",
          color: "#2D2D30",
          pb: 1
        }}
      >
        What rights are being licensed?
      </Typography>
      <Stack direction={"column"} gap={2}>
        {
          rights?.map((item) => {
            const items = item?.child?.map(i => {
              return {
                checked: licenses[i.type],
                label: i.name,
                description: i.description,
                explainer: i.explainer,
                items: [],
                value: i.type
              }
            }) || [];
            var isChecked = item?.type ? licenses[item?.type] : false;
            if (items.length) {
              isChecked = items?.every(item => item.checked);
            }
            return <LabeledCheckbox
              checked={isChecked}
              label={item.name}
              description={item.description}
              explainer={item?.explainer || null}
              items={items}
              value={item.type || null}
              onChange={onChange}
            />
          })
        }
      </Stack>
    </Box>
  )
}

export const LabeledCheckbox = ({checked, label, description, explainer, items, value, onChange}) => {
  const [open, setOpen] = useState(false);
  const [learnMore, setLearnMore] = useState(false);

  return (
    <>
      <FormControlLabel
        key={label}
        control={(
          <Checkbox
            checked={checked} size={"medium"}
            sx={{mb: "auto", p: 0.5, mr: 1}}
            onChange={() => {
              if (value !== null) {
                onChange(value, !checked);
              } else {
                setOpen(!open)
              }
            }} color={"secondary"}
          />
        )}
        label={
          <Stack direction={"column"}>
            <Typography color={"#2D2D30"} variant={"subtitle1"} fontWeight={500}>{label}</Typography>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Typography color={"#949494"} variant={"subtitle2"} fontWeight={400}>{description}</Typography>
              {explainer &&
                <Button className={"gradient-text"} onClick={() => setLearnMore(prev => !prev)}>
                  {learnMore ? "Hide Explainer" : "Learn More"}
                </Button>
              }
            </Stack>
            {explainer && learnMore &&
              <Typography color={"#949494"} variant={"subtitle2"} fontWeight={400}>{explainer}</Typography>
            }
          </Stack>
        }
      />
      {open && <Fade in timeout={400}>
        <Box sx={{pl: 2, display: "flex", flexDirection: "column", gap: 2}} fullWidth>
          {items?.map(subItem => {
            console.log(subItem);
            return <LabeledCheckbox
              key={subItem.value}
              checked={!!subItem.checked}
              label={subItem.label}
              description={subItem.description}
              explainer={subItem.explainer}
              items={[]}
              value={subItem.value}
              onChange={onChange}
            />
          })}
        </Box>
      </Fade>
      }
    </>
  )
}