import {Avatar, Skeleton, Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CardRounded} from "../../../components/card/CardRounded";
import {BadgeBox} from "../../../components/card/BadgeBox";
import {usePrismicDocumentByID} from "@prismicio/react";

export const WhyLicenseIPCard = () => {
  const navigate = useNavigate();
  const id = "ZnmY9REAACAA0uAN";
  const [article] = usePrismicDocumentByID(id);

  return (
    article ? <CardRounded
      sx={{
        backgroundImage: `url(${article?.data?.cover_image?.url}) !important`,
        p: 4, cursor: "pointer", position: "relative",
      }}
      onClick={() => navigate(`/blog/${id}`)}
      className={"pen-and-paper-bg"}
    >
      <Typography variant={"h3"} maxWidth={"400px"} fontWeight={600} align={"left"} sx={{mr: "auto"}}>{article?.data?.title[0]?.text || ""}</Typography>
      <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} sx={{p: 1}}>
        <Avatar sx={{bgcolor: "rgba(0,0,0, 0.1)", backdropFilter: "blur(2px)", border: 0.5, borderColor: "#fefefe", color: "#fefefe", p: 0.5}} />
        <div>
          <Typography variant={"h6"} fontWeight={500} fontSize={"1.1rem"} align={'left'}>{article?.data?.author_name[0]?.text}</Typography>
          <Typography variant={"subtitle2"} fontWeight={400} color={'lightgrey'} align={'left'}>{article?.data?.category[0]?.text || ""}</Typography>
        </div>
      </Stack>
      <BadgeBox sx={{position: "absolute", bottom: 4 * 8, right: 4 * 8}}>
        {article?.data?.read_time[0]?.text || "4 min"}
      </BadgeBox>
    </CardRounded> : <Skeleton variant="rectangular" width={"100%"} height={"35dvh"} sx={{borderRadius: 5, p: 4}} />
  )
}