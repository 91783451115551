import {Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {collection, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import {LicenseRequestType} from "../../utils/enums/LicenseRequestType";
import moment from "moment/moment";
import {IconAlertCircle} from "@tabler/icons-react";
import {Link} from "react-router-dom";

export const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([])
  const {state: {userDetails}} = useContext(AppContext);

  useEffect(() => {
    const fetchNotifications = () => {
      let unsubscribe = null;
      try {
        const notificationsQuery = query(
          collection(db, "license_requests"),
          where("recipientMail", "==", userDetails?.email),
          orderBy("createdAt", "desc")
        );
        unsubscribe = onSnapshot(notificationsQuery, (querySnapshot) => {
          let notifications = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            notifications.push(data);
          });
          setNotifications(notifications);
        });
      } catch (error) {
        console.log(error);
      }
      return unsubscribe;
    }
    if (userDetails && userDetails?.email) {
      const unsubscribe = fetchNotifications();
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [userDetails])

  return (
    <Stack direction={"column"} gap={2} maxWidth={"lg"} alignItems={"flex-start"}
           sx={{mx: {xs: 2, md: 'auto'}, width: "100%"}}>
      <Typography variant={"h2"} fontWeight={700}>Notifications</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>User</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Date</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Expiration</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Type</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Message</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            notifications?.length === 0 ?
              <TableRow>
                <TableCell colSpan={6}>{"You don't have any notifications"}</TableCell>
              </TableRow>
              : null
          }
          {
            notifications?.map(notification => {
              const message = {
                [LicenseRequestType.PENDING]: `${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} has sent you license.`,
                [LicenseRequestType.ACCEPTED]: `You have accepted license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                [LicenseRequestType.DECLINED]: `You have declined license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                [LicenseRequestType.PAID]: `The payment was successfully for license ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email}.`,
                [LicenseRequestType.MINTED]: `Your license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} is minted.`,
                [LicenseRequestType.FAILED]: `Your license from ${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} failed in some proccess please contact us.`,
              }[notification.state];
              const isExpired = notification.expirationDate < new Date().getTime();

              return (
                <TableRow key={notification?.uid}>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>{notification?.email}</TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>
                    <Tooltip title={"Date license request is created"}>
                      <Typography sx={{whiteSpace: "nowrap"}}>{moment(notification.createdAt).format("DD MMM YYYY")}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>
                    <Tooltip title={isExpired ? "License expired" : "Expiration date"}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <IconAlertCircle size={16} className={"text-grey"}/>
                        <Typography sx={{whiteSpace: "nowrap"}}>{moment(notification.expirationDate).format("DD MMM YYYY")}</Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={"text-grey"}
                    sx={{
                      fontWeight: 400,
                      textTransform: "capitalize"
                    }}
                  >
                    <Typography sx={{whiteSpace: "nowrap"}}>{notification?.type?.toLowerCase()?.replaceAll("_", " ")}</Typography>
                  </TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>{message}</TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>
                    <Button
                      className={"sign-button"}
                      sx={{px: 2, borderRadius: 5, whiteSpace: "nowrap"}} disabled={isExpired}
                      component={Link} to={`/notifications/${notification?.uid}`}
                    >
                      View License
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </Stack>
  )
}