import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {NavLink} from "react-router-dom";
import React from "react";

export const SidebarItem = ({path, title, icon, handleClose}) => {

  return (
    <ListItemButton
      component={NavLink} to={path}
      className={"text-grey nav-item-btn"}
      sx={{
        py: 1.5, borderRadius: 4, my: 0.5, minWidth: "0 !important",
        "&.active": {background: "rgba(0,0,0,0.04) !important"},
        "&.active > *": {color: "#D55380"}
      }}
      onClick={handleClose}
    >
      <ListItemIcon sx={{minWidth: "0 !important"}}>{icon}</ListItemIcon>
      <ListItemText primary={title} sx={{pl: 1.5}} className={"nav-item-text"} primaryTypographyProps={{sx: {fontSize: 16}}}/>
    </ListItemButton>
  )
}