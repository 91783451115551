import React from "react";
import {Box, Divider, Stack, Typography} from "@mui/material";

export const ImageCard = () => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "18rem",
                height: "28rem",
                flexShrink: 0,
                boxShadow: 3,
                bgcolor: "#FBFBFB",
                border: 1,
                borderColor: "#EDEDED",
                borderRadius: "1.25rem",
                p: 0.5
            }}
        >
            <img
                src={"https://s3-alpha-sig.figma.com/img/8763/2330/0e931f7ba932854e19b4e6c78904aa58?Expires=1695600000&Signature=X7Z7F2Z3jws15ENkgkOyEjQOlIqjjDq7A4e01sWKHMJSMPVcuOvstJCgznJfsIgrRDIQt4M9mbENirKvFtaACWQhJRqMMGqoeJ-5mK6Rst4STBlzX1~q1VbVtE4xX4TfFtI2mz4r14i7OVKGcK-LtS8YTQgUsMNSkMW0gNUPKzHEp9wfC-S74YRYGeefVliFB2LDn20eSlkO9P6afoeutne6326XIkDQTAPCYKJE3dYSaXzlt5cf8iaQtwtIRjxYRH1c7fOVkBMqNGKP5vnfUI6klEyDzyJZ0wC-c44cgOVkntCmsVlEzrWScyN6LMyH9H53btWN8Q6SYrO~MF-kpA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                width={"100%"}
                height={"auto"}
                style={{
                    borderRadius: "inherit"
                }}
            />

            <Box sx={{ py: 1}}>
                <Typography
                    sx={{
                        fontSize: "1.3rem",
                        fontWeight: 700,
                        color: "#2D2D30"
                    }}
                >
                    Pirate Bird #43
                </Typography>
                <Typography
                    sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#898989"
                    }}
                >
                    Pirate Bird #43
                </Typography>
            </Box>
            <Divider />

            <Stack direction={"row"} gap={1} justifyContent={"space-between"} sx={{py: 1}}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: "#898989"
                        }}
                    >
                        Price
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            color: "#2D2D30"
                        }}
                    >
                        1.38 ETH
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: "#898989"
                        }}
                    >
                        Highest Bid
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            color: "#2D2D30"
                        }}
                    >
                        2 ETH
                    </Typography>
                </Box>
            </Stack>
        </Box>
    )
}