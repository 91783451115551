import {Box, FormControlLabel, Stack, Typography} from "@mui/material";
import {IOSSwitch} from "../../../components/switch/Switch";
import React from "react";

export const ExplicitContent = ({registerWorkForm, onUpdate}) => {
  return (
    <>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Box sx={{flex: 1}}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              color: "#2D2D30",
            }}
          >
            Explicit and sensitive content
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "0.85rem",
              color: "#898989",
            }}
          >
            Set this item as explicit and sensitive content.
          </Typography>
        </Box>
        <FormControlLabel
          control={<IOSSwitch
            checked={registerWorkForm?.explicitContent}
            onChange={(e) => onUpdate("explicitContent", e.target.checked)}
          />}
        />
      </Stack>
    </>
  )
}