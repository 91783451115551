import Modal from "@mui/material/Modal";
import {Stack, Typography} from "@mui/material";
import logo from "../../../../assets/img/logo-img.png";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../../config/firebase";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "40vw", maxWidth: "80vw",
  maxHeight: "90vh",
  bgcolor: 'background.paper',
  borderRadius: 5,
  border: "0 !important",
  boxShadow: 24, p: {xs: 2, md: 5},
  overflowY: "auto"
};

export const PrintLicenseModal = ({open, handleClose, nft, license}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const docRef = doc(db, "users", nft?.userUid);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        const userDetails = {...docSnap?.data()}
        if (userDetails?.firstName || userDetails?.lastName) {
          userDetails.displayName = `${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`?.trim();
        }
        setUser(userDetails);
      }
    }

    if (open && nft?.userUid) {
      fetchUser();
    } else {
      setUser(null)
    }

  }, [nft, open])

  useEffect(() => {
    if (open && nft && user) {
      window.print();
    }
  }, [open, nft, user])

  function replaceLastCommaWithAnd(names = "") {
    // Find the position of the last comma in the string
    const lastCommaIndex = names?.lastIndexOf(',');
    if (lastCommaIndex === -1) {
      return names;  // Return the original string if no comma is found
    }

    // Replace the last comma with " and"
    return names.substring(0, lastCommaIndex) + ' and' + names.substring(lastCommaIndex + 1).replace(/\s+/g, ' ');
  }

  let licenseNames = replaceLastCommaWithAnd(license?.signees?.map(sign => `${sign?.firstName} ${sign?.lastName}`)?.join(", "))

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack id={"print-license-modal"} direction={"column"} gap={2} sx={style} alignItems={"center"}>
        <img
          src={logo} alt={"Logo"}
          height={"50"} width={"auto"}
        />
        <Stack direction={"column"} gap={2} fullWidth sx={{textAlign: "left", width: "100%"}}>
          <Typography fullWidth>
            This Copyright License Agreement (the “Agreement”) is effective as
            of {moment(license?.createdAt).format("DD/MM/YYYY")},
            and the Agreement is entered into by and between the following parties: {licenseNames}.
            The parties agree that where this Agreement and the license listed on blockchain at {nft?.nftTxnHash} differ
            regarding the terms between Licensor and Licensee,
            the license listed on blockchain controls. As between the parties and C3, this Agreement controls.
          </Typography>
          <Typography fullWidth>
            In this Agreement, the party granting the right to use the licensed property,
            [Full Name 1], will be referred to as “Licensor,” and the party receiving the right to use licensed
            property,
            [Full Name 2] will be referred to as “Licensee.”
            The parties agree that any payments made pursuant to this agreement will be made via external services, e.g.
            Stripe,
            unless and until the C3 platform can support payments internally.
            When the C3 platform can support payments internally, the C3 platform may function as an alternative mode of
            payment.
          </Typography>
          <Typography fullWidth>
            Licensor owns and retains all present proprietary rights in the works described in this agreement except
            those rights that this Agreement expressly transfers. Licensor’s rights include those arising from common
            law copyrights and federal copyrights which may have been or which may be granted by the U.S. Copyright
            Office. The works being licensed will be referred to as the “Work,” consisting of:
          </Typography>
          <div>
            <Typography fullWidth align={"left"} fontWeight={600}>{nft?.title}</Typography>
            <Typography fullWidth align={"left"} fontWeight={600}>{nft?.description}</Typography>
          </div>
          <Typography fullWidth align={"left"}>The parties agree to abide by the following terms:</Typography>

          <ol type={"I"}>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Rights Being Licensed</Typography>
              <Typography fullWidth align={"left"} gutterBottom>
                Licensor agrees that this Agreement provides Licensee with the following rights to the Work:
                <Typography component={"span"} style={{textTransform: "capitalize"}}>{Object.keys(license?.licenses)?.filter(key => license?.licenses[key])?.map(key => key?.replaceAll("_", " ")?.toLowerCase()).join(", ")}</Typography>
              </Typography>
            </li>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Scope and limitations</Typography>
              <Typography fullWidth align={"left"} gutterBottom>The license granted by this Agreement only applies in the following geographical area:</Typography>
              <Typography fullWidth align={"left"} gutterBottom>{license?.location}, {license?.state}</Typography>
              <Typography fullWidth align={"left"} gutterBottom>
                The license granted by this agreement will continue until [Date],
                at which point this license will expire and all of Licensee’s rights will terminate.
                The license granted by this Agreement is <Typography component={"span"} style={{textTransform: "capitalize"}}>{license?.exclusiveType?.toLowerCase()}</Typography>.
              </Typography>
            </li>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Termination Events</Typography>
              <Typography fullWidth align={"left"} gutterBottom>Licensee agrees that failure to act in accordance with the following conditions constitutes breach of this Agreement and terminates all of Licensee’s rights under this Agreement:</Typography>
              <Typography fullWidth align={"left"} gutterBottom textTransform={"capitalize"}>{license?.terminationEvents?.join(", ")}</Typography>
              <Typography fullWidth align={"left"} gutterBottom textTransform={"capitalize"}>Also, the following other limitations apply:</Typography>
              <Typography fullWidth align={"left"} gutterBottom textTransform={"capitalize"}>{license?.limitations || "-"}</Typography>
            </li>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Payment</Typography>
              <Typography fullWidth align={"left"} gutterBottom>Licensee agrees to compensate Licensor {license?.price || license?.percent || "Not monetized"}{license?.price ? " $" : license?.percent ? " %" : ""} for this license.</Typography>
            </li>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Arbitration</Typography>
              <Typography fullWidth align={"left"} gutterBottom>
                The parties agree that any dispute, controversy, or claim arising out of or relating to this contract, or the breach, termination, or invalidity thereof, may be settled by binding arbitration, conducted by the C3 Team at the C3 Team’s discretion. The parties agree that C3 reserves the right to take any action that may be necessary to protect parties’ rights or prevent the parties from taking unlawful action.
              </Typography>
            </li>
            <li style={{paddingLeft: "15px"}}>
              <Typography fullWidth align={"left"} gutterBottom>Severability</Typography>
              <Typography fullWidth align={"left"} gutterBottom>
                If any provision in this Agreement shall be held or rendered invalid or unenforceable, such as by court decision, statute, or rule, the remaining portions of this Agreement shall be severable and unaffected. The parties’ rights and obligations shall be construed and enforceable as if the Agreement did not contain the particular part of this Agreement that was held or rendered invalid or unenforceable.              </Typography>
            </li>
          </ol>
          {
            license?.signees?.map(sign => {
              return (
                <div key={sign?.email} style={{padding: '10px'}}>
                  {`${sign?.firstName} ${sign?.lastName}`}<br/>
                  Signature: <Typography component={"span"} style={{marginLeft: '15px'}}>________________</Typography>
                </div>
              )
            })
          }
        </Stack>

      </Stack>
    </Modal>
  )
}