import Modal from "@mui/material/Modal";
import {Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import logo from "../../../../assets/img/logo-img.png";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../../config/firebase";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "40vw", maxWidth: "80vw",
  maxHeight: "90vh",
  bgcolor: 'background.paper',
  borderRadius: 5,
  border: "0 !important",
  boxShadow: 24, p: {xs: 2, md: 5}
};

export const PrintProofModal = ({open, handleClose, nft}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const docRef = doc(db, "users", nft?.userUid);
      const docSnap = await getDoc(docRef);

      if (docSnap?.exists()) {
        const userDetails = {...docSnap?.data()}
        if (userDetails?.firstName || userDetails?.lastName) {
          userDetails.displayName = `${userDetails?.firstName || ""} ${userDetails?.lastName || ""}`?.trim();
        }
        setUser(userDetails);
      }
    }

    if (open && nft?.userUid) {
      fetchUser();
    } else {
      setUser(null)
    }

  }, [nft, open])

  useEffect(() => {
    if (open && nft && user) {
      window.print();
    }
  }, [open, nft, user])

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack id={"print-modal"} direction={"column"} gap={2} sx={style} alignItems={"center"}>
        <img
          src={logo} alt={"Logo"}
          height={"50"} width={"auto"}
        />
        <Typography fullWidth>
          This document and the associated information stored on blockchain constitutes evidence of the common law
          copyright belonging to {user?.displayName || user?.email}, as
          of {moment(nft?.createdAt).format("DD MMMM YYYY HH:mm")}. The following information reiterates the information
          stored on blockchain regarding the work and its associated NFT that are protected by common law copyright:
        </Typography>
        <Table
          sx={{
            width: "100%",
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none"
            }
          }}
        >
          <TableBody>
            <TableRow sx={{border: 0}}>
              <TableCell>Title of Work:</TableCell>
              <TableCell style={{width: "60%"}}>{nft?.title}</TableCell>
            </TableRow>
            <TableRow sx={{border: 0}}>
              <TableCell>Description of Work:</TableCell>
              <TableCell style={{width: "60%"}}>{nft?.description}</TableCell>
            </TableRow>
            <TableRow sx={{border: 0}}>
              <TableCell>Link to NFT on Blockchain:</TableCell>
              <TableCell style={{width: "60%"}}>N/A</TableCell>
            </TableRow>
            <TableRow sx={{border: 0}}>
              <TableCell>NFT Hash:</TableCell>
              <TableCell style={{width: "60%"}}>{nft?.nftTxnHash || "N/A"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Modal>
  )
}