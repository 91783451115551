import {CardRounded} from "../../../components/card/CardRounded";
import {Avatar, Skeleton, Stack, Typography} from "@mui/material";
import {BadgeBox} from "../../../components/card/BadgeBox";
import {useNavigate} from "react-router-dom";
import {usePrismicDocumentByID} from "@prismicio/react";
import {CardRoundedContent} from "../../../components/card/CardRoundedContent";
import robotImg from "../../../assets/backgrounds/robot-chat.png";

export const BottomDiscoveryCards = ({id = "ZnrSmhEAAB8A1NMA", position = "center"}) => {
  const navigate = useNavigate();
  const [article] = usePrismicDocumentByID(id);

  return (
    article ?
      <CardRoundedContent image={article?.data?.cover_image?.url} position={"top"} circleColor={'#22b07d'} sx={{cursor: "pointer"}}
                          onClick={() => navigate(`/blog/${id}`)}
                          fullName={article?.data?.author_name[0]?.text?.trim() || "Chris Bennett"}
                          desc={article?.data?.title[0]?.text || ""} position={position}
                          occupations={article?.data?.category[0]?.text || 'Chatbot, helpful around the huse'}/>
      : <Skeleton variant="rectangular" width={"100%"} height={"35dvh"} sx={{borderRadius: 5, p: 4}}/>
  )
}