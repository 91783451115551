import {Box, TextField, Typography} from "@mui/material";
import React from "react";

export const ListAllAttributions = ({attributions, onUpdate}) => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "1rem",
          color: "#2D2D30",
          pb: 1
        }}
      >
        Please list all attributions of your work.
      </Typography>
      <TextField
        fullWidth
        InputLabelProps={{shrink: true}}
        value={attributions}
        onChange={(e) => onUpdate("attributions", e.target.value)}
        InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
        placeholder={"Acknowledge others work that you used in your own creative work."}
      />
    </Box>
  )
}