import * as React from 'react';
import { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Box, Button, Fade, } from "@mui/material";
import './stripe.css'
import axios from "axios";
import { auth } from "../../config/firebase";
import { StripeProductTypes } from "../../utils/enums/StripeProductTypes";

const stripePromise = loadStripe('pk_test_51PGhoOEXmEj60yYJPU9Akgt2Wa1PtTmBVDxAKVFt0Ex8fZh5YKl1C5MDgpmc8PNbagAIoMcudNxdHzdbVvhbzrjS00ox9ccXeX');

export const StripeEmbeddedForm = ({ nftUid, publicAddress, allChecksPassed, setStatus, onNextStep, productType = StripeProductTypes.NFT }) => {

    // const url = process.env.REACT_APP_ENVIRONMENT === 'development' ?
    //     'http://localhost:5001/c3-nft-c3/us-central1/create_checkout_session' :
    //     'https://us-central1-c3-nft-c3.cloudfunctions.net/create_checkout_session'
    const url = 'https://us-central1-c3-nft-c3.cloudfunctions.net/create_checkout_session'


    const fetchClientSecret = useCallback(() => {
        const params = {
            nftUid: nftUid,
            userUid: auth.currentUser.uid,
            userEmail: auth.currentUser.email,
            publicAddress,
            productType,
        };
        // Mint NFT / Mint License

        return axios.post(url, params, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            console.log(res.data.clientSecret)
            return res.data.clientSecret
        }).catch((err) => {
            setStatus('stripe-error')
            console.log(err)
        })

    }, [publicAddress]);

    const options = publicAddress && nftUid && allChecksPassed && { fetchClientSecret };

    if (!options) return null;

    return (
        <Fade in={publicAddress && nftUid && allChecksPassed && options}>
            <Box sx={{ width: '100%', display: (publicAddress && nftUid && allChecksPassed) ? 'block' : 'none' }}>
                {/*<Button onClick={()=>connectStripe()}>connect stripe</Button>*/}
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                        ...options
                    }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </Box>
        </Fade>
    )
}
