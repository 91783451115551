import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {grey} from "@mui/material/colors";
import {NonExclusiveType} from "../../../../utils/enums/NonExclusiveType";
import React from "react";

export const TableView = ({nft, license}) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Title of work</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>{nft?.title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Rights Licenses</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem", textTransform: "capitalize"}}>{Object.keys(license?.licenses)?.filter(key => license?.licenses[key])?.map(key => key?.replaceAll("_", " ")?.toLowerCase()).join(", ") || "No rights"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Exclusivity</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem", textTransform: "capitalize"}}>{license?.exclusiveType?.toLowerCase()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Compensation</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem"}}>
            {license?.price || license?.percent || "Not monetized"}{license?.price ? " USD" : license?.percent ? " %" : ""}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Limitations</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem", textTransform: "capitalize"}}>{license?.limitations}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{fontWeight: 600, fontSize: "1rem"}}>Termination events</TableCell>
          <TableCell sx={{color: grey[700], fontSize: "1rem", textTransform: "capitalize"}}>
            {license?.terminationEvents?.join(", ") || "No terminations events"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
