import React from "react";
import {List, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import phantomLogo from "../../../assets/img/phantom.png";
import metamaskLogo from "../../../assets/img/metamask.png";
import {grey} from "@mui/material/colors";
import {IconChevronRight} from "@tabler/icons-react";

export const ConnectWalletPage = () => {

  return (
    <>
      <Typography variant={"h2"} fontWeight={700}>Connect your wallet</Typography>
      <Typography variant={"subtitle2"} fontWeight={400} className={"text-grey"}>If you don’t have a wallet, you can set
        one up now. It’s free and takes ~2 minutes.</Typography>
      <Typography variant={"subtitle2"} fontWeight={400} className={"text-grey"}>The C3 team  recommends Phantom Wallet. <a href="https://phantom.app/" target={"_blank"} className={"text-purple"}>Click here</a> to create a wallet now.</Typography>
      <Typography variant={"h5"} fontWeight={600}>Test connecting to your wallet</Typography>

      <List>
        <ListItemButton sx={{border: 1, borderColor: 'divider', p: 2, borderRadius: 3, bgcolor: grey[100], "&:hover": {bgcolor: grey[200]} }}>
          <ListItemIcon>
            <img src={phantomLogo} alt={"phantom"} width={35}/>
          </ListItemIcon>
          <ListItemText primary={"Phantom"} variant={"h4"}/>
          <ListItemIcon sx={{display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}>
            <Typography component={"span"} className={"text-grey"}>Connect</Typography>
            <IconChevronRight size={18} />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton sx={{border: 1, borderColor: 'divider', mt: 3, p: 2, borderRadius: 3, bgcolor: grey[100], "&:hover": {bgcolor: grey[200]} }}>
          <ListItemIcon>
            <img src={metamaskLogo} alt={"metamask"} width={35}/>
          </ListItemIcon>
          <ListItemText primary={"Metamask"} variant={"h4"}/>
          <ListItemIcon sx={{display: "flex", flexDirection: "row", gap: 1, alignItems: "center"}}>
            <Typography component={"span"} className={"text-grey"}>Connect</Typography>
            <IconChevronRight size={18} />
          </ListItemIcon>
        </ListItemButton>
      </List>
    </>
  )
}