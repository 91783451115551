export const RightLicenseType = {
  REPRODUCING: "REPRODUCING",
  DERIVATIVE_WORKS: "DERIVATIVE_WORKS",
  DISTRIBUTING_COPIES: "DISTRIBUTING_COPIES",
  AUDIOVISUAL_WORKS: "AUDIOVISUAL_WORKS",
  PERFORMING_PUBLICLY: "PERFORMING_PUBLICLY",
  PERFORMING_COPIES: "PERFORMING_COPIES",
  DISPLAYING_PUBLICLY: "DISPLAYING_PUBLICLY",
  AUDIO_PERFORMING_PUBLICLY: "AUDIO_PERFORMING_PUBLICLY",
  SOUND_RECORDINGS: "SOUND_RECORDINGS",
  DIGITAL_AUDIO_TRANSMISSION: "DIGITAL_AUDIO_TRANSMISSION",
  SUBLICENSING: "SUBLICENSING",
}