import Modal from "@mui/material/Modal";
import {LicenseEntryPage} from "./LicenseEntryPage";
import Box from "@mui/material/Box";
import {Button, Stack} from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "40vw", maxWidth: "80vw",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  borderRadius: 5,
  border: "0 !important",
  boxShadow: 24, p: {xs: 2, md: 5}
};

export const LicenseIntroductionModal = ({open, handleClose}) => {


  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Stack direction={"column"} gap={2} sx={style} alignItems={"center"}>
        <Stack direction={"column"} gap={2} sx={{overflowY: "auto", px: 3, py: 1}}>
          <LicenseEntryPage />
        </Stack>
        <Box>
          <Button onClick={handleClose} className={"sign-button"} size={"large"} sx={{px: 3, borderRadius: 3}}>
            {"I’m ready, let’s make a license"}
          </Button>
        </Box>
      </Stack>
    </Modal>
  )
}