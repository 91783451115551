import {Button, Grid, Stack, Typography} from "@mui/material";
import {IconChevronRight} from "@tabler/icons-react";
import tillyImg from "../../assets/img/tilly-mobile.png"

export const TillyPage = () => {

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} sx={{textAlign: "left !important", color: "#4B4B4B"}}>
        <Stack direction={"column"} gap={3}>

          <Typography variant={"h2"} fontWeight={700} gutterBottom>Learn about copyright</Typography>
          <Typography variant={"subtitle1"} fontWeight={400}>Tilly is an AI-powered IP consultant helping democratize IP rights for everyone.</Typography>
          <Typography variant={"subtitle1"} fontWeight={400}>Our team built Tilly to help you explore the essentials of IP rights. Whether you're an artist, inventor, entrepreneur, or simply curious, Tilly is here to help you understand the intricacies of copyrights.</Typography>

          <div>
            <Button component={"a"} href={"https://tilly-kappa.vercel.app/"} target={"_blank"} sx={{mt: 2, py: 1.5, px: 4, borderRadius: 3}} className={"sign-button"} endIcon={<IconChevronRight />}>
              Learn with Tilly
            </Button>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
        <img src={tillyImg} alt={"Tilly"} width={"300px"} height={"auto"} />
      </Grid>

    </Grid>
  )
}