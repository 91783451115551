import {Suspense, useContext, useEffect, useState} from "react";
import {auth, db} from "./config/firebase";
import {onAuthStateChanged} from "firebase/auth"

import {Box, CircularProgress, ThemeProvider} from "@mui/material";
import {Link, Navigate, Route, Routes} from "react-router-dom";
import {theme} from "./utils/Theme";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import {LoginPage} from "./pages/auth/login/LoginPage";
import {RegisterPage} from "./pages/auth/register/RegisterPage";
import {Profile} from "./pages/profile/Profile";
// import {RoyaltyTemplate} from "./pages/royalty-template/RoyaltyTemplate";
import {AppContext} from "./context/AppContext";
import Homepage from "./components/Homepage";
import Contact from "./components/Contact";
import Mission from "./components/Mission";
import {Web3ReactProvider} from '@web3-react/core'
import client from "./config/prismic";

import allConnections from './connectors.js'
import {Portfolio} from "./pages/portfolio/Portfolio";
import {MintNFT} from "./components/stripe/MintNFT";
import {ForgotPasswordPage} from "./pages/auth/forgot-password/ForgotPasswordPage";
import {MainLayout} from "./layouts/main/MainLayout";
import {collection, doc, getDocs, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {RegisterWorkNew} from "./pages/register-work/RegisterWorkNew";
import {ContactInfoPage} from "./pages/contact/ContactInfoPage";
import {SettingsLayout} from "./layouts/settings/SettingsLayout";
import {ViewProfilePage} from "./pages/settings/view-profile/ViewProfilePage";
import {ConnectWalletPage} from "./pages/settings/connect-wallet/ConnectWalletPage";
import {PaymentSettingsPage} from "./pages/settings/payment-settings/PaymentSettingsPage";
import {AccountSettingsPage} from "./pages/settings/account-settings/AccountSettingsPage";
import {MiniLayout} from "./layouts/mini/MiniLayout";
import {RoyaltyTemplatePublicPage} from "./pages/royalty-template/RoyaltyTemplatePublicPage";
import {DiscoveryPage} from "./pages/discovery/DiscoveryPage";
import {TillyPage} from "./pages/tilly/TillyPage";
import {BlogPage} from "./pages/blog/BlogPage";
import {CreateLicensePage} from "./pages/license/CreateLicensePage";
import {PrismicProvider} from "@prismicio/react";
import {ArticlePage} from "./pages/article/ArticlePage";
import {NotificationsPage} from "./pages/notifications/Notifications";
import {LicenseRequestPage} from "./pages/license-request/LicenseRequestPage";
import {MintPage} from "./pages/mint/MintPage";

function App() {
  const connections = allConnections.map(([connector, hooks]) => [connector, hooks])
  const [isAuth, setIsAuth] = useState(null);
  const {dispatch, getUserDetails} = useContext(AppContext);


  onAuthStateChanged(auth, async (user) => {
    setIsAuth(!!user);
  })

  useEffect(() => {
    if (isAuth) {
      const fetchData = async () => {
        await getUserDetails();
      //   const querySnapshot = await getDocs(collection(db, "plans"));
      //   const items = [];
      //   querySnapshot.forEach(doc => {
      //     const col = {
      //       id: doc.id,
      //       ...doc?.data()
      //     };
      //     items.push(col);
      //   });
      //   dispatch({type: "update_royalty_templates", payload: items});
      }
      fetchData();
      const templatesQuery = query(
        collection(db, "royalty_templates"),
        where("userUid", "==", auth?.currentUser?.uid),
        orderBy("createdAt", "desc")
      );
      const unsubscribe = onSnapshot(templatesQuery, (querySnapshot) => {
        let templates = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          templates.push(data);
        });
        dispatch({type: "update_royalty_templates", payload: templates});
      });
      return () => unsubscribe();
    }
  }, [isAuth])


  return (
    <Web3ReactProvider connectors={connections}>
      <ThemeProvider theme={theme}>
        <PrismicProvider
          client={client}
          internalLinkComponent={({href, ...props}) => (
            <Link to={href} {...props} />
          )}
        >
          <ToastContainer
            theme={"colored"}
            hideProgressBar
            closeButton={false}
            closeOnClick
          />
          {isAuth === null && <LoadingScreen/>}
          <Suspense fallback={<LoadingScreen/>}>
            {/*<svg data-testid="DirectionsCar"></svg>*/}
            {
              auth?.cuurentUser !== null && isAuth !== null ?
                <Routes>
                  <Route element={<MiniLayout/>}>
                    <Route path={"/license/:templateId"} element={<RoyaltyTemplatePublicPage/>}/>
                  </Route>
                  {
                    isAuth ?
                      <Route element={<MainLayout/>}>
                        {/*<Route path={"/"} element={<LoggedInLanding/>}/>*/}
                        <Route path={"/discovery"} element={<DiscoveryPage/>}/>
                        <Route path={"/learn-with-tilly"} element={<TillyPage/>}/>
                        <Route path={"/blog"} element={<BlogPage/>}/>
                        <Route path={"/blog/:id"} element={<ArticlePage/>}/>

                        <Route path={"/contract-templates/create"} element={<CreateLicensePage/>}/>
                        <Route path={"/nft/:nftId/license"} element={<CreateLicensePage/>}/>
                        <Route path="/portfolio" element={<Portfolio/>}/>
                        <Route path="/notifications" element={<NotificationsPage/>}/>
                        <Route path="/notifications/:uid" element={<LicenseRequestPage/>}/>
                        {/*<Route path={"/contract-templates"} element={<RoyaltyTemplate/>}/>*/}
                        <Route path={"/register-work/:nftId"} element={<RegisterWorkNew/>}/>
                        <Route path={"/register-work"} element={<RegisterWorkNew/>}/>
                        <Route path="/profile/:id" index element={<Profile/>}/>
                        <Route path="/contact" index element={<ContactInfoPage/>}/>
                        <Route path="/return" index element={<MintPage/>}/>
                        <Route element={<SettingsLayout/>}>
                          <Route path="/view-profile" index element={<ViewProfilePage/>}/>
                          <Route path="/account-settings" index element={<AccountSettingsPage/>}/>
                          <Route path="/connect-wallet" index element={<ConnectWalletPage/>}/>
                          <Route path="/payment-settings" index element={<PaymentSettingsPage/>}/>
                        </Route>
                        <Route path="*" element={<Navigate to="/discovery"/>}/>
                      </Route>
                      :
                      <>
                        <Route element={<MiniLayout/>}>
                          <Route path="/login" index element={<LoginPage/>}/>
                          <Route path="/register" index element={<RegisterPage/>}/>
                          <Route path="/forgot-password" index element={<ForgotPasswordPage/>}/>
                        </Route>
                        <Route path="/" element={<Homepage/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/mission" element={<Mission/>}/>
                        <Route path="*" element={<Navigate to="/login"/>}/>
                      </>
                  }
                </Routes>
                :
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <CircularProgress/>
                </Box>
            }
          </Suspense>
        </PrismicProvider>
      </ThemeProvider>
    </Web3ReactProvider>
  );
}

export default App;
