import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  Fade,
  IconButton, ListItemIcon, ListItemText,
  Menu, MenuItem,
  Stack,
  Typography
} from "@mui/material";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  IconAlertTriangle,
  IconArchive, IconArchiveOff,
  IconChevronDown,
  IconFileDownload, IconListCheck, IconSend,
  IconX
} from "@tabler/icons-react";
import {deepPurple, grey} from "@mui/material/colors";
import {ShareModal} from "../../../components/share/ShareModal";
import {TableView} from "./license/TableView";
import {CardView} from "./license/CardsView";
import {toast} from "react-toastify";
import {SignTemplateModal} from "./license/SignTemplateModal";
import {SigneesView} from "./license/SigneesView";
import {db} from "../../../config/firebase";
import {doc, onSnapshot} from "firebase/firestore";
import {handleArchiveLicense} from "../../../utils/archiveLicense";
import {AppContext} from "../../../context/AppContext";
import {AlertBanner} from "../../../components/banner/AlertBanner";
import {PrintLicenseModal} from "./print/PrintLicenseModal";


const style = {
  position: 'absolute',
  top: '50%', left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80vw", maxHeight: "80dvh",
  minWidth: "60vw", minHeight: "60dvh",
  bgcolor: 'background.paper',
  borderRadius: 8, border: 0,
  boxShadow: 24, p: 3, overflowY: "auto"
};

const ViewType = {
  TABLE: "TABLE",
  PUBLIC: "PUBLIC",
  SIGNEES: "SIGNEES",
}

export const ViewLicenseModal = ({open, handleClose, nftUid, licenseUid}) => {
  const {state: {royaltyTemplates}, dispatch} = useContext(AppContext);
  const [view, setView] = useState(ViewType.TABLE);
  const [signTemplate, setSignTemplate] = useState(false);
  const [selectedSign, setSelectedSign] = useState(null);
  const [nft, setNft] = useState(null);
  const [license, setLicense] = useState(null);

  useEffect(() => {
    if (open && nftUid) {
      const unsubscribe = onSnapshot(doc(db, "royalty_templates", nftUid), (doc) => {
        console.log("Current nft data: ", doc.data());
        const data = doc.data();
        setNft({uid: nftUid, ...data});
      });
      return () => unsubscribe();
    }
  }, [nftUid, open])

  useEffect(() => {
    if (open && licenseUid) {
      const unsubscribe = onSnapshot(doc(db, "licenses", licenseUid), (doc) => {
        console.log("Current license data: ", doc.data());
        const data = doc.data();
        setLicense({uid: licenseUid, ...data});
      });
      return () => unsubscribe();
    }
  }, [licenseUid, open])

  const handleCloseSignModal = () => {
    setSignTemplate(false);
    setSelectedSign(null)
  }

  const handleArchive = async () => {
    try {
      const handleSuccess = (newLicense, newNft) => {
        setLicense(newLicense);
        setNft(newNft);
        const items = royaltyTemplates?.map(item => {
          if (item?.uid === newNft?.uid) {
            return {...newNft};
          }
          return {...item};
        });
        dispatch({type: "update_royalty_templates", payload: items});
        toast.success(`License ${newLicense?.archive ? "archived" : "unarchived"} successfully`)
      }

      await handleArchiveLicense({license, nft, handleSuccess});
    } catch (error) {
      console.log(error);
      toast.error("Failed to Archive license")
    }
  }

  return (
    nft && license &&
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Fade in={open} timeout={200}>
        <Stack direction={"column"} sx={style}>
          <SignTemplateModal
            open={signTemplate}
            handleClose={handleCloseSignModal}
            license={license} setLicense={setLicense} selectedSign={selectedSign}
          />
          <Header
            nft={nft} license={license} view={view}
            handleClose={handleClose}
            updateView={(newView) => setView(newView)} handleArchive={handleArchive}
            handleSign={() => setSignTemplate(true)} handleViewSign={() => setView(ViewType.SIGNEES)}
          />
          <Box sx={{flex: 1, overflowY: "auto", p: 1,}}>
            {
              {
                [ViewType.TABLE]: <TableView nft={nft} license={license}/>,
                [ViewType.PUBLIC]: <CardView nft={nft} license={license}/>,
                [ViewType.SIGNEES]: (
                  <SigneesView
                    nft={nft} license={license}
                    onSelectSign={sign => {
                      setSelectedSign(sign);
                      setSignTemplate(true);
                    }}
                    onCreateSign={() => setSignTemplate(true)}
                  />
                ),
              }[view]
            }

          </Box>
          {license?.archive &&
            <AlertBanner
              icon={<IconAlertTriangle color={"#6D57C3"} size={40}/>}
              color={"#6D57C3"}
              fontSize={15}
              bgcolor={`linear-gradient(94deg, rgba(109, 87, 195, 0.3) -10.69%, rgba(82, 123, 228, 0.25) 59.43%, rgba(75, 188, 252, 0.2) 104.02%)`}
              text={(
                <Typography className={"alert-text"} fontWeight={700}>
                  This license is archived. You cannot edit or share it.
                </Typography>
              )}
            />
          }
        </Stack>
      </Fade>
    </Modal>
  )
}


const Header = ({nft, license, handleClose, updateView, view, handleSign, handleViewSign, handleArchive}) => {
  const [shareModal, setShareModal] = useState(false);
  return (
    <>
      <ShareModal
        url={license?.uid ? `${window.location.origin}/license/${license?.uid}` : ""}
        handleClose={() => setShareModal(false)}
        open={shareModal}
      />
      <Stack direction={"row"} alignItems={"center"} gap={1}
             sx={{pb: 1.5, mx: 1, borderBottom: 1, borderColor: "divider"}}>
        <Typography variant={"h2"} color={"#2D2D30"} fontWeight={700} sx={{flex: 1}}
                    noWrap>{nft?.title}</Typography>

        <Button
          className={"sign-button"}
          sx={{
            borderRadius: 5,
            px: 2, py: 1
          }}
          onClick={() => updateView(view === ViewType.TABLE ? ViewType.PUBLIC : ViewType.TABLE)}
        >
          {view === ViewType.TABLE ? "Public View" : "Table View"}
        </Button>
        <LicenseActionsButton nft={nft} license={license} handleSign={handleSign} handleViewSign={handleViewSign}
                              handleArchive={handleArchive}/>
        <Button
          color={"warning"}
          variant={"outlined"} disabled={license?.archive}
          onClick={() => setShareModal(true)}
          sx={{
            borderRadius: 5,
            px: 2, py: 1
          }}
        >
          Share
        </Button>
        <IconButton onClick={handleClose}>
          <IconX color={grey[500]}/>
        </IconButton>
      </Stack>
    </>
  )
}

const LicenseActionsButton = ({license, nft, handleViewSign, handleSign, handleArchive}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [print, setPrint] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    setPrint(true);
    handleClose();
  }

  return (
    <>
      <PrintLicenseModal
        open={print} handleClose={() => setPrint(false)}
        nft={nft} license={license}
      />
      <Button
        ref={anchorEl}
        color={"secondary"} variant={"outlined"}
        sx={{
          borderRadius: 5,
          borderColor: deepPurple[200],
          px: 2, py: 1
        }}
        onClick={handleClick}
        endIcon={<IconChevronDown size={15}/>}
      >
        License Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSign}>
          <ListItemIcon><IconSend/></ListItemIcon>
          <ListItemText primary={"Send License"} sx={{py: 1, px: 2}}/>
        </MenuItem>
        <MenuItem onClick={handleDownload}>
          <ListItemIcon><IconFileDownload/></ListItemIcon>
          <ListItemText primary={"Download License"} sx={{py: 1, px: 2}}/>
        </MenuItem>
        {/*<MenuItem onClick={handleSign} disabled={license?.archive}>
          <ListItemIcon><IconSignature/></ListItemIcon>
          <ListItemText primary={"Mark as signed"} sx={{py: 1, px: 2}}/>
        </MenuItem>*/}
        <MenuItem onClick={handleViewSign}>
          <ListItemIcon><IconListCheck/></ListItemIcon>
          <ListItemText primary={"View Licenses"} sx={{py: 1, px: 2}}/>
        </MenuItem>
        <MenuItem onClick={handleArchive}>
          <ListItemIcon>{license?.archive ? <IconArchiveOff/> : <IconArchive/>}</ListItemIcon>
          <ListItemText primary={license?.archive ? "Unarchive License" : "Archive License"} sx={{py: 1, px: 2}}/>
        </MenuItem>
      </Menu>
    </>
  )
}