import {Avatar, Card, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {IconCircleFilled} from "@tabler/icons-react";

export const CardRoundedContent = ({image, position = "center", avatarImg, fullName, occupations, circleColor, desc, sx, ...props}) => {
  return (
    <Card elevation={0} {...props} sx={{borderRadius: 5, border: 1, borderColor: "divider", ...sx}}>
      <CardMedia
        image={image}
        title="card background"
        sx={{height: 140, position: "relative", backgroundPosition: `${position} !important`}}
      >
        <Avatar src={avatarImg}
                sx={{
                  bgcolor: "rgba(0,0,0, 0.1)", backdropFilter: "blur(2px)",
                  border: 0.5, borderColor: "#fefefe", color: "#fefefe",
                  p: 0.5, position: "absolute", bottom: 0, right: 10,
                  transform: "translate(0, 50%)"
                }}
        />
      </CardMedia>
      <CardContent sx={{backgroundColor: "#252836", color: 'white', height: 140}}>
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', ml: '3%'}}>
          <Stack direction={'row'} alignItems={'center'} gap={1} mb={1}>
            <Box sx={{color: circleColor}}>
              <IconCircleFilled size={16}/>
            </Box>
            <Typography variant="body1" sx={{color: "#b7b9d2"}} textAlign={'left'}>{fullName}</Typography>
          </Stack>
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Typography variant="h5" color="white" textAlign={'left'}>{desc}</Typography>
            <Typography variant="body2"
                        sx={{textAlign: 'left', color: "#808191", mt: 'auto'}}>{occupations}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};