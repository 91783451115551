import {Box, Fade, Grid, TextField, Typography} from "@mui/material";
import React from "react";
import literatureImg from "../../../assets/img/literature.png";
import visualArtImg from "../../../assets/img/visual-art.png";
import soundRecordingImg from "../../../assets/img/sound-recording.png";
import performingArtImg from "../../../assets/img/performing-art.png";
import audiovisualWorkImg from "../../../assets/img/audiovisual-work.png";
import singleSerialIssueImg from "../../../assets/img/single-serial-issue.png";
import {grey} from "@mui/material/colors";


export const projectTypes = [
  {name: "Literature", img: literatureImg},
  {name: "Visual Art", img: visualArtImg},
  {name: "Sound Recording", img: soundRecordingImg},
  {name: "Performing Art", img: performingArtImg},
  {name: "Audiovisual Work", img: audiovisualWorkImg},
  {name: "Single Serial Issue", img: singleSerialIssueImg},
]

export const ProtectIp = ({onUpdate, registerWorkForm}) => {

  const onSelect = (name) => {
    let types = [name]
    // if (registerWorkForm.projectTypes.includes(name)) {
    //   types = registerWorkForm?.projectTypes?.filter(i => i !== name);
    // } else {
    //   types = [...registerWorkForm.projectTypes, name];
    // }
    console.log(types);
    onUpdate("projectTypes", types);
  }

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 2,
          }}
        >
          Please select the category your work falls under
        </Typography>
      </Box>
      <Grid container spacing={6}>
        {projectTypes?.map(type => {
          const isActive = registerWorkForm?.projectTypes?.includes(type.name);

          return (
            <Grid item key={type.name} xs={6} sm={4} onClick={() => onSelect(type.name)}>
              <img src={type?.img} width={"100%"} height={"auto"} alt={type.name}
                   className={isActive ? "active-shadow" : ""}
                   style={{objectFit: "cover", borderRadius: 16, filter: `grayscale(${isActive ? 0 : 100}%)`}}/>
              <Typography fontWeight={600} color={isActive ? "#D55380" : grey[500]} align={"center"}>{type.name}</Typography>
            </Grid>
          )
        })}
      </Grid>

      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            color: "#2D2D30",
            pb: 2,
          }}
        >
          Please tell us the specific type of your work
        </Typography>
        <TextField
          multiline
          maxRows={3} fullWidth
          value={registerWorkForm?.projectDetails}
          onChange={e => onUpdate("projectDetails", e.target.value)}
          InputProps={{sx: {borderRadius: 3, bgcolor: "#FFF"}}}
          placeholder={"(e.g. original song, book, tweet, codebase)"}
        />
      </Box>
    </>
  )
}