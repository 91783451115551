import {Button, Stack} from "@mui/material";
import {IconChevronLeft, IconChevronRight} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";

export const StepButtons = ({onBack, onNext, onSubmit, isDisabled = false, submitTitle}) => {

  return (
    <Stack direction={"row"} gap={2} sx={{py: 5, flexDirection: {xs: "column", sm: "row"} }}>
      <Button
        sx={{
          py: 1, px: 2,
          border: 0, borderColor: "divider",
          borderRadius: 3,
          bgcolor: grey[100], color: grey[700],
          "&:hover": {
            bgcolor: grey[200], color: grey[800],
            borderColor: "divider", border: 0
          }
        }}
        onClick={onBack}
        startIcon={<IconChevronLeft/>}
      >
        Back
      </Button>
      {
        onNext &&
        <Button
          sx={{flex: 1, py: 1, px: 2, borderRadius: 3}}
          className={"sign-button"}
          onClick={onNext} disabled={isDisabled}
          endIcon={<IconChevronRight/>}
        >
          Continue
        </Button>
      }
      {
        onSubmit &&
        <Button
          sx={{flex: 1, py: 1, px: 2, borderRadius: 3}}
          className={"sign-button"}
          onClick={onSubmit} disabled={isDisabled}
          endIcon={<IconChevronRight/>}
        >
          {submitTitle || "Submit"}
        </Button>
      }


    </Stack>
  )
}