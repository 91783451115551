import {Avatar, Button, Stack, Typography} from "@mui/material";
import {green, grey} from "@mui/material/colors";
import {IconChecks, IconChevronRight} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {ShareModal} from "../../../components/share/ShareModal";
import AnimationSuccess from "../../../components/lottie-animations/AnimationSuccess";

export const LicenseCreated = ({nft, license}) => {
  const [shareModal, setShareModal] = useState(false);
  return (
    <Stack direction={"column"} alignItems={"center"} gap={2} maxWidth={"sm"}
           justifyContent={'center'} alignContent={'center'}
           sx={{m: "auto", borderRadius: 5, boxShadow: 5, bgcolor: "#FFF", p: 5, height: '100%'}}>
      <ShareModal
        url={license?.uid ? `${window.location.origin}/license/${license?.uid}` : ""}
        handleClose={() => setShareModal(null)}
        open={shareModal}
      />
      <AnimationSuccess open />

      <Typography variant={"h3"}>
        A license for "{nft.title}" has been created!
      </Typography>

      <Typography sx={{color: grey[600]}} align={"center"}>
        By creating a license as an NFT, you’ve unlocked avenues to bring your creative work into the world while dictating the conditions under which it is shared. 🔥💪
      </Typography>

      <Button
        color={"warning"}
        variant={"outlined"}
        size={"large"}
        sx={{textTransform: "none", px: 4, borderRadius: 6}}
        onClick={() => setShareModal(true)}
      >
        Share my license 🚀
      </Button>

      <Button
        component={Link}
        to={"/portfolio"}
        size={"large"}
        className={"sign-button"}
        endIcon={<IconChevronRight/>}
        sx={{textTransform: "none", px: 4, borderRadius: 6}}
      >
        View this license in your portfolio
      </Button>
    </Stack>
  )
}