import {
  Box,
  Button, Input, InputAdornment,
  Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
  Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {IconAlertCircle, IconPlus, IconSearch} from "@tabler/icons-react";
import {AppContext} from "../../../../context/AppContext";
import {collection, onSnapshot, orderBy, query, where, and} from "firebase/firestore";
import {db} from "../../../../config/firebase";
import {LicenseRequestType} from "../../../../utils/enums/LicenseRequestType";
import moment from "moment";

export const SigneesView = ({nft, license, onSelectSign, onCreateSign}) => {
  const [search, setSearch] = useState("");

  const [notifications, setNotifications] = useState([])
  const {state: {userDetails}} = useContext(AppContext);

  useEffect(() => {
    const fetchNotifications = () => {
      let unsubscribe = null;
      try {
        const notificationsQuery = query(
          collection(db, "license_requests"),
          and(
            where("email", "==", userDetails?.email),
            where("licenseUid", "==", license?.uid),
          ),
          orderBy("createdAt", "desc")
        );
        unsubscribe = onSnapshot(notificationsQuery, (querySnapshot) => {
          let notifications = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            notifications.push(data);
          });
          setNotifications(notifications);
        });
      } catch (error) {
        console.log(error);
      }
      return unsubscribe;
    }
    if (userDetails && userDetails?.email) {
      const unsubscribe = fetchNotifications();
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [userDetails])

  return (
    <>
      <Stack direction={"row"} gap={2} alignItems={"center"} sx={{py: 1}}>
        <Typography sx={{fontWeight: 600, fontSize: "1.1rem"}}>Licenses</Typography>
        <Typography sx={{fontWeight: 400, fontSize: "1rem"}} className={"text-grey"}>
          {notifications?.length} licenses in operation
        </Typography>
        <Box sx={{flex: 1, fontWeight: 600, textAlign: "right"}}>
          <Input
            placeholder={"Search for signee"}
            value={search} onChange={(e) => setSearch(e.target.value)}
            startAdornment={<InputAdornment position={"start"}><IconSearch /></InputAdornment>}
          />
        </Box>
        <Box sx={{fontWeight: 600, textAlign: "right"}}>
          <Button className={license?.archive ? "" : "gradient-text"} onClick={onCreateSign} disabled={license?.archive}
                  startIcon={<IconPlus className={license?.archive ? "" : "text-purple"} size={18}/>}>
            Send License
          </Button>
        </Box>
      </Stack>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Recipient Mail</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Date</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Expiration</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Type</TableCell>
            <TableCell className={"text-grey"} sx={{fontWeight: 600}}>Message</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            notifications?.length === 0 ?
              <TableRow>
                <TableCell colSpan={6}>{"You don't have any notifications"}</TableCell>
              </TableRow>
              : null
          }
          {
            notifications?.map(notification => {
              const message = {
                [LicenseRequestType.PENDING]: `${notification?.firstName || notification?.lastName ? `${notification?.firstName} ${notification?.lastName}`?.trim() : notification?.email} has sent you license.`,
                [LicenseRequestType.ACCEPTED]: `Client accepted the license`,
                [LicenseRequestType.DECLINED]: `Client declined the license from.`,
                [LicenseRequestType.PAID]: `The payment was successfull.`,
                [LicenseRequestType.MINTED]: `License is minted.`,
                [LicenseRequestType.FAILED]: `Failed in some proccess please contact us.`,
              }[notification.state];
              const isExpired = notification.expirationDate < new Date().getTime();

              return (
                <TableRow key={notification?.uid}>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>{notification?.recipientMail}</TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>
                    <Tooltip title={"Date license request is created"}>
                      {moment(notification.createdAt).format("DD MMM YYYY")}
                    </Tooltip>
                  </TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>
                    <Tooltip title={isExpired ? "License expired" : "Expiration date"}>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <IconAlertCircle size={16} className={"text-grey"}/>
                        <Typography>{moment(notification.expirationDate).format("DD MMM YYYY")}</Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    className={"text-grey"}
                    sx={{
                      fontWeight: 400,
                      textTransform: "capitalize"
                    }}
                  >
                    {notification?.type?.toLowerCase()?.replaceAll("_", " ")}
                  </TableCell>
                  <TableCell className={"text-grey"} sx={{fontWeight: 400}}>{message}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </>
  )
}
