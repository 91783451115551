import React from 'react';
import {AlertBanner} from "../../../components/banner/AlertBanner";
import IconExport from "../../../assets/icons/IconExport";
import CelebrateIcon from "../../../assets/icons/celebrate.png";
import {IconAlertTriangle} from "@tabler/icons-react";
import {countryData} from "../../../utils/countryData";
import {Typography} from "@mui/material";
import {useSetAtom} from "jotai/index";
import {ContactFormAtom} from "../../../atoms";

export const LocationBanner = ({location}) => {
  const setOpenContactModal = useSetAtom(ContactFormAtom);

  const handleContactOpen = () => {
    setOpenContactModal({
      open: true,
      message: "I am located outside of the United States, ...",
      title: "License submission"
    })
  }
  if (location) {
    if (location === countryData.US.name) {
      return (
        <AlertBanner
          icon={<IconExport iconPath={CelebrateIcon}/>}
          color={"#7758D7"}
          text={
            <Typography className={"alert-text"}>
              <Typography component={"b"} fontWeight={700}>Thank you!</Typography> You’re in the right jurisdiction. Let’s go ahead and upload!
            </Typography>
          }
        />
      )
    }
    return (
      <AlertBanner
        icon={<IconAlertTriangle color={"#D55380"} size={45}/>}
        color={"#D55380"}
        text={(
          <Typography className={"alert-text"}>
            <Typography component={"b"} fontWeight={700}>Alert!</Typography> Because you are located outside of the United States, you may not be able to fully protect
            your rights through this platform.
            You may continue, but the rights acquired through this platform may not be legally binding in your country.
            Please contact us <Typography component={"span"} onClick={handleContactOpen} style={{color: "#D55380", fontWeight: "bold"}}>here</Typography> for more
            details.
          </Typography>
        )}
      />
    )
  }

  return null;
}
