import {CardRounded} from "../../../components/card/CardRounded";
import {Stack} from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

const CategoryTips = {
  "Literature": "**Understand Your Rights**\nEnsure that you comprehend the full scope of your rights, especially reproduction, distribution, and public display.\n**Key Takeaway:**\n  - 90% of people can ignore this because standard contracts usually cover these rights.\n  - 10% of people should consider this if they want to retain more control over their work.\n**Consider Copyrighting Literary Components.**\nIf a component of your literary work could stand on its own as a separate literary work, such as a book’s most popular character or an ubiquitous API, consider copyrighting & licensing such component(s) separately. Ensure that you provide which components are included in this license in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because their work is straightforward.\n  - 10% of people should consider this if their work has multiple unique, valuable elements.\n",

  "Visual Art": "**Mind the Medium** Clearly define how your artwork can be used, including whether you prefer that it is used in a certain medium. Ensure that you include your preference in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because most uses are standard.\n  - 10% of people should consider this if they have specific medium preferences.\n**Moral Rights Awareness:**\nBe aware of your possible moral rights, which can protect your work from distortion or misuse. These rights can remain with the artist even after licensing. If this sounds useful to you, ensure that you include such rights in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because not all art is at risk of misuse.\n  - 10% of people should consider this if their work is prone to distortion or misrepresentation.\n**Consider Digital Protection.**\nConsider adding special metadata to your visual art’s files to safeguard against unauthorized reproduction.\n- **Key Takeaway:**\n  - 90% of people can ignore this because physical art is less at risk.\n  - 10% of people should consider this if their work is primarily digital and widely shared online.\n",

  "Sound Recording": "**Understand Your Rights**\nEnsure that you comprehend the full scope of your rights, especially digital audio transmissions, public performance, and synchronization with visuals.\n- **Key Takeaway:**\n  - 90% of people can ignore this because standard contracts usually cover these rights.\n  - 10% of people should consider this if they want to retain more control over their work.\n**Synchronization Rights.**\nDecide whether you want to grant synchronization rights, which allows your recording to be used in visual media, such as films, commercials, or online videos. Specify the terms regarding such use in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because their recordings are not likely to be used in visual media.\n  - 10% of people should consider this if they see potential for their work in films or videos.\n**Master Rights vs. Composition Rights:**\nUnderstand the distinction between master rights (the actual recording, protected as a sound recording) and composition rights (the underlying music and lyrics, protected as literature). If both are covered by the same copyright, it’s okay to license this work as a sound recording. Otherwise, consider licensing each separately.\n- **Key Takeaway:**\n  - 90% of people can ignore this because they control both rights.\n  - 10% of people should consider this if they only control one set of rights.\n",

  "Performing Art": "**Understand Your Rights**\nEnsure that you comprehend the full scope of your rights, especially public performances, broadcasts, and recordings. Provide terms related to those rights in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because standard contracts usually cover these rights.\n  - 10% of people should consider this if they want to retain more control over their work.\n**Rehearsals & Adaptations.**\nClearly define whether the license includes rights for rehearsals and adaptations to ensure that your work is performed as intended.\n- **Key Takeaway:**\n  - 90% of people can ignore this because their work doesn't require strict guidelines.\n  - 10% of people should consider this if precise adaptations are crucial.\n**Credit and Attribution.**\nMaintain your reputation by ensuring that proper credit is given for your work in all performances. Include the attribution requirement in the “Other Limitations” section.\n- **Key Takeaway:**\n  - Secure proper credit for your work to uphold your reputation.\n",

  "Audiovisual Work": "**Understand Your Rights**\nEnsure that you comprehend the full scope of your rights, especially public display and derivative works. Determine whether you will allow adaptations based on your audiovisual content, including remakes, sequels, and spin-offs. Provide clear terms in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because standard contracts usually cover these rights.\n  - 10% of people should consider this if they want to retain more control over their work.\n**Distribution Channels.**\nConsider which distribution channels you want to license your work through and whether you’d prefer different terms and protections for each channel. If so, include which channel this license applies to in the “Other Limitations” section.\n- **Key Takeaway:**\n  - 90% of people can ignore this because their work is distributed through standard channels.\n  - 10% of people should consider this if they aim for diverse distribution platforms.\n**Marketing & Promotion.**\nSpecify the amount of involvement and control you’d prefer to have over the marketing and promotion of your audiovisual work, including how your work is advertised, where it is advertised, and what sort of merchandising you’ll accept.\n- **Key Takeaway:**\n  - 90% of people can ignore this because typical marketing strategies are sufficient.\n  - 10% of people should consider this if they want specific control over their work’s promotion.\n",

  "Single Serial Issue": "**Scope of Use**\nClearly define which issue or edition is being licensed and for what uses, such as print, digital, or both. This helps prevent the unauthorized reproduction of future issues.\n- **Key Takeaway:**\n  - 90% of people can ignore this because their licensing needs are straightforward.\n  - 10% of people should consider this if they have specific use cases.\n**Archival Rights.**\nClearly define whether the license includes rights for archiving and for how long the issue can remain available.\n- **Key Takeaway:**\n  - 90% of people can ignore this because archiving is usually standard.\n  - 10% of people should consider this if they have specific archival needs.\n**Reprints & Redistribution.**\nSpecify any additional terms for reprints or redistributions. This applies to collections, anthologies, or special editions that may feature your work.\n- **Key Takeaway:**\n  - 90% of people can ignore this because reprints are typically covered.\n  - 10% of people should consider this if they anticipate special editions or anthologies.\n"
};


export const BeforeStartedCard = ({ nft }) => {
  const { projectTypes } = nft;

  return (
    projectTypes?.length &&
    projectTypes?.map(projectType => (
      CategoryTips[projectType] ? (
        <CardRounded key={projectType} component={Stack} direction={"column"} gap={3} sx={{ p: 4, textAlign: "left" }} className={"template-card"}>
          <ReactMarkdown>{CategoryTips[projectType]}</ReactMarkdown>
        </CardRounded>
      ) : null
    ))
  );
};
