import {Stack, Typography} from "@mui/material";
import AnimationFailed from "../lottie-animations/AnimationFailed";
import React from "react";
import {useSetAtom} from "jotai/index";
import {ContactFormAtom} from "../../atoms";

export const LicenseRequestFailed = () => {
  const setOpenContactModal = useSetAtom(ContactFormAtom);

  const handleContactOpen = () => {
    setOpenContactModal({
      open: true,
      message: "",
      title: "License failed during payment or minting"
    })
  }

  return (
    <Stack direction={"column"} gap={2}>
      <Typography variant={"h2"} fontWeight={700} align={"center"} gutterBottom>License Failed</Typography>
      <AnimationFailed />
      <Typography fontWeight={400} maxWidth={"sm"} sx={{mx: "auto"}} className={"text-grey"} align={"center"}>
        License during the payment or minting please <Typography component={"span"} onClick={handleContactOpen} style={{color: "#D55380", fontWeight: "bold"}}>contact us</Typography>
      </Typography>

    </Stack>
  )
}