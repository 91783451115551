import {auth} from "../../config/firebase";
import {Avatar, Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import {IconBriefcase, IconLicense, IconPencil, IconShare3} from "@tabler/icons-react";
import React from "react";

export const UserInfo = ({id}) => {
    const isCurrentUser = id === auth?.currentUser?.uid

    return (
        <Stack direction={"column"} gap={2} sx={{maxWidth: {xs: "90vw", md: "80vw"}, width: "100%", mt: 2, mx: "auto"}}>
            <Box
                sx={{
                    minHeight: "250px",
                    width: "100%",
                    bgcolor: "#FFF",
                    borderRadius: 5,
                    boxShadow: 5,
                    zIndex: 100,
                    position: "relative"
                }}
            >
                <Box
                    sx={{
                        bgcolor: grey[100],
                        backgroundImage: "url(https://s3-alpha-sig.figma.com/img/1f6a/5f4f/9a2a475ea0e3ba07da1e156158516257?Expires=1695600000&Signature=G39edgMW-JpmLG61Is4rfHofRfUa-VM-50D-EqYNlAdrrg1qVbvFyRwc86WA6dRBxFOaK1vasZ79WQkaY8Y5yIGFErw6nWyJeELtsywfAV5XrRIkMN5LU7HWTuAd7DzUoRp~YEK9S8tqNIjri7lDAZ2Qgvy1zy6KAVA5lBz3vlBVXrc2W8t8ivjiR2Kqp7-LAZr7Xt22Gli3DvNvgup8JHnqQS-bDixrtGHlkKJ~WZqUWRCHP0ZYbhM7ZP679pod4~kTtuQrq8twLbDpVGrPR~bDjUY561sz15ZcVrFodHkFgejLzoFKTnMlf~HgTMgJggyo1ALE5q-Nv9SJsneymg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4)",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderTopRightRadius: "inherit",
                        borderTopLeftRadius: "inherit",
                        height: "15rem",
                        width: "100%",
                        zIndex: 10,
                        position: "relative"
                    }}
                >
                    <Avatar
                        component={Button}
                        onClick={() => alert("Clicked")}
                        sx={{
                            position: "relative",
                            top: "50%",
                            left: 30,
                            bgcolor: grey[400],
                            width: "10rem",
                            height: "10rem",
                            border: 5,
                            borderColor: "#FFF",
                            "&:hover": {
                                bgcolor: grey[500],
                            }
                        }}
                        src={auth?.currentUser?.photoURL}
                    >

                    </Avatar>
                    {
                        isCurrentUser &&
                        <IconButton
                            sx={{
                                position: "absolute",
                                bgcolor: "#FFF",
                                zIndex: 100,
                                color: "#E35273",
                                top: 10,
                                right: 10,
                                "&:hover": {
                                    bgcolor: grey[100],
                                }
                            }}
                        >
                            <IconPencil size={18}/>
                        </IconButton>
                    }
                </Box>

                <Box
                    sx={{
                        // height: "10rem",
                        p: 2,
                        mt: 5
                    }}
                >
                    <Typography
                        fontSize={"1.5rem"}
                        fontWeight={"bold"}
                        sx={{}}
                    >
                        Theo Goetemann
                    </Typography>
                    <Typography
                        fontSize={"0.85rem"}
                        fontWeight={"bold"}
                        color={"#6B6B6B"}
                        sx={{}}
                    >
                        @theo_goat
                    </Typography>
                    <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"} sx={{mt: 2}}>
                        <Button
                            size={"small"}
                            sx={{
                                borderRadius: 5,
                                px: 1,
                                color: "#E7526F",
                                border: 1,
                                borderColor: "#E7526F",
                                textTransform: "none",
                                fontWeight: "bold"
                            }}
                        >
                            <IconBriefcase size={18} color={"#E7526F"} style={{marginRight: 5}}/>
                            Manage Portfolio
                        </Button>
                        <Button
                            size={"small"}
                            sx={{
                                borderRadius: 5,
                                px: 1,
                                color: "#8D57C3",
                                border: 1,
                                borderColor: "#8D57C3",
                                textTransform: "none",
                                fontWeight: "bold"
                            }}
                        >
                            <IconLicense size={18} color={"#8D57C3"} style={{marginRight: 5}}/>
                            Manage Licenses
                        </Button>
                        <Button
                            size={"small"}
                            sx={{
                                borderRadius: 5,
                                px: 1,
                                color: "#767D84",
                                border: 1,
                                borderColor: "#767D84",
                                textTransform: "none",
                                fontWeight: "bold"
                            }}
                        >
                            <IconShare3 size={18} color={"#767D84"} style={{marginRight: 5}}/>
                            Share
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Stack>
    )
}
