import {Box, Stack, Typography} from "@mui/material";
import bgImg1 from "../../../assets/backgrounds/card-bg-1.svg";
import {useNavigate} from "react-router-dom";


export const ChooseTemplate = ({onNextStep}) => {
  const navigate = useNavigate();

  return (
    <Stack direction={"row"} gap={4} alignItems={"stretch"} useFlexGap flexWrap={"wrap"} sx={{color: "#FFFFFF !important"}}>
      <Stack direction={"column"} gap={2} className={"royalty-card1"} onClick={onNextStep} sx={{background: `url(${bgImg1})`, cursor: "pointer", p: 4, borderRadius: 8, flex: 2, minWidth: "250px"}}>
        <Typography variant={"h4"} fontWeight={700} align={"left"}>Document your work</Typography>
        <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>
          This mints your work as an NFT, providing evidence of your common law copyright that can strengthen your claim against anyone that may infringe on your copyright.
        </Typography>
        <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>However, common law copyright is geographically limited. If someone infringes your work, damages will often be limited to what is allowed under you state’s law.</Typography>
        <div>
          <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >
            Consider this option if:
          </Typography>
          <ul style={{paddingTop: 0, margin: 0}}>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >
                You don’t have the money for federal registration
              </Typography>
            </li>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >
                Your work isn’t popular or valuable yet
              </Typography>
            </li>
          </ul>
        </div>

        <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"} sx={{pt: {xs: 2, md: 5}, mt: "auto"}}>
          <Box>
            <Typography variant={"h4"} fontWeight={400} align={"left"}>$2.99</Typography>
            <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>~4 minutes</Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack direction={"column"} gap={2} className={"royalty-card1"} onClick={() => navigate("/contact")} sx={{background: `url(${bgImg1})`, cursor: "pointer", p: 4, borderRadius: 8, flex: 1, minWidth: "250px"}}>
        <Typography variant={"h4"} fontWeight={700} align={"left"}>Register your work with the U.S. Copyright Office</Typography>
        <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>This registers your work with the US Copyright Office. Registration protects you nation-wide. This means if someone infringes your work, your recovery of damages can go nation-wide. </Typography>
        <div>
          <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >
            Consider this option if:
          </Typography>
          <ul style={{paddingTop: 0, margin: 0}}>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >Your work is popular or valuable</Typography>
            </li>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400} align={"left"} >If you want full copyright protection</Typography>
            </li>
          </ul>
        </div>

        <Stack direction={"row"} gap={2} justifyContent={"flex-end"} alignItems={"center"} sx={{pt: {xs: 2, md: 5}, mt: "auto"}}>
          <Box>
            <Typography variant={"h4"} fontWeight={400} align={"left"}>$95</Typography>
            <Typography variant={"subtitle2"} fontWeight={400} align={"left"}>96 hour turnaround</Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

