import {Grid, Button, Typography} from "@mui/material";
import {CardRounded} from "../../../components/card/CardRounded";
import {IconChevronRight} from "@tabler/icons-react";

export const LicenseEntryPage = () => {

  return (
    <>
      <CardRounded component={Grid} container sx={{p: 4}} className={"template-card"}>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"h3"} fontWeight={700}>90% of users only need a basic license.</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>Your work may only be valuable as a sum of its parts, instead of each part individually. If you place too many limitations in your license, you may not generate as much interest.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 90% of our users</Typography>, uploading their work once as an unbroken whole may benefit them.
          </Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 10% of users</Typography>, each part of a work is valuable by itself. Limitations in your license(s) may benefit them.
          </Typography>
        </Grid>
      </CardRounded>
      <CardRounded component={Grid} container sx={{p: 4}} className={"template-card"}>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"h3"} fontWeight={700}>To monetize or not?</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>Make sure that the license’s terms align with your interests. Licenses are contracts. Once someone agrees to your license, you can’t change its terms for that person.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 90% of our users</Typography>, credit and/or royalties are the most important considerations.
          </Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 10% of users</Typography>, other considerations like scope-limiting and sub-licensing may be more important.
          </Typography>
        </Grid>
      </CardRounded>
      <CardRounded component={Grid} container sx={{p: 4}} className={"template-card"}>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"h3"} fontWeight={700}>Why create multiple licenses?</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>Licenses can be as broad or as limited as you prefer. If you would prefer to piece your work into component parts and exercise more control over who uses which part, multiple licenses may benefit you.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{textAlign: "left"}}>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 90% of our users</Typography>, only one license may suffice to start making money or to let other people begin using your work..
          </Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>
            <Typography component={"b"} fontWeight={700}>For 10% of users</Typography>, multiple licenses might allow more granular control over a work.
          </Typography>
        </Grid>
      </CardRounded>
      <CardRounded component={Grid} container sx={{p: 4}} className={"template-card"}>
        <Grid item xs={12} sm={5} md={4} sx={{textAlign: "left"}}>
          <Typography variant={"h3"} fontWeight={700}>Give me a typical example.</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>
            A writer has finished a book and listed it on C3.
          </Typography>
          <Typography variant={"subtitle2"} fontWeight={400}>
            A musician has finished a song and listed it on C3.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={8} sx={{textAlign: "left"}}>
          <Typography variant={"subtitle2"} fontWeight={400}>A writer has finished a book and listed the book on C3.</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}><Typography component={"b"} fontWeight={700}>Her goal:</Typography> Maximize royalties. The book contains a particularly compelling character, who the writer thinks could be used in fan-fiction and tv spin-offs.</Typography>
          <Typography variant={"subtitle2"} fontWeight={400}><Typography component={"b"} fontWeight={700}>Her licenses:</Typography> She will create a license for her book, and separate licenses for Character 1. This allows her to license her book to publishers for distribution separately from licensing her characters for remixing.</Typography>
          <ul>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400}>License 1: The book</Typography>
            </li>
            <li>
              <Typography variant={"subtitle2"} fontWeight={400}>License 2: Character 1.</Typography>
            </li>
          </ul>
        </Grid>
      </CardRounded>
    </>
  )
}